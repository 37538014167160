import React from "react";

const Switcher3 = ({ isChecked, onToggle }) => {
  const handleCheckboxChange = () => {
    onToggle(!isChecked);
  };

  return (
    <div className="flex items-center cursor-pointer select-none">
      <label className="flex items-center gap-4 cursor-pointer select-none">
        <div className="relative">
          {/* Hidden Checkbox */}
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="sr-only"
          />
          {/* Background */}
          <div
            className={`block w-14 h-8 rounded-full transition-colors duration-300 ${
              isChecked
                ? "bg-gradient-to-r from-green-600 to-green-700"
                : "bg-gradient-to-r from-red-400 to-red-600"
            }`}
          ></div>
          {/* Toggle Knob */}
          <div
            className={`absolute top-1 h-6 w-6 rounded-full bg-white shadow-md transition-transform duration-300 ${
              isChecked ? "translate-x-1" : "translate-x-7"
            }`}
          >
            <span className="flex h-full w-full items-center justify-center">
              {isChecked ? (
                <span className="text-green-500 text-sm font-bold">✔</span>
              ) : (
                <span className="text-red-500 text-sm font-bold">✘</span>
              )}
            </span>
          </div>
        </div>
        {/* Status Text */}
        {/* <span className="text-sm font-medium">
          {isChecked ? "Active" : "Inactive"}
        </span> */}
      </label>
    </div>
  );
};

export default Switcher3;
