import React, {  useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  modifyTransaction,
  removeTransaction,
} from "../store/slices/transactionSlice";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";
import { ChevronLeft, ChevronRight, Edit, Trash2 } from "lucide-react";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ExpenseDirection = () => {
  const dispatch = useDispatch();
  const { activeSociety } = useSidebarContext();
  const [dateRange, setDateRange] = useState("All Dates");
  const activeUser = useSelector((state) => state.auth.activeUser);
  // Consolidated filter state
  const [filters, setFilters] = useState({
    name: "",
    society: "",
    transactionType: "",
    transactionDirection: "",
    amount: "",
    date: "",
    payment_method: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const plotsPerPage = 10;
  const isEmployee = activeUser?.role === "Employee";


    
const transactions = useMemo(() => {
    return activeSociety?.FinancialTransaction || [];
  }, [activeSociety]);
  const filterTransactionsBySociety = (transaction) => {
    if (!activeSociety) return true;

    const societyId = activeSociety._id;

    // Check if societies exist and match the active society
    const relatedToSociety =
      transaction.societies?.some((society) => society._id === societyId) ||
      (transaction.customer_id?.societies &&
        transaction.customer_id.societies.some(
          (society) => society._id === societyId
        )) ||
      (transaction.employee_id?.society &&
        transaction.employee_id.society._id === societyId) ||
      (transaction.user_id?.investorData?.societies &&
        transaction.user_id.investorData.societies.some(
          (society) => society._id === societyId
        ));

    // Allow transactions with no specific user/society association
    return relatedToSociety || !transaction.societies;
  };

   const sortTransactionsByDate = (transactions) => {
    return [...transactions].sort((a, b) => {
      const dateA = new Date(a.transaction_date);
      const dateB = new Date(b.transaction_date);
      return dateB - dateA;
    });
  };
  const getDateFilteredTransactions = () => {
    const now = new Date();
    let startDate;

    switch (dateRange) {
      case "Last Year":
        startDate = new Date(
          now.getFullYear() - 1,
          now.getMonth(),
          now.getDate()
        );
        break;
      case "Last 6 Months":
        startDate = new Date(now.setMonth(now.getMonth() - 6));
        break;
      case "Last 3 Months":
        startDate = new Date(now.setMonth(now.getMonth() - 3));
        break;
      case "Last Month":
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case "Last Day":
        startDate = new Date(now.setDate(now.getDate() - 1));
        break;
      case "Today":
        startDate = new Date(now.setHours(0, 0, 0, 0));
        break;
      case "All Dates":
        return transactions.filter(
          (t) =>
            t.transaction_direction === "Expense" &&
            filterTransactionsBySociety(t)
        );
      default:
        return transactions.filter(
          (t) => t.transaction_direction === "Expense"
        );
    }

    return transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.transaction_date);
      return (
        transactionDate >= startDate &&
        transaction.transaction_direction === "Expense" &&
        filterTransactionsBySociety(transaction)
      );
    });
  };

 
const filteredTransactions = useMemo(() => {
    const dateFiltered = getDateFilteredTransactions();
    return sortTransactionsByDate(
      dateFiltered.filter((transaction) => {
        const matchesTransactionType = filters.transactionType
          ? transaction.transaction_type === filters.transactionType
          : true;
        const matchesName = filters.name
          ? transaction.customer_id?.name
              ?.toLowerCase()
              .includes(filters.name.toLowerCase()) ||
            transaction.employee_id?.name
              ?.toLowerCase()
              .includes(filters.name.toLowerCase())
          : true;
        const matchesSociety = filters.society
          ? transaction.customer_id?.societies?.some((s) =>
              s.name.toLowerCase().includes(filters.society.toLowerCase())
            )
          : true;
        const matchesAmount = filters.amount
          ? transaction.amount.toString().includes(filters.amount)
          : true;
        const matchesDate = filters.date
          ? new Date(transaction.transaction_date).toDateString() ===
            new Date(filters.date).toDateString()
          : true;
        const matchesTransactionDirection = filters.transactionDirection
          ? transaction.transaction_direction === filters.transactionDirection
          : true;
  
        return (
          matchesTransactionType &&
          matchesName &&
          matchesSociety &&
          matchesAmount &&
          matchesDate &&
          matchesTransactionDirection
        );
      })
    );
  }, [transactions, filters ]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  
  const handleEdit = (transaction) => {
      if (isEmployee) return;
  
      // Create a ref to store the selected date
      const dateRef = React.createRef();
  
      const CustomDialog = () => {
        // Local state for the date
        const [dateValue, setDateValue] = React.useState(
          new Date(transaction.transaction_date)
        );
  
        return (
          <div className="max-w-3xl mx-auto p-6">
            <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 rounded-xl shadow-2xl border border-slate-700">
              <h2 className="text-3xl font-bold text-teal-300 mb-6">
                Edit Transaction
              </h2>
              <div className="grid grid-cols-2 gap-6">
                {[
                  {
                    id: "amount",
                    label: "Amount",
                    type: "number",
                    value: transaction.amount,
                  },
                  {
                    id: "transaction_type",
                    label: "Transaction Type",
                    type: "text",
                    value: transaction.transaction_type,
                  },
                  {
                    id: "transaction_direction",
                    label: "Transaction Direction",
                    type: "text",
                    value: transaction.transaction_direction,
                  },
                  {
                    id: "payment_method",
                    label: "Payment Method",
                    type: "text",
                    value: transaction.payment_method,
                  },
                  {
                    id: "status",
                    label: "Status",
                    type: "text",
                    value: transaction.status,
                  },
                  {
                    id: "description",
                    label: "Description",
                    type: "text",
                    value: transaction.description,
                  },
                  {
                    id: "receipt_no",
                    label: "Receipt No",
                    type: "text",
                    value: transaction.receipt_no,
                  },
                ].map((field) => (
                  <div key={field.id} className="relative">
                    <label className="block text-teal-300 text-sm font-medium mb-2">
                      {field.label}
                    </label>
                    <input
                      id={field.id}
                      type={field.type}
                      className="w-full px-4 py-2.5 rounded-lg border border-slate-600 bg-slate-700 text-white 
                               placeholder-slate-400 focus:border-teal-400 focus:ring-2 focus:ring-teal-400 
                               focus:ring-opacity-50 transition-all duration-200 ease-in-out"
                      defaultValue={field.value}
                    />
                  </div>
                ))}
                <div className="relative">
                  <label className="block text-teal-300 text-sm font-medium mb-2">
                    Transaction Date
                  </label>
                  <DatePicker
                    selected={dateValue}
                    onChange={(date) => {
                      setDateValue(date);
                      dateRef.current = date;
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="w-full px-4 py-2.5 rounded-lg border border-slate-600 bg-slate-700 text-white 
                             placeholder-slate-400 focus:border-teal-400 focus:ring-2 focus:ring-teal-400 
                             focus:ring-opacity-50 transition-all duration-200 ease-in-out"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      };
  
      Swal.fire({
        html: ReactDOMServer.renderToString(<CustomDialog />),
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        cancelButtonText: "Cancel",
        width: "auto",
        background: "#1E293B",
        customClass: {
          popup:
            "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl text-teal-400",
          confirmButton:
            "bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200",
          cancelButton:
            "bg-red-500 hover:bg-red-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200",
          title: "text-2xl font-bold text-teal-300 mb-4",
          container: "backdrop-blur-sm",
        },
        didOpen: () => {
          // Mount the actual React component
          const container = document.querySelector(".swal2-html-container");
          if (container) {
            ReactDOM.render(<CustomDialog />, container);
          }
        },
        willClose: () => {
          // Cleanup
          const container = document.querySelector(".swal2-html-container");
          if (container) {
            ReactDOM.unmountComponentAtNode(container);
          }
        },
        preConfirm: () => {
          return {
            transaction_date: dateRef.current
              ? dateRef.current.toISOString()
              : transaction.transaction_date,
            amount: document.getElementById("amount").value,
            transaction_type: document.getElementById("transaction_type").value,
            transaction_direction: document.getElementById(
              "transaction_direction"
            ).value,
            payment_method: document.getElementById("payment_method").value,
            status: document.getElementById("status").value,
            description: document.getElementById("description").value,
            receipt_no: document.getElementById("receipt_no").value,
          };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            modifyTransaction({
              id: transaction._id,
              transactionData: {
                ...transaction,
                ...result.value,
              },
            })
          );
  
          Swal.fire({
            title: "Success!",
            text: "Transaction has been updated successfully",
            icon: "success",
            confirmButtonColor: "#10B981",
            background: "#1E293B",
            color: "#5EEAD4",
            confirmButtonText: "Done",
            customClass: {
              popup:
                "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl",
              confirmButton:
                "hover:bg-emerald-600 transition-colors px-6 py-2.5 rounded-lg",
              title: "text-xl font-bold text-teal-300",
            },
          });
        }
      });
    };
  const handleDelete = (transactionId) => {
    if (isEmployee) return;
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      background: "#1E293B",
      customClass: {
        popup:
          "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl text-teal-400",
        confirmButton:
          "bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200",
        cancelButton:
          "bg-red-500 hover:bg-red-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200",
        title: "text-2xl font-bold text-teal-300 mb-4",
        container: "backdrop-blur-sm",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeTransaction(transactionId));
       
        Swal.fire({
          title: "Deleted!",
          text: "The transaction has been deleted.",
          icon: "success",
          confirmButtonColor: "#10B981",
          background: "#1E293B",
          color: "#5EEAD4",
          confirmButtonText: "Done",
          customClass: {
            popup:
              "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl",
            confirmButton:
              "hover:bg-emerald-600 transition-colors px-6 py-2.5 rounded-lg",
            title: "text-xl font-bold text-teal-300",
          },
        });
      }
    });
  };

  const getTransactionTypeColor = (transactionType) => {
    const colors = {
      'Full Payment': 'bg-green-500/20 hover:bg-green-500/30',
      'Partial Payment': 'bg-green-500/20 hover:bg-green-500/30',
      'Installment Payment': 'bg-purple-500/20 hover:bg-purple-500/30',
      'Salary Payment': 'bg-yellow-500/20 hover:bg-yellow-500/30',
      'Expense Payment': 'bg-red-500/20 hover:bg-red-500/30',
      'Resell Payment': 'bg-indigo-500/20 hover:bg-indigo-500/30',
      'Other': 'bg-green-500/20 hover:bg-green-500/30',
      'Dealer Commission': 'bg-orange-500/20 hover:bg-orange-500/30',
      'Booking Full Payment': 'bg-cyan-500/20 hover:bg-cyan-500/30',
      'Booking Installment Payment': 'bg-cyan-500/20 hover:bg-cyan-500/30',
      'Transfer Fee': 'bg-pink-500/20 hover:bg-pink-500/30'
    };
    return colors[transactionType] || 'bg-green-500/20 hover:bg-green-500/30';
  };

  // Get transactions for the current page
  const indexOfLastTransaction = currentPage * plotsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - plotsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );


  const calculateTotal = (transactions) =>{
    // transactions.reduce((sum, t) => sum + Number(t.amount || 0), 0);
  const societyTransactions = transactions.filter(filterTransactionsBySociety);
  return societyTransactions.reduce((sum, t) => sum + Number(t.amount || 0), 0);
  }
  const paymentExpense = transactions
    .filter(filterTransactionsBySociety)
    .filter((t) => t.transaction_direction === "Expense");

  const totalExpense = calculateTotal(paymentExpense);

  const exportTransactionsToExcel = (transactions, period) => {
    const societyFilteredTransactions = transactions.filter(
      filterTransactionsBySociety
    );
    const dataToExport = societyFilteredTransactions.map((transaction) => ({
      Date: new Date(transaction.transaction_date).toLocaleDateString(),
      "Customer Name":
        transaction.customer_id?.name ||
        transaction.employee_id?.name ||
        transaction.user_id?.investorData?.name ||
        "N/A",
      "Society Name":
        transaction.customer_id?.societies
          ?.map((society) => society.name)
          .join(", ") ||
        transaction.employee_id?.society?.name ||
        "N/A",
      "Plot Number": transaction.plot_id?.plot_number || "No Plot",
      Description: transaction.description || "N/A",
      Amount: `${transaction.amount?.toLocaleString()} PKR`,
      Status: transaction.status || "N/A",
      "Payment Method": transaction.payment_method || "N/A",
      "Receipt No": transaction.receipt_no || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Expense Transactions");

    const fileName = `${activeSociety.name}_income_transactions_${period}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  const handleExportToExcel = () => {
    Swal.fire({
      title: `Export Income Transactions - ${activeSociety.name}`,
      html: `
      <div class="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6 rounded-xl">
        <p class="text-teal-300 mb-4">Select the period for export:</p>
       <select 
  id="exportPeriod" 
  class="swal2-select bg-slate-800 text-teal-300 border border-slate-700 rounded-md p-2 outline-none focus:ring-2 focus:ring-teal-500">
  <option value="all" class="bg-slate-800 text-teal-300">All Time</option>
  <option value="day" class="bg-slate-800 text-teal-300">Today</option>
  <option value="week" class="bg-slate-800 text-teal-300">This Week</option>
  <option value="month" class="bg-slate-800 text-teal-300">This Month</option>
  <option value="year" class="bg-slate-800 text-teal-300">This Year</option>
</select>
      </div>
    `,
      confirmButtonText: "Export",
      focusConfirm: false,
      background: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
      customClass: {
        popup: "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
        title: "text-teal-300",
        confirmButton:
          "bg-teal-600 hover:bg-teal-700 text-white px-6 py-2 rounded-lg",
        content: "bg-slate-800 p-6 rounded-lg",
      },
      preConfirm: () => {
        return document.getElementById("exportPeriod").value;
      },
      willOpen: () => {
        const style = document.createElement("style");
        style.innerHTML = `
        #exportPeriod {
          color: #81E6D9; 
        }
        #exportPeriod:focus {
          background-color: #1E293B;
        }
      `;
        document.head.appendChild(style);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const period = result.value;
        exportTransactionsToExcel(getDateFilteredTransactions(), period);
      }
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(filteredTransactions.length / plotsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(filteredTransactions.length / plotsPerPage);
    let pages = [];

    if (totalPages <= 5) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, 5];
      } else if (currentPage >= totalPages - 2) {
        pages = Array.from({ length: 5 }, (_, i) => totalPages - 4 + i);
      } else {
        pages = [
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        ];
      }
    }

    return pages.map((pageNum) => (
      <button
        key={pageNum}
        onClick={() => setCurrentPage(pageNum)}
        className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium transition-all duration-300 ${
          pageNum === currentPage
            ? "bg-teal-500 text-white shadow-lg scale-110"
            : "bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white"
        }`}
      >
        {pageNum}
      </button>
    ));
  };

  return (
    <div className=" bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-4 md:p-8">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            {activeSociety && (
              <div className="flex items-center gap-3 mt-2">
                <div className="w-16 h-16 rounded-full overflow-hidden border-2 border-teal-400">
                  <img
                    src={
                      activeSociety.society_image || "/api/placeholder/40/40"
                    }
                    alt={activeSociety.name}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.src = "/api/placeholder/40/40";
                    }}
                  />
                </div>
                <div className="text-left">
                  <p className="text-3xl text-teal-400 font-bold">
                    {activeSociety.name}
                  </p>
                  <h2 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-teal-300 to-teal-500 bg-clip-text text-transparent">
                    Financial Sales Analysis
                  </h2>
                </div>
              </div>
            )}
          </div>
          {/* {loading && (
            <div className="text-sm text-gray-400 animate-pulse">
              Loading data...
            </div>
          )} */}
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="ml-4 bg-slate-800 text-gray-400 border border-slate-700 p-2 rounded"
          >
            <option>All Dates</option>
            <option>Last Year</option>
            <option>Last 6 Months</option>
            <option>Last 3 Months</option>
            <option>Last Month</option>
            <option>Last Day</option>
            <option>Today</option>
          </select>
        </div>

        {/* Total Expense Card */}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 items-center">
          <div className="bg-slate-800/50 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700">
            <div className="p-6">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-gradient-to-r from-teal-400/10 to-teal-400/10 rounded-full">
                  <div className="w-6 h-6 text-teal-400">📊</div>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-400">
                    Total Expense
                  </p>
                  <p className="text-xl font-bold bg-gradient-to-r from-teal-300 to-teal-400 bg-clip-text text-transparent">
                    {totalExpense.toLocaleString()} PKR
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 gap-3">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={filters.name}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none"
          />
          <input
            type="text"
            name="society"
            placeholder="Society"
            value={filters.society}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none"
          />
          <div className="flex-1">
            <select
              name="transactionType"
              value={filters.transactionType}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none"
            >
              <option value="">All Types</option>
              <option value="Full Payment">Full Payment</option>
              <option value="Partial Payment">Partial Payment</option>
              <option value="Installment Payment">Installment Payment</option>
              <option value="Salary Payment">Salary Payment</option>
              <option value="Dealer Commission">Dealer Commission</option>
              <option value="Expense Payment">Expense Payment</option>
              <option value="Other Payment">Other Payment</option>
            </select>
          </div>
          <input
            type="text"
            name="amount"
            placeholder="Amount"
            value={filters.amount}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none"
          />
          <div className="relative">
            <input
              type="date"
              name="date"
              placeholder="Date"
              value={filters.date}
              onChange={handleFilterChange}
              className="w-full px-4 py-2 rounded-lg text-teal-400 bg-slate-700 focus:bg-slate-600 focus:outline-none appearance-none pl-10"
            />
            <span className="absolute left-3 top-2.5 text-teal-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4V2m0 12h8m-8 0H4m8-8V4m4 4h4m-4 4h4"
                />
              </svg>
            </span>
          </div>
        </div>

        {/* Transactions Table */}
        <div className="bg-slate-800/50 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700 overflow-hidden">
          <div className="p-6 border-b border-slate-700 flex justify-between items-center">
            <h3 className="text-3xl font-semibold bg-gradient-to-r from-teal-300 to-teal-400 bg-clip-text text-transparent">
              Expense Transactions
            </h3>
            <button
              onClick={handleExportToExcel}
              className="px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-teal-600 transition"
            >
              Export to Excel
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full bg-slate-800 rounded-lg text-teal-300">
              <thead>
                <tr className="bg-slate-900 rounded-lg text-teal-300">
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Societies
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Transaction Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Transaction Direction
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                    Description
                  </th>
                  {!isEmployee && (
                    <th className="px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-700">
                {currentTransactions.map((transaction, index) => (
                  <tr
                    key={transaction._id}
                    className={`transition-colors duration-300 ${getTransactionTypeColor(
                      transaction.transaction_type
                    )}`}
                  >
                    <td className=" border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.transaction_type === "Salary Payment" ? (
                        <>
                          Employee
                          <br />
                          <span className="text-md font-semibold">
                            {transaction.employee_id?.name || "No Employee"}
                          </span>
                        </>
                      ) : transaction.transaction_type === "Expense Payment" ? (
                        <>
                          Investor
                          <br />
                          <span className="text-md font-semibold">
                            {transaction.user_id?.investorData?.name ||
                              "No Investor"}
                          </span>
                        </>
                      ) : (
                        <>
                          Customer
                          <br />
                          <span className="text-md font-semibold">
                            {transaction.customer_id?.name || "No Customer"}
                          </span>
                        </>
                      )}
                    </td>
                    <td className=" border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.transaction_type === "Salary Payment"
                        ? transaction.employee_id?.society?.name || "No Society"
                        : transaction.transaction_type === "Expense Payment" &&
                          transaction.user_id?.investorData?.societies &&
                          transaction.user_id.investorData.societies.length > 0
                        ? transaction.user_id.investorData.societies
                            .map((society) => society.name)
                            .join(", ")
                        : transaction.transaction_type === "Expense Payment"
                        ? "No Society"
                        : transaction.societies &&
                          transaction.societies.length > 0
                        ? transaction.societies
                            .map((society) => society.name)
                            .join(", ")
                        : "No Society"}
                      <br></br>
                      Plot no. {transaction.plot_id?.plot_number || "No Plot"}
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.transaction_type}
                      <br />
                      <div className="text-teal-400 mt-1">
                        Recipt no. {transaction.receipt_no}
                      </div>
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.transaction_direction}
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.amount.toLocaleString()} PKR
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {new Date(
                        transaction.transaction_date
                      ).toLocaleDateString("en-GB")}
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.status}
                    </td>
                    <td className="border-b px-6 py-4 text-sm text-teal-300">
                      {transaction.description}
                    </td>
                    {!isEmployee && (
                      <td className="border-b px-6 py-4 text-sm text-teal-300">
                        <div className="flex items-center gap-2">
                          {/* Edit Icon */}
                          <button
                            onClick={() => handleEdit(transaction)}
                            className="p-2 rounded-xl bg-teal-300/10 hover:bg-teal-300/20 text-teal-300 transition-colors duration-300"
                            title="Edit"
                          >
                            <Edit className="w-5 h-5" />
                          </button>

                          {/* Delete Icon */}
                          <button
                            onClick={() => handleDelete(transaction._id)}
                            className="p-2 rounded-xl bg-red-400/10 hover:bg-red-400/20 text-red-400 transition-colors duration-300"
                            title="Delete"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex space-x-2"></div>
        </div>
        <div className="flex justify-center items-center gap-2 sm:gap-4 mt-4 mb-5">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`
        flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg
        transition-all duration-300
        ${
          currentPage === 1
            ? "bg-slate-600 cursor-not-allowed"
            : "bg-slate-700 text-teal-300 hover:bg-slate-600"
        }
      `}
          >
            <ChevronLeft
              className={`w-4 h-4 ${
                currentPage === 1 ? "text-slate-400" : "text-teal-300"
              }`}
            />
          </button>

          <div className="flex items-center gap-2">
            {currentPage > 3 &&
              Math.ceil(filteredTransactions.length / plotsPerPage) > 5 && (
                <>
                  <button
                    onClick={() => setCurrentPage(1)}
                    className="flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg text-sm font-medium bg-slate-700 text-teal-300 hover:bg-slate-600 transition-all duration-300"
                  >
                    1
                  </button>
                  <span className="text-teal-300">...</span>
                </>
              )}

            {renderPageNumbers()}

            {currentPage <
              Math.ceil(filteredTransactions.length / plotsPerPage) - 2 &&
              Math.ceil(filteredTransactions.length / plotsPerPage) > 5 && (
                <>
                  <span className="text-teal-300">...</span>
                  <button
                    onClick={() =>
                      setCurrentPage(
                        Math.ceil(filteredTransactions.length / plotsPerPage)
                      )
                    }
                    className="flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg text-sm font-medium bg-slate-700 text-teal-300 hover:bg-slate-600 transition-all duration-300"
                  >
                    {Math.ceil(filteredTransactions.length / plotsPerPage)}
                  </button>
                </>
              )}
          </div>

          <button
            onClick={handleNextPage}
            disabled={
              currentPage ===
              Math.ceil(filteredTransactions.length / plotsPerPage)
            }
            className={`
        flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg
        transition-all duration-300
        ${
          currentPage === Math.ceil(filteredTransactions.length / plotsPerPage)
            ? "bg-slate-600 cursor-not-allowed"
            : "bg-slate-700 text-teal-300 hover:bg-slate-600"
        }
      `}
          >
            <ChevronRight
              className={`w-4 h-4 ${
                currentPage ===
                Math.ceil(filteredTransactions.length / plotsPerPage)
                  ? "text-slate-400"
                  : "text-teal-300"
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpenseDirection;
