import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransactions,
  modifyTransaction,
  removeTransaction,
} from "../store/slices/transactionSlice";
import {
  Blocks,
  Loader2,
  Edit,
  Trash,
  Building,
  Filter,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";
import { motion } from "framer-motion";

const OtherExpense = () => {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.transactions);
  const { societies } = useSelector((state) => state.societies);
  const { activeSociety } = useSidebarContext();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [directionFilter, setDirectionFilter] = useState("");
  const [editingTransaction, setEditingTransaction] = useState(null);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  const excludedTransactionTypes = [
    "Full Payment",
    "Partial Payment",
    "Installment Payment",
    "Salary Payment",
    "Expense Payment",
    "Resell Payment",
    "Booking Full Payment",
    "Dealer Commission",
    "Booking Installment Payment"
  ];

  // Filtering logic
  const filteredTransactions = transactions
    .filter((t) => !excludedTransactionTypes.includes(t.transaction_type))
    .filter((t) => !descriptionFilter || t.description === descriptionFilter)
    .filter(
      (t) => !directionFilter || t.transaction_direction === directionFilter
    )
    .filter((t) => t.societies?.[0]?.name === activeSociety?.name);

  // const descriptions = [
  //   ...new Set(
  //     filteredTransactions.map((t) => t.description || "No Description")
  //   ),
  // ];

  const descriptions = [
    ...new Set(
      filteredTransactions
        .filter((t) => t.description) // This removes any falsy descriptions
        .map((t) => t.description)
    ),
  ];
  const directions = [
    ...new Set(
      filteredTransactions.map((t) => t.transaction_direction || "Not Defined")
    ),
  ];

  // Pagination logic
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  // Edit transaction handler with immediate update
  const handleEditTransaction = (updatedTransaction) => {
    const updateTransaction = async () => {
      try {
        await dispatch(
          modifyTransaction({
            id: updatedTransaction._id,
            transactionData: updatedTransaction,
          })
        ).unwrap();

        // Optimistically update the transaction in the local state
        dispatch({
          type: "transactions/updateTransaction",
          payload: updatedTransaction,
        });

        setEditingTransaction(null);
      } catch (error) {
        console.error("Error updating transaction:", error);
      }
    };

    updateTransaction();
  };

  // Delete transaction with confirmation
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this transaction?"
    );
    if (confirmDelete) {
      dispatch(removeTransaction(id));
    }
  };

  // Render edit modal as a separate component for cleaner code
  const EditTransactionModal = ({ transaction, onClose, onSave }) => {
    const [editForm, setEditForm] = useState({
      ...transaction,
      societies: transaction.societies?.[0]?.name || "",
      transaction_direction: transaction.transaction_direction || "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;

      setEditForm((prev) => {
        switch (name) {
          case "societies":
            return { ...prev, societies: value }; // handle societies as string
          case "transaction_date":
            return { ...prev, [name]: value }; // directly update the date
          default:
            return { ...prev, [name]: value }; // for other fields
        }
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      // Basic validation
      if (!editForm.societies || !editForm.transaction_direction) {
        alert("Please select a society and transaction direction");
        return;
      }

      // Prepare the transaction data for saving
      const updatedTransaction = {
        ...transaction,
        ...editForm,
        societies: [{ name: editForm.societies }],
        transaction_direction: editForm.transaction_direction,
      };

      onSave(updatedTransaction);
    };

    // return (
    //   <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex items-center justify-center z-50">
    //     <div className="bg-gray-800 rounded-2xl shadow-2xl p-8 max-w-md w-full animate-fade-in-up">
    //       <h2 className="text-2xl font-bold text-teal-300 mb-6 border-b border-teal-700 pb-3">
    //         Edit Transaction
    //         <div className="flex items-center gap-3">
    //           <Building className="w-5 h-5 text-teal-400" />
    //           <span className="bg-gradient-to-r from-teal-400 to-emerald-400 bg-clip-text text-transparent text-xl font-semibold">
    //             {activeSociety.name} - {activeSociety.location}
    //           </span>
    //         </div>
    //       </h2>

    //       <form onSubmit={handleSubmit} className="space-y-4">
    //         {/* Amount Input */}
    //         <div>
    //           <label className="block text-teal-300 mb-2">Amount</label>
    //           <input
    //             type="number"
    //             name="amount"
    //             value={editForm.amount || ""}
    //             onChange={handleChange}
    //             className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
    //           />
    //         </div>

    //         {/* Direction Dropdown */}
    //         <div>
    //           <label className="block text-teal-300 mb-2">Direction</label>
    //           <select
    //             name="transaction_direction"
    //             value={editForm.transaction_direction || ""}
    //             onChange={handleChange}
    //             className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
    //           >
    //             <option value="">Select Direction</option>
    //             {directions.map((dir, index) => (
    //               <option key={index} value={dir}>
    //                 {dir}
    //               </option>
    //             ))}
    //           </select>
    //         </div>

    //         {/* Date Input */}
    //         <div>
    //           <label className="block text-teal-300 mb-2">Date</label>
    //           <input
    //             type="date"
    //             name="transaction_date"
    //             value={
    //               editForm.transaction_date
    //                 ? new Date(editForm.transaction_date)
    //                     .toISOString()
    //                     .split("T")[0]
    //                 : ""
    //             }
    //             onChange={handleChange}
    //             className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
    //           />
    //         </div>

    //         {/* Description Input */}
    //         {/* <div>
    //           <label className="block text-teal-300 mb-2">Description</label>
    //           <input
    //             type="text"
    //             name="description"
    //             value={editForm.description || ""}
    //             onChange={handleChange}
    //             className="w-full bg-gray-700 text-teal-300 rounded-lg py-2 px-3 focus:ring-2 focus:ring-teal-500 transition-all"
    //           />
    //         </div> */}

    //         <div className="flex justify-end space-x-4 pt-4">
    //           <button
    //             type="button"
    //             onClick={onClose}
    //             className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
    //           >
    //             Cancel
    //           </button>
    //           <button
    //             type="submit"
    //             className="px-4 py-2 bg-teal-500 text-white rounded-lg hover:bg-teal-600 transition-colors"
    //           >
    //             Save
    //           </button>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // );
  };

  return (
    <div className=" bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-6">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="relative overflow-hidden backdrop-blur-lg bg-white/5 rounded-2xl p-8 mb-2 shadow-2xl border border-white/10">
          <div className="absolute inset-0 bg-gradient-to-r from-teal-500/10 to-emerald-500/10 pointer-events-none" />
          <div className="relative z-10">
            <div className="flex items-center gap-6 mb-4">
              <div className="bg-gradient-to-br from-teal-400 to-emerald-500 p-4 rounded-2xl shadow-xl">
                <Blocks className="w-8 h-8 text-white" />
              </div>
              <div>
                <h1 className="text-5xl font-bold text-teal-300 mb-2">
                  Miscellaneous Expenses
                </h1>
                {activeSociety && (
                  <div className="flex items-center gap-3">
                    <Building className="w-5 h-5 text-teal-400" />
                    <span className="bg-gradient-to-r from-teal-400 to-emerald-400 bg-clip-text text-transparent text-xl font-semibold">
                      {activeSociety.name} - {activeSociety.location}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modern Filters Section */}
        <div className="backdrop-blur-md bg-white/5 rounded-xl p-6 mb-2 border border-white/10 shadow-lg">
          <div className="flex items-center gap-4 mb-4">
            <Filter className="w-5 h-5 text-teal-400" />
            <h2 className="text-lg font-semibold text-teal-300">Filters</h2>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="block text-teal-300 font-medium">
                Description
              </label>
              <div className="relative">
                <select
                  value={descriptionFilter}
                  onChange={(e) => {
                    setDescriptionFilter(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="w-full bg-slate-800/50 text-teal-300 rounded-lg py-3 px-4 appearance-none border border-teal-500/20 focus:border-teal-500 transition-colors hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20"
                >
                  <option value="" className="bg-slate-800 text-teal-300">
                    All Descriptions
                  </option>
                  {descriptions
                    .filter((desc) => desc !== "No Description")
                    .map((desc, index) => (
                      <option
                        key={index}
                        value={desc}
                        className="bg-slate-800 text-teal-300"
                      >
                        {desc}
                      </option>
                    ))}
                </select>
                <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-teal-400 pointer-events-none" />
              </div>
            </div>
            <div className="space-y-2">
              <label className="block text-teal-300 font-medium">
                Direction
              </label>
              <div className="relative">
                <select
                  value={directionFilter}
                  onChange={(e) => {
                    setDirectionFilter(e.target.value);
                    setCurrentPage(1);
                  }}
                  className="w-full bg-slate-800/50 text-teal-300 rounded-lg py-3 px-4 appearance-none border border-teal-500/20 focus:border-teal-500 transition-colors hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20"
                >
                  <option value="" className="bg-slate-800 text-teal-300">
                    All Directions
                  </option>
                  {directions.map((dir, index) => (
                    <option
                      key={index}
                      value={dir}
                      className="bg-slate-800 text-teal-300"
                    >
                      {dir}
                    </option>
                  ))}
                </select>
                <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-teal-400 pointer-events-none" />
              </div>
            </div>
          </div>
        </div>

        {/* Enhanced Table Section */}
        <div className="backdrop-blur-md bg-white/5 rounded-xl shadow-2xl border border-white/10 overflow-hidden">
          {loading ? (
            <div className="flex flex-col items-center justify-center py-20">
              <div className="relative">
                <div className="w-16 h-16 rounded-full border-4 border-teal-400/20 border-t-teal-400 animate-spin" />
                <Loader2 className="w-8 h-8 text-teal-400 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
              </div>
              <p className="text-teal-300 mt-4 font-medium">
                Loading transactions...
              </p>
            </div>
          ) : filteredTransactions.length === 0 ? (
            <div className="text-center py-20">
              <div className="bg-teal-400/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <Building className="w-8 h-8 text-teal-400" />
              </div>
              <p className="text-teal-300 text-lg font-medium">
                No transactions found for{" "}
                {activeSociety?.name || "the selected society"}
              </p>
            </div>
          ) : (
            <>
              <div className="overflow-x-auto">
                <table className="w-full text-teal-300">
                  <thead className="bg-slate-800/80 border-b border-white/10">
                    <tr>
                      {[
                        "Society",
                        "Type",
                        "Amount",
                        "Direction",
                        "Date",
                        "Description",
                        // "Actions",
                      ].map((header) => (
                        <th
                          key={header}
                          className="px-6 py-4 text-left font-semibold"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTransactions.map((transaction, idx) => (
                      <tr
                        key={transaction._id}
                        className="border-b border-white/5 hover:bg-white/5 transition-colors"
                      >
                        <td className="px-6 py-4 font-medium">
                          {transaction.societies?.[0]?.name || "No Society"}{" "}
                          <br></br>
                        </td>
                        <td className="px-6 py-4">
                          {transaction.transaction_type}
                        </td>
                        <td className="px-6 py-4 font-medium text-emerald-400">
                          {new Intl.NumberFormat("en-PK", {
                            style: "currency",
                            currency: "PKR",
                          }).format(transaction.amount)}
                        </td>
                        <td className="px-6 py-4">
                          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-teal-400/10 text-teal-400">
                            {transaction.transaction_direction || "Not Defined"}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          {new Date(
                            transaction.transaction_date
                          ).toLocaleDateString("en-GB")}
                        </td>
                        <td className="px-6 py-4">
                          {transaction.description || "No Description"}
                        </td>
                        {/* <td className="px-6 py-4">
                          <div className="flex gap-3">
                            <button
                              onClick={() => setEditingTransaction(transaction)}
                              className="p-2 rounded-lg bg-teal-400/10 text-teal-400 hover:bg-teal-400/20 transition-colors"
                            >
                              <Edit className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => handleDelete(transaction._id)}
                              className="p-2 rounded-lg bg-red-400/10 text-red-400 hover:bg-red-400/20 transition-colors"
                            >
                              <Trash className="w-4 h-4" />
                            </button>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Enhanced Pagination */}
              <div className="flex justify-between items-center px-6 py-4 border-t border-white/10">
                <div className="text-sm text-teal-300">
                  Showing{" "}
                  <span className="font-medium">
                    {indexOfFirstTransaction + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(
                      indexOfLastTransaction,
                      filteredTransactions.length
                    )}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {filteredTransactions.length}
                  </span>{" "}
                  transactions
                </div>
                <div className="flex items-center gap-4">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(1, prev - 1))
                    }
                    disabled={currentPage === 1}
                    className="px-4 py-2 rounded-lg bg-gradient-to-r from-teal-500 to-emerald-500 text-white font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:from-teal-600 hover:to-emerald-600 transition-all duration-300 shadow-lg disabled:shadow-none"
                  >
                    Previous
                  </button>
                  <span className="text-teal-300 font-medium">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(totalPages, prev + 1))
                    }
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 rounded-lg bg-gradient-to-r from-teal-500 to-emerald-500 text-white font-medium disabled:opacity-50 disabled:cursor-not-allowed hover:from-teal-600 hover:to-emerald-600 transition-all duration-300 shadow-lg disabled:shadow-none"
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Edit Transaction Modal */}
      {/* {editingTransaction && (
        <EditTransactionModal
          transaction={editingTransaction}
          onClose={() => setEditingTransaction(null)}
          onSave={handleEditTransaction}
        />
      )} */}
    </div>
  );
};

export default OtherExpense;
