import React, { useEffect, useState, useRef } from "react";
import {
  Menu,
  Bell,
  ChevronDown,
  User,
  Settings,
  Building2,
  Check,
  Trash2,
} from "lucide-react";

import { useSidebarContext } from "./sidebar/SidebarContext";
import { useProSidebar } from "react-pro-sidebar";
import Logout from "../../Auth/Logout";
import { useDispatch, useSelector } from "react-redux";
import { checkActiveUser } from "../../store/slices/authSlice";
import { fetchSocieties } from "../../store/slices/societySlice";
import {
  getUserNotifications,
  markAsRead,
  deleteNotificationThunk,
} from "../../store/slices/notificationSlice";
import { Link } from "react-router-dom";
const ACTIVE_SOCIETY_KEY = "activeSociety";
const Topbar = () => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSocietyDropdownOpen, setIsSocietyDropdownOpen] = useState(false);
  const [selectedSociety, setSelectedSociety] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const notificationRef = useRef(null);
  const profileDropdownRef = useRef(null);
  const societyDropdownRef = useRef(null);

  const dispatch = useDispatch();
  const { activeUser, isAuthenticated } = useSelector((state) => state.auth);
  const { societies } = useSelector((state) => state.societies);
  const { notifications } = useSelector((state) => state.notifications);

  const { setActiveSociety, gradient, theme   } = useSidebarContext();
  const { toggleSidebar, broken } = useProSidebar();
 // Initialize selected society from localStorage or context
 useEffect(() => {
  const savedSociety = localStorage.getItem(ACTIVE_SOCIETY_KEY);
  if (savedSociety) {
    const parsedSociety = JSON.parse(savedSociety);
    setSelectedSociety(parsedSociety.name);
  }
}, []);
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(checkActiveUser());
      dispatch(fetchSocieties());
      if (activeUser?.role === "Admin") {
        dispatch(getUserNotifications(activeUser.id));
      } else if (activeUser?.id) {
        dispatch(getUserNotifications(activeUser.id));
      }
    }
  }, [dispatch, isAuthenticated ]); 
  const handleSocietySelection = (society) => {
    setSelectedSociety(society.name);
    setActiveSociety(society);
    localStorage.setItem(ACTIVE_SOCIETY_KEY, JSON.stringify(society));
    setIsSocietyDropdownOpen(false);
    
    // Refresh societies data when switching
    dispatch(fetchSocieties());
  };
  useEffect(() => {
    if (!societies?.length) return;

    const initializeSociety = () => {
      const savedSociety = localStorage.getItem(ACTIVE_SOCIETY_KEY);
      if (savedSociety) {
        const parsedSociety = JSON.parse(savedSociety);
        // Find the fresh society data from the current societies list
        const currentSociety = societies.find(s => s._id === parsedSociety._id);
        if (currentSociety) {
          setSelectedSociety(currentSociety.name);
          setActiveSociety(currentSociety);
          return;
        }
      }
      // If no valid saved society, set based on user role
      if (activeUser?.role === "Employee") {
        const employeeSociety = societies.find(society => 
          activeUser.societies?.some(userSociety => userSociety._id === society._id)
        );
        if (employeeSociety) {
          setSelectedSociety(employeeSociety.name);
          setActiveSociety(employeeSociety);
          localStorage.setItem(ACTIVE_SOCIETY_KEY, JSON.stringify(employeeSociety));
        }
      } else {
        setSelectedSociety(societies[0].name);
        setActiveSociety(societies[0]);
        localStorage.setItem(ACTIVE_SOCIETY_KEY, JSON.stringify(societies[0]));
      }
    };

    initializeSociety();
  }, [societies, activeUser?.role, activeUser?.societies, setActiveSociety]);
  
  const handleMarkAsRead = (notificationId) => {
    dispatch(markAsRead(notificationId));
  };

  const handleDeleteNotification = (notificationId) => {
    dispatch(deleteNotificationThunk(notificationId));
  };

  const notificationsToDisplay = notifications || [];

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target) &&
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target) &&
      societyDropdownRef.current &&
      !societyDropdownRef.current.contains(event.target)
    ) {
      setIsNotificationOpen(false);
      setIsProfileOpen(false);
      setIsSocietyDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  if (!isAuthenticated || !activeUser) {
    return null;
  }

  const getGradientStyle = () => {
    const backgroundClass = gradient || "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800";
    return {
      background: `var(--tw-gradient-${backgroundClass})`,
      className: backgroundClass
    };
  };

  if (!isAuthenticated || !activeUser) {
    return null;
  }

  const gradientStyle = getGradientStyle();

  return (
   

<nav className={`sticky top-0 z-50 w-full shadow-lg backdrop-blur-sm ${gradientStyle.className}`}>
<div className="mx-auto px-4 sm:px-6 lg:px-8">
  <div className="flex h-16 items-center justify-between">
    <div className="flex items-center space-x-4">
      <button
        className={`p-2 rounded-lg hover:bg-slate-700/50 transition-colors duration-200 xl:hidden lg:block`}
        onClick={() => broken && toggleSidebar()}
      >
        <Menu className={`h-6 w-6 ${theme.tailwind.text}`} />
      </button>

      <div className="society-dropdown relative" ref={societyDropdownRef}>
        <button
          onClick={() => setIsSocietyDropdownOpen(!isSocietyDropdownOpen)}
          className={`flex items-center space-x-2 bg-slate-700/50 ${theme.tailwind.text} ${theme.tailwind.hover} px-4 py-2 rounded-lg hover:bg-slate-700/70 transition-all duration-200`}
        >
          <Building2 className="h-5 w-5" />
          <span className="hidden sm:block">{selectedSociety}</span>
          <ChevronDown
            className={`h-4 w-4 transition-transform duration-200 ${
              isSocietyDropdownOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        {isSocietyDropdownOpen && (
          // <div className="absolute top-full left-0 mt-2 w-64 rounded-lg bg-slate-800 shadow-lg border border-slate-700 py-2 animate-fadeIn">
          <div className={`absolute top-full left-0 mt-2 w-64 rounded-lg bg-slate-800 shadow-lg border border-slate-700 py-2 animate-fadeIn ${gradientStyle.className}`}>
             {(activeUser?.role === "Admin" ? societies : activeUser?.societies)?.map((society) => (
      <button
        key={society._id}
        onClick={() => handleSocietySelection(society)}
        className={`w-full px-4 py-2 text-left hover:bg-slate-700/50 transition-colors duration-200 flex items-center space-x-2 ${
          selectedSociety === society.name
            ? `${theme.tailwind.text} ${gradientStyle.className} ${theme.tailwind.active}`
            : `${theme.tailwind.text} opacity-80`
        }`}
      >
        <Building2 className="h-4 w-4" />
        <span>{society.name}</span>
      </button>
    ))}
          </div>
        )}
      </div>
    </div>

    <div className="flex items-center space-x-4">
      {/* Notification Bell */}
      <div className="relative" ref={notificationRef}>
        <button
          className={`p-2.5 rounded-xl hover:bg-slate-700/50 transition-all duration-300 group relative`}
          onClick={() => setIsNotificationOpen(!isNotificationOpen)}
        >
          <Bell className={`h-6 w-6 ${theme.tailwind.text} group-hover:scale-110 transition-transform`} />
          {notificationsToDisplay.filter((n) => !n.isRead).length > 0 && (
            <span className={`absolute -top-1.5 -right-1.5 h-5 w-5 text-xs flex items-center justify-center bg-red-500 text-white rounded-full border ${theme.tailwind.border}`}>
              {notificationsToDisplay.filter((n) => !n.isRead).length}
            </span>
          )}
        </button>

        {/* Notification Dropdown */}
        {isNotificationOpen && (
          // <div className="absolute right-0 top-full mt-2 xs:w-80 sm:w-80 md:w-96 bg-slate-800 shadow-2xl rounded-2xl border border-slate-700/50 overflow-hidden z-50 animate-dropdown">
          <div className={`absolute right-0 top-full mt-2 xs:w-80 sm:w-80 md:w-96 shadow-2xl rounded-2xl border border-slate-700/50 overflow-hidden z-50 animate-dropdown ${gradientStyle.className}`}>
            <div className="px-4 py-3 bg-slate-700/30 flex justify-between items-center">
              <h3 className={`text-sm sm:text-lg font-semibold ${theme.text}`}>
                Notifications
              </h3>
              <button
                className={`text-xs ${theme.tailwind.text} ${theme.tailwind.hover} hover:underline`}
                onClick={() => notificationsToDisplay.forEach((n) => handleMarkAsRead(n._id))}
              >
                Mark All Read
              </button>
            </div>

            {/* Notification List */}
            <ul className="divide-y divide-slate-700/30 max-h-[300px] overflow-y-auto">
              {notificationsToDisplay.map((notification) => (
                <li
                  key={notification._id}
                  className={`px-4 py-3 flex items-start justify-between group ${
                    notification.isRead ? "bg-transparent" : "bg-slate-700/20"
                  } hover:bg-slate-700/30 transition-colors`}
                >
                  <div className="flex-grow mr-3">
                    <p className={`text-xs sm:text-sm ${theme.tailwind.text} line-clamp-2`}>
                      {notification.message}
                    </p>
                    <p className={`text-[10px] sm:text-xs ${theme.tailwind.text} opacity-60 mt-1`}>
                      {new Date(notification.createdAt).toLocaleString()}
                    </p>
                  </div>
                  <div className="flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                    {!notification.isRead && (
                      <button
                        onClick={() => handleMarkAsRead(notification._id)}
                        className={theme.tailwind.text}
                      >
                        <Check className="h-3 w-3 sm:h-4 sm:w-4" />
                      </button>
                    )}
                    <button
                      onClick={() => handleDeleteNotification(notification._id)}
                      className={theme.tailwind.text}
                    >
                      <Trash2 className="h-3 w-3 sm:h-4 sm:w-4" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Profile Dropdown */}
      <div className="profile-dropdown relative" ref={profileDropdownRef}>
        <button
          onClick={() => setIsProfileOpen(!isProfileOpen)}
          className={`flex items-center space-x-3 px-3 py-2 rounded-lg hover:bg-slate-700/50 transition-colors duration-200`}
        >
          <div className="hidden sm:block text-right">
            <p className={`text-sm font-semibold ${theme.tailwind.text}`}>
              {activeUser.username}
            </p>
            <p className={`text-xs ${theme.tailwind.text} opacity-70`}>
              {activeUser.designation || activeUser.role}
            </p>
          </div>
          <div className={`h-10 w-10 rounded-full bg-opacity-20 flex items-center justify-center ${theme.tailwind.border} border-2 hover:border-opacity-100 transition-colors duration-200`}>
            {activeUser.profile_picture ? (
              <img
                src={activeUser.profile_picture}
                alt={`${activeUser.username || "User"}'s profile`}
                className="h-full w-full rounded-full object-cover"
              />
            ) : (
              <span className={`text-lg font-semibold ${theme.tailwind.text}`}>
                {activeUser.username?.charAt(0).toUpperCase() || "U"}
              </span>
            )}
          </div>
          <ChevronDown
            className={`h-4 w-4 ${theme.tailwind.text} transition-transform duration-200 ${
              isProfileOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        {/* Profile Menu */}
        {isProfileOpen && (
          // <div className="absolute top-full right-0 mt-2 w-72 rounded-lg bg-slate-800 shadow-lg border border-slate-700 overflow-hidden animate-fadeIn">
          <div className={`absolute top-full right-0 mt-2 w-72 rounded-lg shadow-lg border border-slate-700 overflow-hidden animate-fadeIn ${gradientStyle.className}`} >
            <div className="px-4 py-3 bg-slate-700/30">
              <p className={`text-sm font-semibold ${theme.tailwind.text}`}>
                {activeUser.username}
              </p>
              <p className={`text-xs ${theme.tailwind.text} opacity-70`}>
                {activeUser.additionalData?.designation}
              </p>
              <div className={`mt-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${theme.tailwind.text} bg-opacity-10 ${theme.tailwind.border}`}>
                {activeUser.role}
              </div>
            </div>
            
            <div className="py-2">
              <Link
                to="/user-creation/user-profile"
                onClick={() => setIsProfileOpen(false)}
                className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-700/50 transition-colors duration-200 ${theme.tailwind.text} ${theme.tailwind.hover}`}
              >
                <User className="h-4 w-4" />
                <span>View Profile</span>
              </Link>
              {activeUser.role === "Admin" && (
                <Link
                  to="/tabs-settings"
                  onClick={() => setIsProfileOpen(false)}
                  className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-700/50 transition-colors duration-200 ${theme.tailwind.text} ${theme.tailwind.hover}`}
                >
                  <Settings className="h-4 w-4" />
                  <span>Settings</span>
                </Link>
              )}
              <div className="my-2 border-t border-slate-700"></div>
              <Logout />
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
</div>
</nav>
   );
};

export default Topbar;
