// import React from "react";
// import ProgressCircle from "./ProgressCircle";

// const StatBox = ({ title, subtitle, icon, progress, increase }) => {
//   return (
//     <>
//       <div className="group relative bg-slate-900 rounded-2xl overflow-hidden transition-all duration-500 hover:transform hover:scale-[1.02]">

//         {/* Content Container */}
//         <div className="relative p-6 z-10">
//           {/* Top Section */}
//           <div className="flex justify-between items-start space-x-4">
//             {/* Left Side */}
//             <div className="space-y-4">
//               {/* Icon */}
//               <div
//                 className="p-3 rounded-xl bg-slate-800/50 backdrop-blur-sm group-hover:bg-teal-500/10
//                           transition-all duration-500 inline-block transform group-hover:-translate-y-1 border border-teal-400"
//               >
//                 {React.cloneElement(icon, {
//                   className:
//                     "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500 ",
//                 })}
//               </div>

//               {/* Title */}
//               <h3
//                 className="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r
//                          from-white to-slate-300 group-hover:from-teal-300 group-hover:to-teal-200
//                          transition-all duration-500"
//               >
//                 {title}
//               </h3>
//             </div>

//             {/* Progress Circle */}
//             <ProgressCircle progress={progress} />
//           </div>

//           {/* Bottom Section */}
//           <div className="mt-6 flex justify-between items-center">
//             {/* Subtitle */}
//             <span
//               className="text-base font-medium text-slate-400 group-hover:text-teal-300
//                          transition-colors duration-500"
//             >
//               {subtitle}
//             </span>

//             {/* Increase Indicator */}
//             <div className="flex items-center space-x-2">

//               <span className="text-sm font-semibold text-teal-400 group-hover:text-teal-300">
//                 {increase}
//               </span>
//             </div>
//           </div>
//         </div>

//         {/* Decorative Elements */}
//         <div
//           className="absolute -bottom-12 -right-12 w-48 h-48 bg-teal-500/5 rounded-full blur-3xl
//                     group-hover:bg-teal-500/10 transition-all duration-700"
//         />
//         <div
//           className="absolute -top-12 -left-12 w-48 h-48 bg-slate-700/20 rounded-full blur-3xl
//                     group-hover:bg-teal-500/5 transition-all duration-700"
//         />
//       </div>
//     </>
//   );
// };

// // Add custom animation
// const customStyles = `
//   @keyframes shimmer {
//     0% { background-position: 200% 0; }
//     100% { background-position: -200% 0; }
//   }

//   @keyframes pulse-slow {
//     0%, 100% { opacity: 0.4; }
//     50% { opacity: 0.8; }
//   }
// `;

// const style = document.createElement("style");
// style.textContent = customStyles;
// document.head.appendChild(style);

// export default StatBox;

// import React from "react";
// import {
//   LineChart,
//   Line,
//   BarChart,
//   Bar,
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   XAxis,
//   CartesianGrid,
//   YAxis,
//   Tooltip,
//   Legend,
// } from "recharts";

// const CustomizedLabel = ({ x, y, stroke, value }) => (
//   <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
//     {value}
//   </text>
// );

// const StatBox = ({ title, subtitle, icon, chartType, chartData }) => {
//   const renderChart = () => {
//     switch (chartType) {
//       case "TinyLineChart":
//         return (
//           <ResponsiveContainer width="100%" height="100%">
//             <LineChart data={chartData}>
//               <XAxis dataKey="name" hide />

//               <Line
//                 type="monotone"
//                 dataKey="value"
//                 stroke="#2DD4BF"
//                 strokeWidth={2}
//                 dot={false}
//               />
//             </LineChart>
//           </ResponsiveContainer>
//         );
//       case "TinyBarChart":
//         return (
//           <ResponsiveContainer width="100%" height="100%">
//             <BarChart data={chartData}>
//               <Bar dataKey="value" fill="#14B8A6" dot={false} />
//             </BarChart>
//           </ResponsiveContainer>
//         );
//       case "TotalUserCustomerLineChart":
//         return (
//           <ResponsiveContainer width="100%" height="100%">
//             <LineChart
//               data={chartData}
//               margin={{
//                 top: 20,
//                 right: 30,
//                 left: 20,
//                 bottom: 10,
//               }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="value" height={60} />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               {/* Line for Total Users */}
//               <Line
//                 type="monotone"
//                 dataKey="totalUsers"
//                 stroke="#2DD4BF"
//                 label={<CustomizedLabel />}
//               />
//               {/* Line for Total Customers */}
//               <Line
//                 type="monotone"
//                 dataKey="totalCustomers"
//                 stroke="#8884d8"
//                 label={<CustomizedLabel />}
//               />
//               {/* Line for Difference (Total Users - Total Customers) */}
//               <Line
//                 type="monotone"
//                 dataKey="difference"
//                 stroke="#FF5733"
//                 label={<CustomizedLabel />}
//               />
//             </LineChart>
//           </ResponsiveContainer>
//         );

//       case "AreaChartFillByValue":
//         return (
//           <ResponsiveContainer width="100%" height="100%">
//             <AreaChart data={chartData}>
//               <Area
//                 type="monotone"
//                 dataKey="value"
//                 stroke="#2DD4BF"
//                 fill="#14B8A6"
//               />
//             </AreaChart>
//           </ResponsiveContainer>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="group relative bg-slate-900 rounded-2xl overflow-hidden transition-all duration-500 hover:transform hover:scale-[1.02]">
//       <div className="relative p-6 z-10">
//         <div className="flex justify-between items-start space-x-4">
//           <div className="space-y-4">
//             <div
//               className="p-3 rounded-xl bg-slate-800/50 backdrop-blur-sm group-hover:bg-teal-500/10
//               transition-all duration-500 inline-block transform group-hover:-translate-y-1 border border-teal-400"
//             >
//               {React.cloneElement(icon, {
//                 className:
//                   "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//               })}
//             </div>
//             <h3
//               className="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r
//               from-white to-slate-300 group-hover:from-teal-300 group-hover:to-teal-200
//               transition-all duration-500"
//             >
//               {title}
//             </h3>
//           </div>
//           <div className="w-20 h-20">{renderChart()}</div>
//         </div>
//         <div className="mt-6 flex justify-between items-center">
//           <span
//             className="text-base font-medium text-slate-400 group-hover:text-teal-300
//             transition-colors duration-500"
//           >
//             {subtitle}
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatBox;

// import React from "react";
// import {
//   LineChart,
//   Line,
//   BarChart,
//   Bar,
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   XAxis,
//   CartesianGrid,
//   YAxis,
//   Tooltip,
//   Legend,
// } from "recharts";

// const CustomizedLabel = ({ x, y, stroke, value }) => (
//   <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
//     {value}
//   </text>
// );

// const StatBox = ({ title, subtitle, icon, chartType, chartData }) => {
//   const renderChart = () => {
//     switch (chartType) {
//       case "TinyLineChart":
//         return (
//           <ResponsiveContainer width="100%" height="100%">
//             <LineChart data={chartData}>
//               <XAxis dataKey="name" hide />
//               <Line
//                 type="monotone"
//                 dataKey="value"
//                 stroke="#2DD4BF"
//                 strokeWidth={2}
//                 dot={false}
//               />
//             </LineChart>
//           </ResponsiveContainer>
//         );
//       case "TinyBarChart":
//         return (
//           <ResponsiveContainer width="100%" height="100%">
//             <BarChart data={chartData}>
//               <Bar dataKey="value" fill="#14B8A6" dot={false} />
//             </BarChart>
//           </ResponsiveContainer>
//         );
//       case "TotalUserCustomerLineChart":
//         return (
//           <ResponsiveContainer width="100%" height="100%">
//             <LineChart
//               data={chartData}
//               margin={{
//                 top: 20,
//                 right: 30,
//                 left: 20,
//                 bottom: 10,
//               }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="name" height={60} />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               {/* Line for Total Users */}
//               <Line
//                 type="monotone"
//                 dataKey="totalUsers"
//                 stroke="#2DD4BF"
//                 label={<CustomizedLabel />}
//               />
//               {/* Line for Total Customers */}
//               <Line
//                 type="monotone"
//                 dataKey="totalCustomers"
//                 stroke="#8884d8"
//                 label={<CustomizedLabel />}
//               />
//               {/* Line for Difference (Total Users - Total Customers) */}
//               <Line
//                 type="monotone"
//                 dataKey="difference"
//                 stroke="#FF5733"
//                 label={<CustomizedLabel />}
//               />
//             </LineChart>
//           </ResponsiveContainer>
//         );

//       case "AreaChartFillByValue":
//         return (
//           <ResponsiveContainer width="100%" height="100%">
//             <AreaChart data={chartData}>
//               <Area
//                 type="monotone"
//                 dataKey="value"
//                 stroke="#2DD4BF"
//                 fill="#14B8A6"
//               />
//             </AreaChart>
//           </ResponsiveContainer>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     // <div className="group relative bg-slate-900 rounded-2xl overflow-hidden transition-all duration-500 hover:transform hover:scale-[1.02]">
//     //   <div className="relative p-6 z-10 flex flex-col h-full">
//     //     {/* Title and Subtitle */}
//     //     <div className="space-y-4">
//     //       <div
//     //         className="p-3 rounded-xl bg-slate-800/50 backdrop-blur-sm group-hover:bg-teal-500/10
//     //         transition-all duration-500 inline-block transform group-hover:-translate-y-1 border border-teal-400"
//     //       >
//     //         {React.cloneElement(icon, {
//     //           className:
//     //             "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//     //         })}
//     //       </div>
//     //       <h3
//     //         className="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r
//     //         from-white to-slate-300 group-hover:from-teal-300 group-hover:to-teal-200
//     //         transition-all duration-500"
//     //       >
//     //         {title}
//     //       </h3>
//     //     </div>

//     //     <div className="mt-2">
//     //       <span
//     //         className="text-base font-medium text-slate-400 group-hover:text-teal-300
//     //         transition-colors duration-500"
//     //       >
//     //         {subtitle}
//     //       </span>
//     //     </div>

//     //     {/* Chart */}
//     //     <div className="flex-grow mt-4">{renderChart()}</div>
//     //   </div>
//     // </div>
//     <div className="group relative bg-slate-900 rounded-2xl overflow-hidden transition-all duration-500 hover:transform hover:scale-[1.02]">
//       <div className="relative p-6 z-10 flex flex-col h-[200px] ">
//         {/* Flex container for icon, title, and subtitle */}
//         <div className="flex items-start space-x-4">
//           {/* Icon on the left */}
//           <div
//             className="p-3 rounded-xl bg-slate-800/50 backdrop-blur-sm group-hover:bg-teal-500/10
//             transition-all duration-500 inline-block transform group-hover:-translate-y-1 border border-teal-400"
//           >
//             {React.cloneElement(icon, {
//               className:
//                 "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//             })}
//           </div>

//           {/* Title and Subtitle on the right */}
//           <div className="flex flex-col justify-end">
//             <h3
//               className="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r
//               from-white to-slate-300 group-hover:from-teal-300 group-hover:to-teal-200
//               transition-all duration-500"
//             >
//               {title}
//             </h3>
//             <span
//               className="text-base font-medium text-slate-400 group-hover:text-teal-300
//               transition-colors duration-500"
//             >
//               {subtitle}
//             </span>
//           </div>
//         </div>

//         {/* Chart at the bottom */}
//         <div className="flex-grow mt-4">{renderChart()}</div>
//       </div>
//     </div>
//   );
// };

// export default StatBox;

// import React, { useState } from "react";
// import {
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   BarChart,
//   XAxis,
//   YAxis,
//   Bar,
//   Tooltip,
// } from "recharts";
// import { MenuItem, Select } from "@mui/material";

// const StatBox = ({ title, subtitle, icon, chartData, chartType }) => {
//   const [timePeriod, setTimePeriod] = useState("Today");

//   // Mock function to simulate data update based on time period
//   const getUpdatedChartData = (period) => {
//     // Replace this logic with actual data fetching or calculation logic
//     switch (period) {
//       case "lastMonth":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.8 }));

//       case "lastWeek":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.5 }));
//       case "Today":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.9 }));
//       case "lastYear":
//         return chartData.map((d) => ({ ...d, value: d.value * 1.5 }));
//       default:
//         return chartData;
//     }
//   };

//   const handleTimePeriodChange = (event) => {
//     setTimePeriod(event.target.value);
//   };

//   const formattedChartData =
//     getUpdatedChartData(timePeriod).length > 0
//       ? getUpdatedChartData(timePeriod)
//       : [{ name: "Data", value: 0 }];

//   return (
//     <div className="group relative bg-slate-900 rounded-2xl overflow-hidden transition-all duration-500 hover:transform hover:scale-[1.02]">
//       {/* Dropdown for Time Period Selection */}
//       <div className="absolute top-4 right-4 z-20">
//         <Select
//           value={timePeriod}
//           onChange={handleTimePeriodChange}
//           variant="outlined"
//           size="small"
//           className="text-sm bg-slate-700 text-white"
//         >
//           <MenuItem value="lastMonth">Last Month</MenuItem>
//           <MenuItem value="lastWeek">Last Week</MenuItem>
//           <MenuItem value="lastYear">Last Year</MenuItem>
//           <MenuItem value="Today">Today</MenuItem>
//         </Select>
//       </div>

//       <div className="relative flex flex-col h-[200px]">
//         {/* Flex container for icon, title, and subtitle */}
//         <div className="flex items-start space-x-4 p-6">
//           {/* Icon on the left */}
//           <div
//             className="p-3 rounded-xl bg-slate-800/50 backdrop-blur-sm group-hover:bg-teal-500/10
//             transition-all duration-500 inline-block transform group-hover:-translate-y-1 border border-teal-400"
//           >
//             {React.cloneElement(icon, {
//               className:
//                 "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//             })}
//           </div>

//           {/* Title and Subtitle on the right */}
//           <div className="flex flex-col justify-center">
//             <h3
//               className="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r
//               from-white to-slate-300 group-hover:from-teal-300 group-hover:to-teal-200
//               transition-all duration-500"
//             >
//               {title}
//             </h3>
//             <span
//               className="text-base font-medium text-slate-400 group-hover:text-teal-300
//               transition-colors duration-500"
//             >
//               {subtitle}
//             </span>
//           </div>
//         </div>

//         {/* Conditional Chart Rendering */}
//         <div className="flex-grow -mb-28">
//           <ResponsiveContainer width="100%" height="50%">
//             {chartType === "area" ? (
//               <AreaChart data={formattedChartData}>
//                 <Area
//                   type="monotone"
//                   dataKey="value"
//                   stroke="#2DD4BF"
//                   fill="#14B8A6"
//                 />
//               </AreaChart>
//             ) : (
//               <BarChart data={formattedChartData}>
//                 <XAxis dataKey="name" hide />
//                 <YAxis hide />
//                 <Tooltip cursor={{ fill: "rgba(20, 184, 166, 0.1)" }} />
//                 <Bar dataKey="value" fill="#14B8A6" radius={[10, 10, 0, 0]} />
//               </BarChart>
//             )}
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatBox;

// import React, { useState } from "react";
// import {
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   BarChart,
//   XAxis,
//   YAxis,
//   Bar,
//   Tooltip,
// } from "recharts";

// const StatBox = ({ title, subtitle, icon, chartData, chartType }) => {
//   const [timePeriod, setTimePeriod] = useState("Today");

//   const getUpdatedChartData = (period) => {
//     switch (period) {
//       case "lastMonth":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.8 }));
//       case "lastWeek":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.5 }));
//       case "Today":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.9 }));
//       case "lastYear":
//         return chartData.map((d) => ({ ...d, value: d.value * 1.5 }));
//       default:
//         return chartData;
//     }
//   };

//   const formattedChartData =
//     getUpdatedChartData(timePeriod).length > 0
//       ? getUpdatedChartData(timePeriod)
//       : [{ name: "Data", value: 0 }];

//   return (
//     <div className="group relative bg-slate-900/90 backdrop-blur-xl rounded-3xl overflow-hidden transition-all duration-500 hover:scale-[1.02] border border-slate-800/50 hover:border-teal-500/30 shadow-lg hover:shadow-teal-500/10">
//       {/* Dropdown */}
//       <div className="absolute top-4 right-4 z-20">
//         <select
//           value={timePeriod}
//           onChange={(e) => setTimePeriod(e.target.value)}
//           className="bg-slate-800 text-slate-200 text-sm rounded-lg px-3 py-1.5 border border-slate-700 hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20 transition-all duration-300 appearance-none cursor-pointer backdrop-blur-xl"
//         >
//           <option value="lastMonth">Last Month</option>
//           <option value="lastWeek">Last Week</option>
//           <option value="lastYear">Last Year</option>
//           <option value="Today">Today</option>
//         </select>
//       </div>

//       <div className="relative flex flex-col h-[200px]">
//         {/* Header Section */}
//         <div className="flex items-start space-x-4 p-6">
//           {/* Icon Container */}
//           <div className="p-3 rounded-2xl bg-gradient-to-br from-slate-800/90 to-slate-900/90 backdrop-blur-xl group-hover:from-teal-500/10 group-hover:to-teal-500/5 transition-all duration-500 border border-slate-700/50 group-hover:border-teal-400/30 transform group-hover:-translate-y-1 shadow-lg group-hover:shadow-teal-500/20">
//             {React.cloneElement(icon, {
//               className:
//                 "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//             })}
//           </div>

//           {/* Title and Subtitle */}
//           <div className="flex flex-col justify-center">
//             <h3 className="text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-slate-300 group-hover:from-teal-300 group-hover:to-teal-200 transition-all duration-500">
//               {title}
//             </h3>
//             <span className="text-base font-medium text-slate-400 group-hover:text-teal-300/80 transition-colors duration-500">
//               {subtitle}
//             </span>
//           </div>
//         </div>

//         {/* Chart Section */}
//         <div className="flex-grow -mb-28">
//           <ResponsiveContainer width="100%" height="50%">
//             {chartType === "area" ? (
//               <AreaChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient
//                     id="colorGradient"
//                     x1="0"
//                     y1="0"
//                     x2="0"
//                     y2="1"
//                   >
//                     <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.3} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
//                   </linearGradient>
//                 </defs>
//                 <Area
//                   type="monotone"
//                   dataKey="value"
//                   stroke="#2DD4BF"
//                   fill="url(#colorGradient)"
//                   strokeWidth={2}
//                 />
//               </AreaChart>
//             ) : (
//               <BarChart data={formattedChartData}>
//                 <XAxis dataKey="name" hide />
//                 <YAxis hide />
//                 <Tooltip
//                   contentStyle={{
//                     backgroundColor: "rgba(15, 23, 42, 0.9)",
//                     border: "none",
//                     borderRadius: "8px",
//                     padding: "8px",
//                   }}
//                   cursor={{ fill: "rgba(20, 184, 166, 0.1)" }}
//                 />
//                 <Bar dataKey="value" fill="#14B8A6" radius={[10, 10, 0, 0]} />
//               </BarChart>
//             )}
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatBox;

// import React, { useState } from "react";
// import {
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   BarChart,
//   XAxis,
//   YAxis,
//   Bar,
//   Tooltip,
// } from "recharts";

// const StatBox = ({ title, subtitle, icon, chartData, chartType }) => {
//   const [timePeriod, setTimePeriod] = useState("Today");
//   const [isHovered, setIsHovered] = useState(false);

//   const getUpdatedChartData = (period) => {
//     switch (period) {
//       case "lastMonth":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.8 }));
//       case "lastWeek":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.5 }));
//       case "Today":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.9 }));
//       case "lastYear":
//         return chartData.map((d) => ({ ...d, value: d.value * 1.5 }));
//       default:
//         return chartData;
//     }
//   };

//   const formattedChartData =
//     getUpdatedChartData(timePeriod).length > 0
//       ? getUpdatedChartData(timePeriod)
//       : [{ name: "Data", value: 0 }];

//   return (
//     <div
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       className="group relative h-[280px] w-full max-w-6xl bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-3xl overflow-hidden transition-all duration-700 hover:scale-[1.02] border border-slate-700/50 hover:border-teal-500/30"
//     >
//       {/* Animated Background Effect */}
//       {/* <div className="absolute inset-0 bg-gradient-to-br from-teal-500/10 via-emerald-500/10 to-cyan-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-700" />
//       <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(45,212,191,0.1),transparent)]" /> */}

//       {/* Glowing Orbs */}
//       {/* <div className="absolute top-0 left-0 w-32 h-32 bg-teal-500/20 rounded-full filter blur-3xl group-hover:bg-teal-400/30 transition-all duration-700 -translate-x-16 -translate-y-16" />
//       <div className="absolute bottom-0 right-0 w-32 h-32 bg-cyan-500/20 rounded-full filter blur-3xl group-hover:bg-cyan-400/30 transition-all duration-700 translate-x-16 translate-y-16" /> */}

//       {/* Content Container */}
//       <div className="relative h-full backdrop-blur-sm  z-10">
//         {/* Header Section */}
//         <div className="flex justify-between items-start p-6 mb-2">
//           {/* Icon and Text Container */}
//           <div className="flex flex-col items-center text-left space-y-4">
//             {/* Animated Icon Container */}
//             <div className="relative">
//               <div className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-500 rounded-2xl opacity-20 group-hover:opacity-30 blur" />
//               <div className="relative p-4 bg-gradient-to-br from-slate-800/90 to-slate-900/90 rounded-2xl border border-slate-700/50 group-hover:border-teal-500/30 transform group-hover:-translate-y-1 transition-all duration-500">
//                 {React.cloneElement(icon, {
//                   className:
//                     "w-10 h-10 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//                 })}
//               </div>
//             </div>

//             {/* Title and Subtitle with enhanced typography */}
//             <div className="space-y-1">
//               <h3 className="text-xl font-bold">
//                 <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-300 via-teal-200 to-cyan-200 group-hover:from-teal-200 group-hover:via-cyan-200 group-hover:to-teal-200">
//                   {title}
//                 </span>
//               </h3>
//               <p className="text-sm text-teal-300/80 group-hover:text-teal-200/80">
//                 {subtitle}
//               </p>
//             </div>
//           </div>

//           {/* Custom Styled Dropdown */}
//           <select
//             value={timePeriod}
//             onChange={(e) => setTimePeriod(e.target.value)}
//             className="bg-slate-800/90 text-teal-300 text-sm rounded-xl px-4 py-2 border border-slate-700 hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20 transition-all duration-300 appearance-none cursor-pointer backdrop-blur-xl hover:bg-slate-700/90"
//           >
//             <option value="Today">Today</option>
//             <option value="lastWeek">Last Week</option>
//             <option value="lastMonth">Last Month</option>
//             <option value="lastYear">Last Year</option>
//           </select>
//         </div>

//         {/* Enhanced Chart Section */}
//         <div className="h-24">
//           <ResponsiveContainer width="100%" height="100%">
//             {chartType === "area" ? (
//               <AreaChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient
//                     id="colorGradient"
//                     x1="0"
//                     y1="0"
//                     x2="0"
//                     y2="1"
//                   >
//                     <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.4} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
//                   </linearGradient>
//                 </defs>
//                 <Area
//                   type="monotone"
//                   dataKey="value"
//                   stroke="#2DD4BF"
//                   strokeWidth={2}
//                   fill="url(#colorGradient)"
//                 />
//               </AreaChart>
//             ) : (
//               <BarChart data={formattedChartData}>
//                 <XAxis dataKey="name" hide />
//                 <YAxis hide />
//                 <Tooltip
//                   contentStyle={{
//                     backgroundColor: "rgba(15, 23, 42, 0.95)",
//                     border: "1px solid rgba(45, 212, 191, 0.2)",
//                     borderRadius: "12px",
//                     padding: "8px",
//                     boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
//                   }}
//                   cursor={{ fill: "rgba(45, 212, 191, 0.1)" }}
//                 />
//                 <Bar dataKey="value" fill="#2DD4BF" radius={[8, 8, 0, 0]} />
//               </BarChart>
//             )}
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatBox;

// import React, { useState } from "react";
// import {
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   BarChart,
//   XAxis,
//   YAxis,
//   Bar,
//   Tooltip,
// } from "recharts";

// const StatBox = ({
//   title,
//   subtitle,
//   icon,
//   chartData,
//   chartType,
//   className = "",
// }) => {
//   const [timePeriod, setTimePeriod] = useState("Today");
//   const [isHovered, setIsHovered] = useState(false);

//   const getUpdatedChartData = (period) => {
//     switch (period) {
//       case "lastMonth":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.8 }));
//       case "lastWeek":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.5 }));
//       case "Today":
//         return chartData.map((d) => ({ ...d, value: d.value * 0.9 }));
//       case "lastYear":
//         return chartData.map((d) => ({ ...d, value: d.value * 1.5 }));
//       default:
//         return chartData;
//     }
//   };

//   const formattedChartData =
//     getUpdatedChartData(timePeriod).length > 0
//       ? getUpdatedChartData(timePeriod)
//       : [{ name: "Data", value: 0 }];

//   return (
//     <div
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       className={`group relative h-[180px] bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl overflow-hidden transition-all duration-700 hover:scale-[1.02] border border-slate-700/50 hover:border-teal-500/30 ${className}`}
//     >
//       <div className="relative h-full backdrop-blur-sm z-10">
//         <div className="flex items-center justify-between p-4">
//           <div className="flex items-center gap-3">
//             <div className="relative">
//               <div className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-500 rounded-xl opacity-20 group-hover:opacity-30 blur" />
//               <div className="relative p-2 bg-gradient-to-br from-slate-800/90 to-slate-900/90 rounded-xl border border-slate-700/50 group-hover:border-teal-500/30 transform group-hover:-translate-y-1 transition-all duration-500">
//                 {React.cloneElement(icon, {
//                   className:
//                     "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//                 })}
//               </div>
//             </div>

//             <div className="space-y-0.5">
//               <h3 className="text-lg font-bold">
//                 <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-300 via-teal-200 to-cyan-200 group-hover:from-teal-200 group-hover:via-cyan-200 group-hover:to-teal-200">
//                   {title}
//                 </span>
//               </h3>
//               <p className="text-xs text-teal-300/80 group-hover:text-teal-200/80">
//                 {subtitle}
//               </p>
//             </div>
//           </div>

//           <select
//             value={timePeriod}
//             onChange={(e) => setTimePeriod(e.target.value)}
//             className="bg-slate-800/90 text-teal-300 text-xs rounded-lg px-1 py-1 border border-slate-700 hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20 transition-all duration-300 appearance-none cursor-pointer backdrop-blur-xl hover:bg-slate-700/90"
//           >
//             <option value="Today">Today</option>
//             <option value="lastWeek">Last Week</option>
//             <option value="lastMonth">Last Month</option>
//             <option value="lastYear">Last Year</option>
//           </select>
//         </div>

//         <div className="h-28">
//           <ResponsiveContainer width="100%" height="100%">
//             {chartType === "area" ? (
//               <AreaChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.4} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
//                   </linearGradient>
//                 </defs>
//                 <Area
//                   type="monotone"
//                   dataKey="value"
//                   stroke="#2DD4BF"
//                   strokeWidth={2}
//                   fill="url(#areaGradient)"
//                 />
//               </AreaChart>
//             ) : (
//               <BarChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="0%" stopColor="#2DD4BF" stopOpacity={1} />
//                     <stop offset="100%" stopColor="#14B8A6" stopOpacity={1} />
//                   </linearGradient>
//                 </defs>
//                 <XAxis dataKey="name" hide />
//                 <YAxis hide />
//                 <Tooltip
//                   contentStyle={{
//                     backgroundColor: "rgba(15, 23, 42, 0.95)",
//                     border: "1px solid rgba(45, 212, 191, 0.2)",
//                     borderRadius: "12px",
//                     padding: "8px",
//                     boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
//                   }}
//                   cursor={{ fill: "rgba(45, 212, 191, 0.1)" }}
//                 />
//                 <Bar
//                   dataKey="value"
//                   fill="url(#barGradient)"
//                   radius={[8, 8, 0, 0]}
//                 />
//               </BarChart>
//             )}
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatBox;
// import React, { useState } from "react";
// import {
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   BarChart,
//   XAxis,
//   YAxis,
//   Bar,
//   Tooltip,
//   Line,
//   LineChart,
// } from "recharts";

// const StatBox = ({
//   title,
//   subtitle,
//   icon,
//   chartData = [],
//   chartType,
//   className = "",
//   onClick,
// }) => {
//   const [timePeriod, setTimePeriod] = useState("Today");
//   const [isHovered, setIsHovered] = useState(false);

//   const handleClick = () => {
//     if (onClick) { onClick(); }
//   }
//   const getUpdatedChartData = (period) => {
//     if (!Array.isArray(chartData)) {
//       console.error("chartData is not an array:", chartData);
//       return [];
//     }

//     switch (period) {
//       case "lastMonth":
//         return chartData.map((d) => ({
//           ...d,
//           totalPlots: d.totalPlots * 0.8,
//           totalBookedPlots: d.totalBookedPlots * 0.8,
//         }));
//       case "lastWeek":
//         return chartData.map((d) => ({
//           ...d,
//           totalPlots: d.totalPlots * 0.5,
//           totalBookedPlots: d.totalBookedPlots * 0.5,
//         }));
//       case "Today":
//         return chartData.map((d) => ({
//           ...d,
//           totalPlots: d.totalPlots * 0.9,
//           totalBookedPlots: d.totalBookedPlots * 0.9,
//         }));
//       case "lastYear":
//         return chartData.map((d) => ({
//           ...d,
//           totalPlots: d.totalPlots * 1.5,
//           totalBookedPlots: d.totalBookedPlots * 1.5,
//         }));
//       default:
//         return chartData;
//     }
//   };

//   const formattedChartData =
//     getUpdatedChartData(timePeriod).length > 0
//       ? getUpdatedChartData(timePeriod)
//       : [{ name: "Data", totalPlots: 0, totalBookedPlots: 0 }];

//   // Generate unique gradient IDs using subtitle instead of title
//   const gradientId = `colorGradient-${subtitle.replace(
//     /\s+/g,
//     ""
//   )}-${Math.random().toString(36).substr(2, 9)}`;

//   return (
//     <div
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleClick}
//       style={{ cursor: onClick ? "pointer" : "default" }}
//       className={`group relative h-[180px] bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl overflow-hidden transition-all duration-700 hover:scale-[1.02] border border-slate-700/50 hover:border-teal-500/30 ${className}`}
//     >
//       <div className="relative h-full backdrop-blur-sm z-10">
//         <div className="flex items-center justify-between p-4">
//           <div className="flex items-center gap-3">
//             <div className="relative">
//               <div className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-500 rounded-xl opacity-20 group-hover:opacity-30 blur" />
//               <div className="relative p-2 bg-gradient-to-br from-slate-800/90 to-slate-900/90 rounded-xl border border-slate-700/50 group-hover:border-teal-500/30 transform group-hover:-translate-y-1 transition-all duration-500">
//                 {React.cloneElement(icon, {
//                   className:
//                     "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//                 })}
//               </div>
//             </div>

//             <div className="space-y-0.5">
//               <h3 className="text-lg font-bold">
//                 <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-300 via-teal-200 to-cyan-200 group-hover:from-teal-200 group-hover:via-cyan-200 group-hover:to-teal-200">
//                   {title}
//                 </span>
//               </h3>
//               <p className="text-xs text-teal-300/80 group-hover:text-teal-200/80">
//                 {subtitle}
//               </p>
//             </div>
//           </div>

//           <select
//             value={timePeriod}
//             onChange={(e) => setTimePeriod(e.target.value)}
//             className="bg-slate-800/90 text-teal-300 text-xs rounded-lg px-2 py-1 border border-slate-700 hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20 transition-all duration-300 appearance-none cursor-pointer backdrop-blur-xl hover:bg-slate-700/90"
//           >
//             <option value="Today">Today</option>
//             <option value="lastWeek">Last Week</option>
//             <option value="lastMonth">Last Month</option>
//             <option value="lastYear">Last Year</option>
//           </select>
//         </div>

//         <div className="h-24 mt-2">
//           <ResponsiveContainer width="100%" height="100%">
//             {chartType === "line" ? (
//               <LineChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.4} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
//                   </linearGradient>
//                 </defs>
//                 <Line
//                   type="monotone"
//                   dataKey="totalPlots"
//                   stroke="#2DD4BF"
//                   dot={false}
//                   strokeWidth={1}
//                   fill={`url(#${gradientId})`}
//                 />
//                 <Line
//                   type="monotone"
//                   dataKey="totalBookedPlots"
//                   stroke="#14B8A6"
//                   dot={false}
//                   strokeWidth={4}
//                   fill={`url(#${gradientId})`}
//                 />
//               </LineChart>
//             ) : 
//             chartType === "area" ? (
//               <AreaChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.4} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
//                   </linearGradient>
//                 </defs>
//                 <Area
//                   type="monotone"
//                   dataKey="value"
//                   stroke="#2DD4BF"
//                   strokeWidth={2}
//                   fill={`url(#${gradientId})`}
//                 />
//               </AreaChart>
//             ) : (
//               <BarChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="0%" stopColor="#2DD4BF" stopOpacity={0.8} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.3} />
//                   </linearGradient>
//                 </defs>
//                 <XAxis dataKey="name" hide />
//                 <YAxis hide />
//                 <Tooltip
//                   contentStyle={{
//                     backgroundColor: "rgba(15, 23, 42, 0.95)",
//                     border: "1px solid rgba(45, 212, 191, 0.2)",
//                     borderRadius: "12px",
//                     padding: "8px",
//                     boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
//                   }}
//                   cursor={{ fill: "rgba(45, 212, 191, 0.1)" }}
//                 />
//                 <Bar
//                   dataKey="value"
//                   fill={`url(#${gradientId})`}
//                   radius={[8, 8, 0, 0]}
//                 />
//               </BarChart>
//             )}
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatBox;



import React, { useState } from "react";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Line,
  LineChart,
} from "recharts";

const StatBox = ({
  title,
  subtitle,
  icon,
  chartData = [],
  chartType,
  className = "",
  onClick,
}) => {
  const [timePeriod, setTimePeriod] = useState("Today");
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (onClick) { onClick(); }
  }
  const getUpdatedChartData = (period) => {
    if (!Array.isArray(chartData)) {
      console.error("chartData is not an array:", chartData);
      return [];
    }

    switch (period) {
      case "lastMonth":
        return chartData.map((d) => ({
          ...d,
          totalPlots: d.totalPlots * 0.8,
          totalBookedPlots: d.totalBookedPlots * 0.8,
        }));
      case "lastWeek":
        return chartData.map((d) => ({
          ...d,
          totalPlots: d.totalPlots * 0.5,
          totalBookedPlots: d.totalBookedPlots * 0.5,
        }));
      case "Today":
        return chartData.map((d) => ({
          ...d,
          totalPlots: d.totalPlots * 0.9,
          totalBookedPlots: d.totalBookedPlots * 0.9,
        }));
      case "lastYear":
        return chartData.map((d) => ({
          ...d,
          totalPlots: d.totalPlots * 1.5,
          totalBookedPlots: d.totalBookedPlots * 1.5,
        }));
      default:
        return chartData;
    }
  };

  const formattedChartData =
    getUpdatedChartData(timePeriod).length > 0
      ? getUpdatedChartData(timePeriod)
      : [{ name: "Data", totalPlots: 0, totalBookedPlots: 0 }];

  // Generate unique gradient IDs using subtitle instead of title
  const gradientId = `colorGradient-${subtitle.replace(
    /\s+/g,
    ""
  )}-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
      style={{ cursor: onClick ? "pointer" : "default" }}
      className={`group relative h-[180px] bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl overflow-hidden transition-all duration-700 hover:scale-[1.02] border border-slate-700/50 hover:border-teal-500/30 ${className}`}
    >
      <div className="relative h-full backdrop-blur-sm z-10">
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center gap-3">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-500 rounded-xl opacity-20 group-hover:opacity-30 blur" />
              <div className="relative p-2 bg-gradient-to-br from-slate-800/90 to-slate-900/90 rounded-xl border border-slate-700/50 group-hover:border-teal-500/30 transform group-hover:-translate-y-1 transition-all duration-500">
                {React.cloneElement(icon, {
                  className:
                    "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
                })}
              </div>
            </div>

            <div className="space-y-0.5">
              <h3 className="text-lg font-bold">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-300 via-teal-200 to-cyan-200 group-hover:from-teal-200 group-hover:via-cyan-200 group-hover:to-teal-200">
                  {title}
                </span>
              </h3>
              <p className="text-xs text-teal-300/80 group-hover:text-teal-200/80">
                {subtitle}
              </p>
            </div>
          </div>

          <select
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            className="bg-slate-800/90 text-teal-300 text-xs rounded-lg px-2 py-1 border border-slate-700 hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20 transition-all duration-300 appearance-none cursor-pointer backdrop-blur-xl hover:bg-slate-700/90"
          >
            <option value="Today">Today</option>
            <option value="lastWeek">Last Week</option>
            <option value="lastMonth">Last Month</option>
            <option value="lastYear">Last Year</option>
          </select>
        </div>

        <div className="h-24 mt-2">
          <ResponsiveContainer width="100%" height="100%">
            {chartType === "line" ? (
              <LineChart data={formattedChartData}>
                <defs>
                  <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.4} />
                    <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <Line
                  type="monotone"
                  dataKey="totalPlots"
                  stroke="#2DD4BF"
                  dot={false}
                  strokeWidth={1}
                  fill={`url(#${gradientId})`}
                />
                <Line
                  type="monotone"
                  dataKey="totalBookedPlots"
                  stroke="#14B8A6"
                  dot={false}
                  strokeWidth={4}
                  fill={`url(#${gradientId})`}
                />
              </LineChart>
            ) : 
            chartType === "area" ? (
              <AreaChart data={formattedChartData}>
                <defs>
                  <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.4} />
                    <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#2DD4BF"
                  strokeWidth={2}
                  fill={`url(#${gradientId})`}
                />
              </AreaChart>
            ) : (
              <BarChart data={formattedChartData}>
                <defs>
                  <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#2DD4BF" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.3} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" hide />
                <YAxis hide />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "rgba(15, 23, 42, 0.95)",
                    border: "1px solid rgba(45, 212, 191, 0.2)",
                    borderRadius: "12px",
                    padding: "8px",
                    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
                  }}
                  cursor={{ fill: "rgba(45, 212, 191, 0.1)" }}
                />
                <Bar
                  dataKey="value"
                  fill={`url(#${gradientId})`}
                  radius={[8, 8, 0, 0]}
                />
              </BarChart>
            )}
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default StatBox;
