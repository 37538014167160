// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import { useNavigate, Navigate } from "react-router-dom";

// const ProtectedRoute = ({ children, isAuthenticated }) => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const isReloading =
//       window.performance &&
//       window.performance.getEntriesByType("navigation").length > 0 &&
//       window.performance.getEntriesByType("navigation")[0].type === 1;

//     const forcedLogout = sessionStorage.getItem("forcedLogout");

//     if (forcedLogout === "true" && !isReloading) {
//       sessionStorage.removeItem("forcedLogout");
//       navigate("/login");
//     }
//   }, [navigate]);

//   return isAuthenticated ? children : <Navigate to="/login" replace />;
// };

// ProtectedRoute.propTypes = {
//   children: PropTypes.node.isRequired,
//   isAuthenticated: PropTypes.bool.isRequired,
// };

// export default ProtectedRoute;
import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, isAuthenticated }) => {
  // Check persisted auth state
  const persistentAuth = JSON.parse(localStorage.getItem("isAuthenticated"));

  if (!isAuthenticated && !persistentAuth) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default ProtectedRoute;
