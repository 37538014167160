import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserNotifications,
  markAsRead,
  deleteNotificationThunk,
} from "../store/slices/notificationSlice";
import { checkActiveUser } from "../store/slices/authSlice";
import {
  Check,
  Trash2,
  RefreshCcw,
  ChevronLeft,
  ChevronRight,
  Bell,
  Filter,
} from "lucide-react";

const Notifications = () => {
  const dispatch = useDispatch();
  const { activeUser } = useSelector((state) => state.auth);
  const { notifications, loading, error } = useSelector(
    (state) => state.notifications
  );

  const [selectedFilter, setSelectedFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const notificationsPerPage = 8;

  useEffect(() => {
    dispatch(checkActiveUser());
  }, [dispatch]);

  useEffect(() => {
    if (activeUser?.id) {
      dispatch(getUserNotifications(activeUser.id));
    }
  }, [dispatch, activeUser]);

  const handleRefreshNotifications = async () => {
    if (activeUser?.id) {
      setIsRefreshing(true);
      try {
        await dispatch(getUserNotifications(activeUser.id)).unwrap();
      } catch (error) {
        console.error("Failed to refresh notifications", error);
      } finally {
        setIsRefreshing(false);
      }
    }
  };

  const handleMarkAsRead = (id) => {
    dispatch(markAsRead(id));
  };

  const handleDelete = (id) => {
    dispatch(deleteNotificationThunk(id));
  };

  const handleMarkAllAsRead = () => {
    notifications.forEach((n) => {
      if (!n.isRead) dispatch(markAsRead(n._id));
    });
  };

  const groupNotificationsByTime = (notifications) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const groupings = {
      all: notifications,
      today: notifications.filter(
        (n) => new Date(n.createdAt).toDateString() === today.toDateString()
      ),
      yesterday: notifications.filter(
        (n) => new Date(n.createdAt).toDateString() === yesterday.toDateString()
      ),
      thisWeek: notifications.filter(
        (n) =>
          new Date(n.createdAt) > new Date(today.setDate(today.getDate() - 7))
      ),
      older: notifications.filter(
        (n) =>
          new Date(n.createdAt) <= new Date(today.setDate(today.getDate() - 7))
      ),
    };

    return groupings;
  };

  const groupedNotifications = groupNotificationsByTime(notifications);
  const filteredNotifications =
    selectedFilter in groupedNotifications
      ? groupedNotifications[selectedFilter]
      : notifications;

  const totalPages = Math.ceil(
    filteredNotifications.length / notificationsPerPage
  );
  const displayedNotifications = filteredNotifications.slice(
    (currentPage - 1) * notificationsPerPage,
    currentPage * notificationsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className="flex items-center justify-center p-4">
      <div className="w-full max-w-6xl bg-slate-800/70 backdrop-blur-xl rounded-3xl shadow-2xl border border-slate-700/50 overflow-hidden">
        {/* Header */}
        <div className="bg-slate-800/80 px-6 py-4 border-b border-slate-700/30 flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Bell className="text-teal-400" size={28} />
            <h2 className="text-2xl font-bold text-teal-300">Notifications</h2>
          </div>

          <div className="flex items-center space-x-3">
            <button
              onClick={handleRefreshNotifications}
              className={`
                text-teal-300 hover:text-teal-200 transition 
                ${isRefreshing ? "animate-spin" : ""}
              `}
            >
              <RefreshCcw size={20} />
            </button>

            <div className="relative">
              <select
                className="appearance-none bg-slate-700/50 text-teal-300 rounded-lg pl-3 pr-8 py-2 focus:ring-2 focus:ring-teal-500 transition"
                value={selectedFilter}
                onChange={(e) => {
                  setSelectedFilter(e.target.value);
                  setCurrentPage(1);
                }}
              >
                {["all", "today", "yesterday", "thisWeek", "older"].map(
                  (filter) => (
                    <option
                      key={filter}
                      value={filter}
                      className="bg-slate-800 text-teal-300"
                    >
                      {filter.charAt(0).toUpperCase() + filter.slice(1)}
                    </option>
                  )
                )}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-teal-300">
                <Filter size={16} />
              </div>
            </div>

            <button
              onClick={handleMarkAllAsRead}
              disabled={!notifications.length}
              className="bg-teal-600 text-slate-900 rounded-lg px-4 py-2 hover:bg-teal-500 transition disabled:opacity-50"
            >
              Mark All Read
            </button>
          </div>
        </div>

        {/* Notifications Content */}
        <div className="p-6">
          {isRefreshing && (
            <div className="text-center text-teal-300 animate-pulse">
              Refreshing notifications...
            </div>
          )}

          {error && (
            <div className="text-red-400 text-center">Error: {error}</div>
          )}

          {!isRefreshing && displayedNotifications.length === 0 && (
            <div className="text-center text-teal-300/70">
              No notifications to show
            </div>
          )}

          <div className="space-y-4">
            {displayedNotifications.map((notification) => (
              <div
                key={notification._id}
                className={`
                  p-4 rounded-xl transition transform hover:scale-[1.02] hover:shadow-lg 
                  ${
                    notification.isRead
                      ? "bg-slate-700/30"
                      : "bg-teal-900/20 border border-teal-500/30 shadow-md"
                  }
                `}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <p className="text-teal-300 font-medium">
                      {notification.message}
                    </p>
                    <p className="text-sm text-teal-300/70">
                      {notification.type} |{" "}
                      {new Date(notification.createdAt).toLocaleString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: true, // Set to true if you prefer a 12-hour format
                        }
                      )}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    {!notification.isRead && (
                      <button
                        onClick={() => handleMarkAsRead(notification._id)}
                        className="text-teal-500 hover:text-teal-300 transition"
                      >
                        <Check size={20} />
                      </button>
                    )}
                    <button
                      onClick={() => handleDelete(notification._id)}
                      className="text-red-500 hover:text-red-300 transition"
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex justify-between items-center mt-6 bg-slate-800/50 rounded-xl p-3">
              <button
                onClick={() => handlePageChange("prev")}
                disabled={currentPage === 1}
                className="text-teal-300 disabled:opacity-50 hover:text-teal-500 transition"
              >
                <ChevronLeft size={24} />
              </button>
              <span className="text-teal-300 font-medium">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange("next")}
                disabled={currentPage === totalPages}
                className="text-teal-300 disabled:opacity-50 hover:text-teal-500 transition"
              >
                <ChevronRight size={24} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
