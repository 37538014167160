import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAllUsers, registerUser } from "../../store/slices/authSlice";
import { fetchSocieties } from "../../store/slices/societySlice";
import { useSidebarContext } from "../../scenes/global/sidebar/SidebarContext";
import {
  User,
  Mail,
  Phone,
  Camera,
  Lock,
  IdCard,
  MapPinHouse,
  BookUser,
  Eye,
  EyeOff,
  Merge,
  Workflow,
  LockKeyhole,
  X,
} from "lucide-react";

const CustomerRegistrationForm = () => {
  const dispatch = useDispatch();
  const { societies } = useSelector((state) => state.societies);
  const { activeSociety } = useSidebarContext();

  const [societiesFetched, setSocietiesFetched] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { users, status } = useSelector((state) => state.auth);
  const [activeSection, setActiveSection] = useState("basic");

  useEffect(() => {
    const initialize = async () => {
      if (!societiesFetched) {
        await dispatch(fetchSocieties());
        setSocietiesFetched(true);
      }

      if (status === "idle" || (status === "failed" && users.length === 0)) {
        dispatch(fetchAllUsers());
      }
    };

    initialize();
  }, [dispatch, societiesFetched, status, users.length]);

  const RegistrationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters")
      .max(20, "Username must be at most 20 characters"),

    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),

    customerData: Yup.object().shape({
      name: Yup.string()
        .required("Full name is required")
        .min(3, "Name must be at least 3 characters")
        .max(50, "Name must be at most 50 characters"),

      contactInfo: Yup.object().shape({
        email: Yup.string()
          .required("Email is required")
          .email("Please enter a valid email address"),

        phone: Yup.string()
          .required("Phone number is required")
          .matches(
            /^\+92\s3[0-9]{2}-[0-9]{7}$/,
            "Phone number must be in the format +92 3XX-XXXXXXX"
          ),

        waris_phone: Yup.string()
          .required("Waris phone number is required")
          .matches(
            /^\+92\s3[0-9]{2}-[0-9]{7}$/,
            "Waris phone number must be in the format +92 3XX-XXXXXXX"
          ),

        cnic: Yup.string()
          .required("CNIC is required")
          .matches(
            /^\d{5}-\d{7}-\d{1}$/,
            "CNIC must be in the format XXXXX-XXXXXXX-X"
          ),

        waris_cnic: Yup.string()
          .required("Waris CNIC is required")
          .matches(
            /^\d{5}-\d{7}-\d{1}$/,
            "Waris CNIC must be in the format XXXXX-XXXXXXX-X"
          ),

        address: Yup.string()
          .required("Address is required")
          .min(10, "Address must be at least 10 characters long"),

        father_name: Yup.string()
          .required("Father's full name is required")
          .min(3, "Father's name must be at least 3 characters")
          .max(50, "Father's name must be at most 50 characters"),

        waris_name: Yup.string()
          .required("Waris full name is required")
          .min(3, "Waris name must be at least 3 characters")
          .max(50, "Waris name must be at most 50 characters"),

        waris_father_name: Yup.string()
          .required("Waris father's full name is required")
          .min(3, "Waris father's name must be at least 3 characters")
          .max(50, "Waris father's name must be at most 50 characters"),

        waris_relation: Yup.string()
          .required("Waris relation is required")
          .min(3, "Waris relation must be at least 3 characters")
          .max(50, "Waris relation must be at most 50 characters"),

        waris_address: Yup.string()
          .required("Waris address is required")
          .min(10, "Waris address must be at least 10 characters long"),
        id_front_img: Yup.string().nullable(),
        id_back_img: Yup.string().nullable(),
        waris_id_front_img: Yup.string().nullable(),
        waris_id_back_img: Yup.string().nullable(),
      }),
    }),
  });

  const handleProfileImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setFieldValue("profile_picture", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatCNIC = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const truncatedValue = cleanedValue.slice(0, 13);
    if (truncatedValue.length <= 5) {
      return truncatedValue;
    } else if (truncatedValue.length <= 12) {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(5)}`;
    } else {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(
        5,
        12
      )}-${truncatedValue.slice(12)}`;
    }
  };

  const getSectionStatus = (values, errors, section) => {
    const hasError = (path) => {
      return (
        path.split(".").reduce((obj, key) => obj && obj[key], errors) !==
        undefined
      );
    };

    const isFieldEmpty = (path) => {
      return !path.split(".").reduce((obj, key) => obj && obj[key], values);
    };

    switch (section) {
      case "basic":
        return {
          complete:
            !isFieldEmpty("username") &&
            !isFieldEmpty("password") &&
            !hasError("username") &&
            !hasError("password"),
          errors: ["username", "password"].filter(
            (field) => hasError(field) || isFieldEmpty(field)
          ),
        };

      case "contact":
        const contactFields = [
          "customerData.name",
          "customerData.contactInfo.father_name",
          "customerData.contactInfo.cnic",
          "customerData.contactInfo.email",
          "customerData.contactInfo.phone",
          "customerData.contactInfo.address",
        ];
        return {
          complete: contactFields.every(
            (field) => !isFieldEmpty(field) && !hasError(field)
          ),
          errors: contactFields.filter(
            (field) => hasError(field) || isFieldEmpty(field)
          ),
        };

      case "waris":
        const warisFields = [
          "customerData.contactInfo.waris_name",
          "customerData.contactInfo.waris_father_name",
          "customerData.contactInfo.waris_relation",
          "customerData.contactInfo.waris_cnic",
          "customerData.contactInfo.waris_phone",
          "customerData.contactInfo.waris_address",
        ];
        return {
          complete: warisFields.every(
            (field) => !isFieldEmpty(field) && !hasError(field)
          ),
          errors: warisFields.filter(
            (field) => hasError(field) || isFieldEmpty(field)
          ),
        };

      default:
        return { complete: true, errors: [] };
    }
  };

  // Function to get human-readable field names
  const getFieldLabel = (fieldPath) => {
    const fieldMap = {
      username: "Username",
      password: "Password",
      "customerData.name": "Full Name",
      "customerData.contactInfo.father_name": "Father/Husband Name",
      "customerData.contactInfo.cnic": "CNIC",
      "customerData.contactInfo.email": "Email",
      "customerData.contactInfo.phone": "Phone Number",
      "customerData.contactInfo.address": "Address",
      "customerData.contactInfo.waris_name": "Waris Name",
      "customerData.contactInfo.waris_father_name": "Waris Father Name",
      "customerData.contactInfo.waris_relation": "Waris Relation",
      "customerData.contactInfo.waris_cnic": "Waris CNIC",
      "customerData.contactInfo.waris_phone": "Waris Phone",
      "customerData.contactInfo.waris_address": "Waris Address",
      "customerData.contactInfo.id_front_img": "Front ID Card Image",
      "customerData.contactInfo.id_back_img": "Back ID Card Image",
      "customerData.contactInfo.waris_id_front_img": "Front ID Card Image",
      "customerData.contactInfo.waris_id_back_img": "Back ID Card Image",
    };
    return fieldMap[fieldPath] || fieldPath;
  };

  const handleSubmit = async (
    values,
    { setSubmitting, setTouched, resetForm }
  ) => {
    // Set all fields as touched to trigger validation
    const touchedFields = {};
    const setNestedTouched = (obj, prefix = "") => {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          setNestedTouched(obj[key], `${prefix}${key}.`);
        } else {
          touchedFields[`${prefix}${key}`] = true;
        }
      });
    };
    setNestedTouched(values);
    setTouched(touchedFields);

    // Validate each section
    const sections = ["basic", "contact", "waris", "Waris Id Card"];
    let firstIncompleteSection = null;
    let missingFields = [];

    for (const section of sections) {
      const status = getSectionStatus(values, {}, section);
      if (!status.complete) {
        firstIncompleteSection = section;
        missingFields = status.errors;
        break;
      }
    }

    if (firstIncompleteSection) {
      setActiveSection(firstIncompleteSection);
      const missingFieldNames = missingFields.map(getFieldLabel).join(", ");

      Swal.fire({
        icon: "warning",
        title: "Incomplete Fields",
        html: `Please fill in the following fields in the <strong>${firstIncompleteSection}</strong> section:<br><br>${missingFieldNames}`,
        confirmButtonColor: "#f27474",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#FCA5A5",
      });
      setSubmitting(false);
      return;
    }

    if (!activeSociety) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No active society selected",
        confirmButtonColor: "#f27474",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#FCA5A5",
      });
      setSubmitting(false);
      return;
    }

    try {
      const submissionPayload = {
        username: values.username,
        password: values.password,
        role: "Customer",
        profile_picture: profileImage,
        societies: [activeSociety._id],
        customerData: {
          ...values.customerData,
          societies: [activeSociety._id],
          contactInfo: {
            ...values.customerData.contactInfo,
            id_front_img: values.customerData.contactInfo.id_front_img || null,
            id_back_img: values.customerData.contactInfo.id_back_img || null,
            waris_id_front_img:
              values.customerData.contactInfo.waris_id_front_img || null,
            waris_id_back_img:
              values.customerData.contactInfo.waris_id_back_img || null,
          },
        },
      };

      const result = await dispatch(registerUser(submissionPayload)).unwrap();

      if (result?.success || result?.user) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Customer registered successfully.",
          confirmButtonColor: "#008000",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#5EEAD4",
        });
        resetForm();
        setProfileImage(null);
      } else {
        throw new Error(result.message || "Registration failed");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: error.message || "An unexpected error occurred",
        confirmButtonColor: "#f27474",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#FCA5A5",
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Add indicator components for section completion
  const SectionIndicator = ({ values, errors, section }) => {
    const status = getSectionStatus(values, errors, section);
    return (
      <div
        className={`w-2 h-2 rounded-full ml-2 ${
          status.complete ? "bg-green-500" : "bg-red-500"
        }`}
      />
    );
  };

  // Update the section buttons in your render method to include completion indicators
  const renderSectionButton = (section, icon, label, values, errors) => (
    <button
      key={section.key}
      type="button"
      onClick={() => setActiveSection(section.key)}
      className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-all duration-300 ${
        activeSection === section.key
          ? "bg-teal-500/20 text-teal-300"
          : "text-teal-300/50 hover:bg-white/10"
      }`}
    >
      {React.cloneElement(icon, {
        className:
          activeSection === section.key ? "text-teal-300" : "text-teal-300/50",
      })}
      <span className="text-sm font-medium flex-1">{label}</span>
      <SectionIndicator values={values} errors={errors} section={section.key} />
    </button>
  );

  const initialValues = {
    username: "",
    password: "",
    role: "Customer",
    profile_picture: "",
    customerData: {
      name: "",
      contactInfo: {
        phone: "",
        email: "",
        waris_phone: "",
        cnic: "",
        waris_cnic: "",
        address: "",
        father_name: "",
        waris_name: "",
        waris_father_name: "",
        waris_relation: "",
        waris_address: "",
        id_front_img: "",
        id_back_img: "",
        waris_id_front_img: "",
        waris_id_back_img: "",
      },
    },
  };

  return (
    <div className="py-6 flex items-center justify-center">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-transparent pointer-events-none"></div>
        <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-2 border border-white/20 mb-2 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)]">
          <h2 className="text-4xl font-bold text-center p-4 bg-gradient-to-r from-teal-300 via-teal-300 to-teal-300 text-transparent bg-clip-text">
            {activeSociety
              ? `${activeSociety.name} - Customer Registration`
              : "Customer Registration"}
          </h2>
          <p className="text-teal-300/60 text-center mb-4">
            Register new customer in {activeSociety.name}
          </p>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={RegistrationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting, validateForm }) => (
            <Form className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-2xl p-8 border border-white/20">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {/* Sidebar Navigation */}
                <div className="lg:col-span-1 bg-white/5 rounded-xl p-4">
                  <div className="space-y-4">
                    {[
                      {
                        key: "basic",
                        label: "Basic Information",
                        icon: <User />,
                      },
                      {
                        key: "contact",
                        label: "Contact Details",
                        icon: <Phone />,
                      },
                      {
                        key: "waris",
                        label: "Waris Information",
                        icon: <BookUser />,
                      },
                      {
                        key: "User Id Card",
                        label: "User Id Card Images",
                        icon: <IdCard />,
                      },
                      {
                        key: "Waris Id Card",
                        label: "Waris Id Card Images",
                        icon: <IdCard />,
                      },
                    ].map((section) => (
                      <button
                        key={section.key}
                        type="button"
                        onClick={() => setActiveSection(section.key)}
                        className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-all duration-300 ${
                          activeSection === section.key
                            ? "bg-teal-500/20 text-teal-300"
                            : "text-teal-300/50 hover:bg-white/10"
                        }`}
                      >
                        {React.cloneElement(section.icon, {
                          className:
                            activeSection === section.key
                              ? "text-teal-300"
                              : "text-teal-300/50",
                        })}
                        <span className="text-sm font-medium">
                          {section.label}
                        </span>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Main Content Area */}
                <div className="lg:col-span-2 space-y-6">
                  {activeSection === "basic" && (
                    <div>
                      <h3 className="text-xl font-semibold text-teal-300 mb-4">
                        Basic Information
                      </h3>

                      <div className="flex justify-center mb-6">
                        <div className="relative group">
                          <div className="relative w-32 h-32">
                            {profileImage ? (
                              <img
                                src={profileImage}
                                alt="Profile Preview"
                                className="w-full h-full rounded-full object-cover ring-4 ring-teal-300/50"
                              />
                            ) : (
                              <div className="w-full h-full rounded-full bg-gradient-to-br from-slate-700/50 to-slate-800/50 flex items-center justify-center ring-4 ring-teal-300/50 backdrop-blur-sm">
                                <Camera size={40} className="text-teal-300" />
                              </div>
                            )}
                            <label className="absolute bottom-0 right-0 bg-gradient-to-r from-teal-400 to-cyan-400 p-2 rounded-full cursor-pointer hover:scale-110 transition-transform duration-300 shadow-lg">
                              <Camera size={20} className="text-white" />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(event) =>
                                  handleProfileImageChange(event, setFieldValue)
                                }
                                className="hidden"
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="relative">
                          <User
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="username"
                            placeholder="Username"
                            className="w-full pl-10 px-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="text-red-400 text-sm ml-2 mt-1"
                          />
                        </div>
                        <div className="relative">
                          <LockKeyhole
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            className="w-full pl-10 px-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-3 top-3 text-teal-300 hover:text-teal-400 focus:outline-none"
                          >
                            {showPassword ? (
                              <EyeOff size={20} />
                            ) : (
                              <Eye size={20} />
                            )}
                          </button>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-red-400 text-sm ml-2 mt-1"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {activeSection === "contact" && (
                    <div>
                      <h3 className="text-xl font-semibold text-teal-300 mb-4">
                        Contact Information
                      </h3>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <User
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.name"
                            placeholder="Full Name"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.name"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Workflow
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.father_name"
                            placeholder="Father/Husband Name "
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.father_name"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <IdCard
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.cnic"
                            placeholder="XXXXX-XXXXXXX-X"
                            value={formatCNIC(
                              values.customerData.contactInfo.cnic
                            )}
                            onChange={(e) => {
                              const formattedValue = formatCNIC(e.target.value);
                              setFieldValue(
                                "customerData.contactInfo.cnic",
                                formattedValue
                              );
                            }}
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.cnic"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Mail
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="email"
                            name="customerData.contactInfo.email"
                            placeholder="Email"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.email"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Phone
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />

                          <Field
                            type="tel"
                            name="customerData.contactInfo.phone"
                            placeholder="+92 3XX-XXXXXXX"
                            autoComplete="off"
                            value={values.customerData.contactInfo.phone}
                            onChange={(e) => {
                              let rawValue = e.target.value.replace(
                                /[^\d]/g,
                                ""
                              );
                              if (!rawValue.startsWith("92")) {
                                rawValue = `92${rawValue}`;
                              }
                              const formattedValue = `+${rawValue.slice(
                                0,
                                2
                              )} ${rawValue.slice(2, 5)}-${rawValue.slice(
                                5,
                                12
                              )}`;
                              setFieldValue(
                                "customerData.contactInfo.phone",
                                formattedValue
                              );
                            }}
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.phone"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>

                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <BookUser
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.address"
                            placeholder="Customer Address"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.address"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                    </div>
                  )}

                  {activeSection === "waris" && (
                    <div>
                      <h3 className="text-xl font-semibold text-teal-300 mb-4">
                        Waris Information
                      </h3>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <User
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.waris_name"
                            placeholder="Waris Full Name"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.waris_name"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Workflow
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.waris_father_name"
                            placeholder="Waris Father/Husband Name"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.waris_father_name"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Merge
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.waris_relation"
                            placeholder="Waris Relation"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.waris_relation"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <IdCard
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.waris_cnic"
                            placeholder="XXXXX-XXXXXXX-X"
                            value={formatCNIC(
                              values.customerData.contactInfo.waris_cnic
                            )}
                            onChange={(e) => {
                              const formattedValue = formatCNIC(e.target.value);
                              setFieldValue(
                                "customerData.contactInfo.waris_cnic",
                                formattedValue
                              );
                            }}
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.waris_cnic"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <Phone
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />

                          <Field
                            type="tel"
                            name="customerData.contactInfo.waris_phone"
                            placeholder="+92 3XX-XXXXXXX"
                            autoComplete="off"
                            value={values.customerData.contactInfo.waris_phone}
                            onChange={(e) => {
                              let rawValue = e.target.value.replace(
                                /[^\d]/g,
                                ""
                              );
                              if (!rawValue.startsWith("92")) {
                                rawValue = `92${rawValue}`;
                              }
                              const formattedValue = `+${rawValue.slice(
                                0,
                                2
                              )} ${rawValue.slice(2, 5)}-${rawValue.slice(
                                5,
                                12
                              )}`;
                              setFieldValue(
                                "customerData.contactInfo.waris_phone",
                                formattedValue
                              );
                            }}
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.waris_phone"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                      <div className="relative flex flex-col">
                        <div className="relative flex items-center mb-2">
                          <BookUser
                            className="absolute left-3 top-3 text-teal-300 z-10"
                            size={20}
                          />
                          <Field
                            type="text"
                            name="customerData.contactInfo.waris_address"
                            placeholder="Waris Address"
                            className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                          />
                        </div>
                        <ErrorMessage
                          name="customerData.contactInfo.waris_address"
                          component="div"
                          className="text-red-400 text-sm ml-2"
                        />
                      </div>
                    </div>
                  )}

                  {activeSection === "User Id Card" && (
                    <div>
                      <h3 className="text-xl font-semibold text-teal-300 mb-4">
                        User Id Card Images
                      </h3>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Front ID Card */}
                        <div className="space-y-4">
                          <div className="flex justify-between items-center">
                            <label className="text-teal-300">
                              Front ID Card Image
                            </label>
                            {values.customerData.contactInfo.id_front_img && (
                              <button
                                type="button"
                                onClick={() =>
                                  setFieldValue(
                                    "customerData.contactInfo.id_front_img",
                                    ""
                                  )
                                }
                                className="flex items-center gap-1 px-2 py-1 bg-red-500/80 text-white rounded-lg hover:bg-red-600/80"
                              >
                                <X size={16} />
                                Remove
                              </button>
                            )}
                          </div>
                          <div className="relative">
                            {values.customerData.contactInfo.id_front_img ? (
                              <div className="w-full h-48 mb-4">
                                <img
                                  src={
                                    values.customerData.contactInfo.id_front_img
                                  }
                                  alt="Front ID"
                                  className="w-full h-full object-cover rounded-lg border-2 border-teal-300/30"
                                />
                              </div>
                            ) : (
                              <div className="w-full h-48 border-2 border-dashed border-teal-300/30 rounded-lg flex items-center justify-center bg-slate-800/50">
                                <div className="text-center">
                                  <IdCard
                                    size={40}
                                    className="mx-auto text-teal-300/50 mb-2"
                                  />
                                  <p className="text-teal-300/50">
                                    Upload Front ID
                                  </p>
                                </div>
                              </div>
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    setFieldValue(
                                      "customerData.contactInfo.id_front_img",
                                      reader.result
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                              className="absolute inset-0 w-full h-48 opacity-0 cursor-pointer"
                            />
                          </div>
                          <ErrorMessage
                            name="customerData.contactInfo.id_front_img"
                            component="div"
                            className="text-red-400 text-sm"
                          />
                        </div>

                        {/* Back ID Card */}
                        <div className="space-y-4">
                          <div className="flex justify-between items-center">
                            <label className="text-teal-300">
                              Back ID Card Image
                            </label>
                            {values.customerData.contactInfo.id_back_img && (
                              <button
                                type="button"
                                onClick={() =>
                                  setFieldValue(
                                    "customerData.contactInfo.id_back_img",
                                    ""
                                  )
                                }
                                className="flex items-center gap-1 px-2 py-1 bg-red-500/80 text-white rounded-lg hover:bg-red-600/80"
                              >
                                <X size={16} />
                                Remove
                              </button>
                            )}
                          </div>
                          <div className="relative">
                            {values.customerData.contactInfo.id_back_img ? (
                              <div className="w-full h-48 mb-4">
                                <img
                                  src={
                                    values.customerData.contactInfo.id_back_img
                                  }
                                  alt="Back ID"
                                  className="w-full h-full object-cover rounded-lg border-2 border-teal-300/30"
                                />
                              </div>
                            ) : (
                              <div className="w-full h-48 border-2 border-dashed border-teal-300/30 rounded-lg flex items-center justify-center bg-slate-800/50">
                                <div className="text-center">
                                  <IdCard
                                    size={40}
                                    className="mx-auto text-teal-300/50 mb-2"
                                  />
                                  <p className="text-teal-300/50">
                                    Upload Back ID
                                  </p>
                                </div>
                              </div>
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    setFieldValue(
                                      "customerData.contactInfo.id_back_img",
                                      reader.result
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                              className="absolute inset-0 w-full h-48 opacity-0 cursor-pointer"
                            />
                          </div>
                          <ErrorMessage
                            name="customerData.contactInfo.id_back_img"
                            component="div"
                            className="text-red-400 text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {activeSection === "Waris Id Card" && (
                    <div>
                      <h3 className="text-xl font-semibold text-teal-300 mb-4">
                        Waris Id Card Images
                      </h3>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Front ID Card */}
                        <div className="space-y-4">
                          <div className="flex justify-between items-center">
                            <label className="text-teal-300">
                              Front ID Card Image
                            </label>
                            {values.customerData.contactInfo
                              .waris_id_front_img && (
                              <button
                                type="button"
                                onClick={() =>
                                  setFieldValue(
                                    "customerData.contactInfo.waris_id_front_img",
                                    ""
                                  )
                                }
                                className="flex items-center gap-1 px-2 py-1 bg-red-500/80 text-white rounded-lg hover:bg-red-600/80"
                              >
                                <X size={16} />
                                Remove
                              </button>
                            )}
                          </div>
                          <div className="relative">
                            {values.customerData.contactInfo
                              .waris_id_front_img ? (
                              <div className="w-full h-48 mb-4">
                                <img
                                  src={
                                    values.customerData.contactInfo
                                      .waris_id_front_img
                                  }
                                  alt="Front ID"
                                  className="w-full h-full object-cover rounded-lg border-2 border-teal-300/30"
                                />
                              </div>
                            ) : (
                              <div className="w-full h-48 border-2 border-dashed border-teal-300/30 rounded-lg flex items-center justify-center bg-slate-800/50">
                                <div className="text-center">
                                  <IdCard
                                    size={40}
                                    className="mx-auto text-teal-300/50 mb-2"
                                  />
                                  <p className="text-teal-300/50">
                                    Upload Front ID
                                  </p>
                                </div>
                              </div>
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    setFieldValue(
                                      "customerData.contactInfo.waris_id_front_img",
                                      reader.result
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                              className="absolute inset-0 w-full h-48 opacity-0 cursor-pointer"
                            />
                          </div>
                          <ErrorMessage
                            name="customerData.contactInfo.waris_id_front_img"
                            component="div"
                            className="text-red-400 text-sm"
                          />
                        </div>

                        {/* Back ID Card */}
                        <div className="space-y-4">
                          <div className="flex justify-between items-center">
                            <label className="text-teal-300">
                              Back ID Card Image
                            </label>
                            {values.customerData.contactInfo
                              .waris_id_back_img && (
                              <button
                                type="button"
                                onClick={() =>
                                  setFieldValue(
                                    "customerData.contactInfo.waris_id_back_img",
                                    ""
                                  )
                                }
                                className="flex items-center gap-1 px-2 py-1 bg-red-500/80 text-white rounded-lg hover:bg-red-600/80"
                              >
                                <X size={16} />
                                Remove
                              </button>
                            )}
                          </div>
                          <div className="relative">
                            {values.customerData.contactInfo
                              .waris_id_back_img ? (
                              <div className="w-full h-48 mb-4">
                                <img
                                  src={
                                    values.customerData.contactInfo
                                      .waris_id_back_img
                                  }
                                  alt="Back ID"
                                  className="w-full h-full object-cover rounded-lg border-2 border-teal-300/30"
                                />
                              </div>
                            ) : (
                              <div className="w-full h-48 border-2 border-dashed border-teal-300/30 rounded-lg flex items-center justify-center bg-slate-800/50">
                                <div className="text-center">
                                  <IdCard
                                    size={40}
                                    className="mx-auto text-teal-300/50 mb-2"
                                  />
                                  <p className="text-teal-300/50">
                                    Upload Back ID
                                  </p>
                                </div>
                              </div>
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const reader = new FileReader();
                                  reader.onloadend = () => {
                                    setFieldValue(
                                      "customerData.contactInfo.waris_id_back_img",
                                      reader.result
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                              className="absolute inset-0 w-full h-48 opacity-0 cursor-pointer"
                            />
                          </div>
                          <ErrorMessage
                            name="customerData.contactInfo.waris_id_back_img"
                            component="div"
                            className="text-red-400 text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Navigation Buttons */}
                  {/* <div className="flex justify-between mt-6">
                    {activeSection !== "basic" && (
                      <button
                        type="button"
                        onClick={() => {
                          const sectionOrder = [
                            "basic",
                            "contact",
                            "waris",
                            "Waris Id Card",
                          ];
                          const currentIndex =
                            sectionOrder.indexOf(activeSection);
                          setActiveSection(sectionOrder[currentIndex - 1]);
                        }}
                        className="px-6 py-3 bg-slate-700/50 text-teal-300 rounded-lg hover:bg-slate-700/70 transition-colors"
                      >
                        Previous
                      </button>
                    )}
                    {activeSection !== "Waris Id Card" && (
                      <button
                        type="button"
                        onClick={() => {
                          const sectionOrder = [
                            "basic",
                            "contact",
                            "waris",
                            "Waris Id Card",
                          ];
                          const currentIndex =
                            sectionOrder.indexOf(activeSection);
                          setActiveSection(sectionOrder[currentIndex + 1]);
                        }}
                        className="ml-auto px-6 py-3 bg-teal-500/20 text-teal-300 rounded-lg hover:bg-teal-500/30 transition-colors"
                      >
                        Next
                      </button>
                    )}
                    {activeSection === "Waris Id Card" && (
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-8 py-3 bg-gradient-to-r from-teal-400 to-cyan-400 rounded-lg text-white font-semibold shadow-lg hover:scale-[1.02] transform transition duration-300 disabled:opacity-50"
                      >
                        {isSubmitting ? "Registering..." : "Register Customer"}
                      </button>
                    )}
                  </div> */}

                  {/* Navigation Buttons */}
                  {/* Navigation Buttons */}
                  <div className="flex justify-between mt-6">
                    {activeSection !== "basic" && (
                      <button
                        type="button"
                        onClick={() => {
                          const sectionOrder = [
                            "basic",
                            "contact",
                            "waris",
                            "User Id Card",
                            "Waris Id Card",
                          ];
                          const currentIndex =
                            sectionOrder.indexOf(activeSection);
                          setActiveSection(sectionOrder[currentIndex - 1]);
                        }}
                        className="px-6 py-3 bg-slate-700/50 text-teal-300 rounded-lg hover:bg-slate-700/70 transition-colors"
                      >
                        Previous
                      </button>
                    )}
                    {activeSection !== "Waris Id Card" && (
                      <button
                        type="button"
                        onClick={async () => {
                          const sectionOrder = [
                            "basic",
                            "contact",
                            "waris",
                            "User Id Card",
                            "Waris Id Card",
                          ];
                          const currentIndex =
                            sectionOrder.indexOf(activeSection);
                          const currentSection = sectionOrder[currentIndex];

                          // Get form errors using Formik's validateForm
                          const formErrors = await validateForm();

                          // Get section status with the current errors
                          const status = getSectionStatus(
                            values,
                            formErrors,
                            currentSection
                          );

                          if (!status.complete) {
                            // Show warning with missing fields
                            const missingFieldNames = status.errors
                              .map(getFieldLabel)
                              .join(", ");
                            Swal.fire({
                              icon: "warning",
                              title: "Incomplete Fields",
                              html: `Please fill in the following fields before proceeding:<br><br>${missingFieldNames}`,
                              confirmButtonColor: "#f27474",
                              background:
                                "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
                              color: "#FCA5A5",
                            });
                            return;
                          }

                          // If validation passes, move to next section
                          setActiveSection(sectionOrder[currentIndex + 1]);
                        }}
                        className="ml-auto px-6 py-3 bg-teal-500/20 text-teal-300 rounded-lg hover:bg-teal-500/30 transition-colors"
                      >
                        Next
                      </button>
                    )}
                    {activeSection === "Waris Id Card" && (
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-8 py-3 bg-gradient-to-r from-teal-400 to-cyan-400 rounded-lg text-white font-semibold shadow-lg hover:scale-[1.02] transform transition duration-300 disabled:opacity-50"
                      >
                        {isSubmitting ? "Registering..." : "Register Customer"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CustomerRegistrationForm;
