// import React from 'react';
// import { Field, Form, Formik } from 'formik';
// import { MapPinHouse, ChevronDown, ChevronUp } from 'lucide-react';
// import * as Yup from 'yup';

// const TransactionModalValidationSchema = Yup.object().shape({
//   societies: Yup.array().min(1, 'Select at least one society'),
//   amounts: Yup.object().test(
//     'has-amounts',
//     'Enter amount for each selected society',
//     function(value) {
//       const societies = this.parent.societies || [];
//       return societies.every(societyId => 
//         value && value[societyId] && Number(value[societyId]) > 0
//       );
//     }
//   ),
//   transaction_type: Yup.string().required('Transaction type is required'),
//   transaction_direction: Yup.string().required('Transaction direction is required'),
//   payment_method: Yup.string().required('Payment method is required'),
//   transaction_date: Yup.string().required('Transaction date is required')
// });

// const TransactionModal = ({ 
//   isOpen, 
//   closeModal, 
//   societies, 
//   onSubmit,
//   modalStyle,
//   modalOverlayStyle 
// }) => {
//   const [selectedSocieties, setSelectedSocieties] = React.useState([]);
//   const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

//   const handleSocietySelect = (societyId, setFieldValue) => {
//     const updatedSelection = selectedSocieties.includes(societyId)
//       ? selectedSocieties.filter(id => id !== societyId)
//       : [...selectedSocieties, societyId];
    
//     setSelectedSocieties(updatedSelection);
//     setFieldValue('societies', updatedSelection);
//   };

//   const handleSubmit = (values, { setSubmitting }) => {
//     const formattedValues = {
//       societies: values.societies,
//       amounts: values.amounts,
//       transaction_type: values.transaction_type,
//       transaction_direction: values.transaction_direction,
//       payment_method: values.payment_method,
//       transaction_date: values.transaction_date
//     };
//     onSubmit(formattedValues);
//     setSubmitting(false);
//   };

//   return (
//     <div className={`modal ${isOpen ? 'block' : 'hidden'}`}>
//       <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50">
//         <div className="flex min-h-screen items-center justify-center p-4">
//           <div className="w-full max-w-2xl rounded-xl bg-slate-800 p-6 shadow-xl">
//             <h2 className="mb-6 text-2xl font-bold text-teal-400">Create Societies Transaction</h2>
            
//             <Formik
//               initialValues={{
//                 societies: [],
//                 amounts: {},
//                 transaction_type: '',
//                 transaction_direction: '',
//                 payment_method: '',
//                 transaction_date: ''
//               }}
//               validationSchema={TransactionModalValidationSchema}
//               onSubmit={handleSubmit}
//             >
//               {({ values, setFieldValue, errors, touched }) => (
//                 <Form className="space-y-6">
//                   {/* Societies Selection */}
//                   <div className="relative">
//                     <label className="block text-sm font-medium text-teal-400 mb-2">
//                       Select Societies
//                     </label>
//                     <div className="relative">
//                       <div 
//                         className="flex items-center cursor-pointer border border-teal-400/30 rounded-lg p-3"
//                         onClick={() => setIsDropdownOpen(!isDropdownOpen)}
//                       >
//                         <MapPinHouse className="text-teal-400 mr-2" size={20} />
//                         <span className="text-teal-100">
//                           {selectedSocieties.length 
//                             ? societies
//                                 .filter(s => selectedSocieties.includes(s._id))
//                                 .map(s => s.name)
//                                 .join(', ')
//                             : 'Select societies'}
//                         </span>
//                         {isDropdownOpen ? (
//                           <ChevronUp className="ml-auto text-teal-400" size={20} />
//                         ) : (
//                           <ChevronDown className="ml-auto text-teal-400" size={20} />
//                         )}
//                       </div>

//                       {isDropdownOpen && (
//                         <div className="absolute z-10 mt-1 w-full bg-slate-700 rounded-lg border border-teal-400/30 shadow-lg">
//                           {societies.map(society => (
//                             <div
//                               key={society._id}
//                               className={`p-3 cursor-pointer hover:bg-slate-600 ${
//                                 selectedSocieties.includes(society._id)
//                                   ? 'bg-teal-400/20 text-teal-100'
//                                   : 'text-gray-200'
//                               }`}
//                               onClick={() => handleSocietySelect(society._id, setFieldValue)}
//                             >
//                               {society.name}
//                             </div>
//                           ))}
//                         </div>
//                       )}
//                     </div>
//                   </div>

//                   {/* Amount Fields for Selected Societies */}
//                   {selectedSocieties.map(societyId => {
//                     const society = societies.find(s => s._id === societyId);
//                     return (
//                       <div key={societyId} className="space-y-2">
//                         <label className="block text-sm font-medium text-teal-400">
//                           Amount for {society?.name}
//                         </label>
//                         <Field
//                           type="number"
//                           name={`amounts.${societyId}`}
//                           className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
//                           placeholder="Enter amount"
//                         />
//                       </div>
//                     );
//                   })}

//                   {/* Transaction Fields */}
//                   <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                     <div>
//                       <label className="block text-sm font-medium text-teal-400 mb-2">
//                         Transaction Type
//                       </label>
//                       <Field
//                         as="select"
//                         name="transaction_type"
//                         className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
//                       >
//                         <option value="">Select Type</option>
//                         <option value="Full Payment">Full Payment</option>
//                         <option value="Partial Payment">Partial Payment</option>
//                         <option value="Installment Payment">Installment Payment</option>
//                       </Field>
//                     </div>

//                     <div>
//                       <label className="block text-sm font-medium text-teal-400 mb-2">
//                         Transaction Direction
//                       </label>
//                       <Field
//                         as="select"
//                         name="transaction_direction"
//                         className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
//                       >
//                         <option value="">Select Direction</option>
//                         <option value="Income">Income</option>
//                         <option value="Expense">Expense</option>
//                       </Field>
//                     </div>

//                     <div>
//                       <label className="block text-sm font-medium text-teal-400 mb-2">
//                         Payment Method
//                       </label>
//                       <Field
//                         as="select"
//                         name="payment_method"
//                         className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
//                       >
//                         <option value="">Select Method</option>
//                         <option value="Bank Transfer">Bank Transfer</option>
//                         <option value="Cash Transfer">Cash Transfer</option>
//                         <option value="JazzCash">JazzCash</option>
//                         <option value="Easypaisa">Easypaisa</option>
//                       </Field>
//                     </div>

//                     <div>
//                       <label className="block text-sm font-medium text-teal-400 mb-2">
//                         Transaction Date
//                       </label>
//                       <Field
//                         type="date"
//                         name="transaction_date"
//                         className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
//                       />
//                     </div>
//                   </div>

//                   {/* Action Buttons */}
//                   <div className="flex gap-4 mt-6">
//                     <button
//                       type="submit"
//                       className="flex-1 bg-teal-500 text-white py-3 px-6 rounded-lg hover:bg-teal-600 transition-colors"
//                     >
//                       Create Transaction
//                     </button>
//                     <button
//                       type="button"
//                       onClick={closeModal}
//                       className="flex-1 bg-slate-600 text-white py-3 px-6 rounded-lg hover:bg-slate-700 transition-colors"
//                     >
//                       Cancel
//                     </button>
//                   </div>
//                 </Form>
//               )}
//             </Formik>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TransactionModal;

import React from 'react';
import { Field, Form, Formik } from 'formik';
import { MapPinHouse, ChevronDown, ChevronUp } from 'lucide-react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TransactionModalValidationSchema = Yup.object().shape({
  societies: Yup.array().min(1, 'Select at least one society'),
  amounts: Yup.object().test(
    'has-amounts',
    'Enter amount for each selected society',
    function(value) {
      const societies = this.parent.societies || [];
      return societies.every(societyId => 
        value && value[societyId] && Number(value[societyId]) > 0
      );
    }
  ),
  transaction_type: Yup.string().required('Transaction type is required'),
  transaction_direction: Yup.string().required('Transaction direction is required'),
  payment_method: Yup.string().required('Payment method is required'),
  transaction_date: Yup.string().required('Transaction date is required'),
  description: Yup.string()
    .max(500, 'Description must be 500 characters or less')
    .optional(),
});

const TransactionModal = ({ isOpen, closeModal, societies, onSubmit }) => {
  const [selectedSocieties, setSelectedSocieties] = React.useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isOpen) {
      setSelectedSocieties([]);
      setIsDropdownOpen(false);
    }
  }, [isOpen]);

  const handleSocietySelect = (societyId, setFieldValue) => {
    const updatedSelection = selectedSocieties.includes(societyId)
      ? selectedSocieties.filter(id => id !== societyId)
      : [...selectedSocieties, societyId];
    
    setSelectedSocieties(updatedSelection);
    setFieldValue('societies', updatedSelection);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await Swal.fire({
        title: 'Create Transaction?',
        text: 'Are you sure you want to create this transaction?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#14B8A6',
        cancelButtonColor: '#6B7280',
        confirmButtonText: 'Yes, create it!',
        background: '#1E293B',
        color: '#fff',
      });

      if (result.isConfirmed) {
        await onSubmit(values);
      }
    } catch (error) {
      await Swal.fire({
        title: 'Error!',
        text: error.message || 'Failed to create transaction',
        icon: 'error',
        confirmButtonColor: '#14B8A6',
        background: '#1E293B',
        color: '#fff',
      });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className={`modal ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50">
        <div className="flex min-h-screen items-center justify-center p-4">
          <div className="w-full max-w-2xl rounded-xl bg-slate-800 p-6 shadow-xl">
            <h2 className="mb-6 text-2xl font-bold text-teal-400">Create Societies Transaction</h2>
            
            <Formik
              initialValues={{
                societies: [],
                amounts: {},
                transaction_type: '',
                transaction_direction: '',
                payment_method: '',
                transaction_date: new Date(),
                description: '',

              }}
              validationSchema={TransactionModalValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched, isSubmitting }) => (
                <Form className="space-y-6">
                  {/* Societies Selection */}
                  <div className="relative">
                    <label className="block text-sm font-medium text-teal-400 mb-2">
                      Select Societies
                    </label>
                    <div className="relative">
                      <div 
                        className="flex items-center cursor-pointer border border-teal-400/30 rounded-lg p-3"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        <MapPinHouse className="text-teal-400 mr-2" size={20} />
                        <span className="text-teal-100">
                          {selectedSocieties.length 
                            ? societies
                                .filter(s => selectedSocieties.includes(s._id))
                                .map(s => s.name)
                                .join(', ')
                            : 'Select societies'}
                        </span>
                        {isDropdownOpen ? (
                          <ChevronUp className="ml-auto text-teal-400" size={20} />
                        ) : (
                          <ChevronDown className="ml-auto text-teal-400" size={20} />
                        )}
                      </div>

                      {isDropdownOpen && (
                        <div className="absolute z-10 mt-1 w-full bg-slate-700 rounded-lg border border-teal-400/30 shadow-lg">
                          {societies.map(society => (
                            <div
                              key={society._id}
                              className={`p-3 cursor-pointer hover:bg-slate-600 ${
                                selectedSocieties.includes(society._id)
                                  ? 'bg-teal-400/20 text-teal-100'
                                  : 'text-gray-200'
                              }`}
                              onClick={() => handleSocietySelect(society._id, setFieldValue)}
                            >
                              {society.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {errors.societies && touched.societies && (
                      <div className="text-red-400 text-sm mt-1">{errors.societies}</div>
                    )}
                  </div>

                  {/* Amount Fields for Selected Societies */}
                  {selectedSocieties.map(societyId => {
                    const society = societies.find(s => s._id === societyId);
                    return (
                      <div key={societyId} className="space-y-2">
                        <label className="block text-sm font-medium text-teal-400">
                          Amount for {society?.name}
                        </label>
                        <Field
                          type="number"
                          name={`amounts.${societyId}`}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue(`amounts.${societyId}`, Number(value));
                          }}
                          className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
                          placeholder="Enter amount"
                        />
                        {errors.amounts?.[societyId] && touched.amounts?.[societyId] && (
                          <div className="text-red-400 text-sm">{errors.amounts[societyId]}</div>
                        )}
                      </div>
                    );
                  })}

                  {/* Transaction Fields */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-teal-400 mb-2">
                        Transaction Type
                      </label>
                      <Field
                        as="select"
                        name="transaction_type"
                        className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
                      >
                        <option value="">Select Type</option>
                        <option value="Full Payment">Full Payment</option>
                        <option value="Partial Payment">Partial Payment</option>
                        <option value="Installment Payment">Installment Payment</option>
                        <option value="Salary Payment">Salary Payment</option>
                        <option value="Expense Payment">Expense Payment</option>
                        <option value="Resell Payment">Resell Payment</option>
                      </Field>
                      {errors.transaction_type && touched.transaction_type && (
                        <div className="text-red-400 text-sm mt-1">{errors.transaction_type}</div>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-teal-400 mb-2">
                        Transaction Direction
                      </label>
                      <Field
                        as="select"
                        name="transaction_direction"
                        className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
                      >
                        <option value="">Select Direction</option>
                        <option value="Income">Income</option>
                        <option value="Expense">Expense</option>
                      </Field>
                      {errors.transaction_direction && touched.transaction_direction && (
                        <div className="text-red-400 text-sm mt-1">{errors.transaction_direction}</div>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-teal-400 mb-2">
                        Payment Method
                      </label>
                      <Field
                        as="select"
                        name="payment_method"
                        className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
                      >
                        <option value="">Select Method</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Cash Transfer">Cash Transfer</option>
                        <option value="JazzCash">JazzCash</option>
                        <option value="Easypaisa">Easypaisa</option>
                        <option value="UPaisa">UPaisa</option>
                        <option value="SadaPay">SadaPay</option>
                        <option value="NayaPay">NayaPay</option>
                        <option value="PayPak">PayPak</option>
                        <option value="Other">Other</option>
                      </Field>
                      {errors.payment_method && touched.payment_method && (
                        <div className="text-red-400 text-sm mt-1">{errors.payment_method}</div>
                      )}
                    </div>

                    <div>
                    <label className="block text-sm font-medium text-teal-400 mb-2">
                      Transaction Date
                    </label>
                    <DatePicker
                      selected={values.transaction_date}
                      onChange={(date) => setFieldValue('transaction_date', date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        wrapperClassName="w-full"
                      className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white"
                    />
                    {errors.transaction_date && touched.transaction_date && (
                      <div className="text-red-400 text-sm mt-1">{errors.transaction_date}</div>
                    )}
                  </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-teal-400 mb-2">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      name="description"
                      className="w-full p-3 bg-slate-700 border border-teal-400/30 rounded-lg text-white resize-none"
                      placeholder="Enter transaction description (optional, max 500 characters)"
                      rows={4}
                    />
                    {errors.description && touched.description && (
                      <div className="text-red-400 text-sm mt-1">{errors.description}</div>
                    )}
                  </div>
                  {/* Action Buttons */}
                  <div className="flex gap-4 mt-6">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="flex-1 bg-teal-500 text-white py-3 px-6 rounded-lg hover:bg-teal-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isSubmitting ? 'Creating...' : 'Create Transaction'}
                    </button>
                    <button
                      type="button"
                      onClick={closeModal}
                      className="flex-1 bg-slate-600 text-white py-3 px-6 rounded-lg hover:bg-slate-700 transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionModal;