
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { ProSidebarProvider } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";

const SidebarContext = createContext(null);
const ACTIVE_SOCIETY_KEY = "activeSociety";

// Background gradients for each active society
const gradientBackgrounds = [
  "bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800",
  "bg-gradient-to-br from-slate-500 via-slate-600 to-slate-500",
  "bg-gradient-to-br from-red-950 via-red-850 to-red-950",
];

const themeColors = [
  {
    tailwind: {
      text: 'text-teal-400',
      hover: 'hover:text-teal-500',
      active: 'active:text-teal-600',
      border: 'border-teal-400'
    },
    mui: {
      main: '#2DD4BF',
      hover: '#14B8A6',
      active: '#0D9488',
      border: '#2DD4BF'
    }
  },
  {
    tailwind: {
      text: 'text-yellow-400',
      hover: 'hover:text-yellow-500',
      active: 'active:text-yellow-600',
      border: 'border-yellow-400'
    },
    mui: {
      main: '#FACC15',
      hover: '#EAB308',
      active: '#CA8A04',
      border: '#FACC15'
    }
  },
  {
    tailwind: {
      text: 'text-orange-500',  
      hover: 'hover:text-orange-700',
      active: 'active:text-orange-800',
      border: 'border-orange-500'
    },
    mui: {
      main: '#F97316', 
      hover: '#C2410C',  
      active: '#9A3C00', 
      border: '#F97316'  
    }
  }
  ,
  {
    tailwind: {
      text: 'text-cyan-400',
      hover: 'hover:text-cyan-500',
      active: 'active:text-cyan-600',
      border: 'border-cyan-400'
    },
    mui: {
      main: '#22D3EE',
      hover: '#06B6D4',
      active: '#0891B2',
      border: '#22D3EE'
    }
  },
  {
    tailwind: {
      text: 'text-fuchsia-400',
      hover: 'hover:text-fuchsia-500',
      active: 'active:text-fuchsia-600',
      border: 'border-fuchsia-400'
    },
    mui: {
      main: '#E879F9',
      hover: '#D946EF',
      active: '#C026D3',
      border: '#E879F9'
    }
  }
];
const defaultGradient = gradientBackgrounds[0]; // Default to the first gradient

export const MyProSidebarProvider = ({ children }) => {
  const { societies } = useSelector((state) => state.societies);
  const { activeUser } = useSelector((state) => state.auth);

  const [activeSociety, setActiveSocietyState] = useState(() => {
    const saved = localStorage.getItem(ACTIVE_SOCIETY_KEY);
    return saved ? JSON.parse(saved) : null;
  });

  const [collapsed, setCollapsed] = useState(false);
  const savedSociety = useMemo(() => {
    const saved = localStorage.getItem(ACTIVE_SOCIETY_KEY);
    return saved ? JSON.parse(saved) : null;
  }, []);
  // Create a mapping of society IDs to background gradients and theme colors
  const societySettingsMap = useMemo(() => {
    const map = new Map();
    if (societies) {
      societies.forEach((society, index) => {
        map.set(society._id, {
          gradient: gradientBackgrounds[index % gradientBackgrounds.length],
          theme: themeColors[index % themeColors.length]
        });
      });
    }
    return map;
  }, [societies]);
  useEffect(() => {
    if (!societies?.length) return;

    const initializeSociety = () => {
      // If we have a saved society, verify it still exists in the current societies list
      if (savedSociety) {
        const societyStillExists = societies.find(s => s._id === savedSociety._id);
        if (societyStillExists) {
          setActiveSocietyState(savedSociety);
          return;
        }
      }

      // If no valid saved society, set based on user role
      if (activeUser?.role === "Employee") {
        // For employees, find their first assigned society
        const employeeSociety = societies.find(society => 
          activeUser.societies?.some(userSociety => userSociety._id === society._id)
        );
        if (employeeSociety) {
          setActiveSocietyState(employeeSociety);
          localStorage.setItem(ACTIVE_SOCIETY_KEY, JSON.stringify(employeeSociety));
        }
      } else {
        // For admins or other roles, default to first society
        setActiveSocietyState(societies[0]);
        localStorage.setItem(ACTIVE_SOCIETY_KEY, JSON.stringify(societies[0]));
      }
    };

    initializeSociety();
  }, [societies, activeUser, savedSociety]);

  const updateActiveSociety = (society) => {
    if (society) {
      setActiveSocietyState(society);
      localStorage.setItem(ACTIVE_SOCIETY_KEY, JSON.stringify(society));
    }
  };

  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };

  // Determine the gradient and theme for the active society
  const societySettings = useMemo(() => {
    if (!activeSociety) return { gradient: defaultGradient, theme: themeColors[0] };
    return societySettingsMap.get(activeSociety._id) || { gradient: defaultGradient, theme: themeColors[0] };
  }, [activeSociety, societySettingsMap]);


  const contextValue = useMemo(
    () => ({
      activeSociety,
      setActiveSociety: updateActiveSociety,
      gradient: societySettings.gradient,
      theme: societySettings.theme,
      collapsed,
      toggleSidebar,
    }),
    [activeSociety, societySettings, collapsed]
  );


  return (
    <ProSidebarProvider>
    <SidebarContext.Provider value={contextValue}>
      <div style={{ display: "flex", background: societySettings.gradient }}>
        <MyProSidebar />
        {children}
      </div>
    </SidebarContext.Provider>
  </ProSidebarProvider>
  );
};

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebarContext must be used within MyProSidebarProvider");
  }
  return context;
};

export default MyProSidebarProvider;
