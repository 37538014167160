import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useSidebarContext } from "../../scenes/global/sidebar/SidebarContext";
import { fetchAllUsers, registerUser } from "../../store/slices/authSlice";
import {
  User,
  Phone,
  Camera,
  Lock,
  BookUser,
  UserRoundPen,
  IdCard,
  Eye,
  EyeOff,
  CalendarClock,
  CreditCard,
  Shield,
} from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EmployeeCreation = () => {
  const dispatch = useDispatch();
  const { activeSociety } = useSidebarContext();
  const { users, status } = useSelector((state) => state.auth);
  const [profileImage, setProfileImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  // Designations from the previous component
  const designations = ["CEO", "Manager", "Sales Officer", "Other"];

  useEffect(() => {
    if (status === "idle" || (status === "failed" && users.length === 0)) {
      dispatch(fetchAllUsers());
    }
  }, [dispatch, status, users.length]);

  const handleProfileImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        setFieldValue("profile_picture", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatCNIC = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const truncatedValue = cleanedValue.slice(0, 13);
    if (truncatedValue.length <= 5) {
      return truncatedValue;
    } else if (truncatedValue.length <= 12) {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(5)}`;
    } else {
      return `${truncatedValue.slice(0, 5)}-${truncatedValue.slice(
        5,
        12
      )}-${truncatedValue.slice(12)}`;
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    employeeData: Yup.object().shape({
      name: Yup.string().required("Employee name is required"),
      designation: Yup.string()
        .required("Designation is required")
        .oneOf(designations, "Invalid designation"),
      personalDetails: Yup.object().shape({
        phone: Yup.string()
          .required("Phone number is required")
          .matches(
            /^\+92\s3[0-9]{2}-[0-9]{7}$/,
            "Phone number must be in format +92 3XX-XXXXXXX"
          ),
        address: Yup.string().required("Address is required"),
        dateOfJoining: Yup.date().required("Date of joining is required"),
        cnic: Yup.string()
          .required("CNIC is required")
          .matches(
            /^\d{5}-\d{7}-\d{1}$/,
            "CNIC must be in format XXXXX-XXXXXXX-X"
          ),
      }),
      salaryDetails: Yup.object().shape({
        salary: Yup.number()
          .required("Salary is required")
          .positive("Salary must be a positive number"),
      }),
    }),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      if (!activeSociety) {
        throw new Error("No active society selected");
      }

      // Add the active society to the societies array
      const userValues = {
        ...values,
        role: "Employee",
        profile_picture: profileImage,
        societies: [activeSociety._id], // Only include the active society
      };

      // Register user
      const userResult = await dispatch(registerUser(userValues));

      if (userResult.payload && userResult.payload.user) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Employee registered successfully",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#5EEAD4",
          confirmButtonColor: "#008000",
        });

        resetForm();
        setProfileImage(null);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "Registration failed",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#FCA5A5",
        confirmButtonColor: "#f27474",
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (!activeSociety) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <p className="text-teal-300 text-xl">Loading society information...</p>
      </div>
    );
  }

  return (
    <div className="py-6 md:py-12 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)]">
      <div className="relative container mx-auto px-4">
        <Formik
          initialValues={{
            username: "",
            password: "",
            employeeData: {
              name: "",
              designation: "",
              personalDetails: {
                phone: "",
                address: "",
                dateOfJoining: "",
                cnic: "",
              },
              salaryDetails: {
                salary: "",
                lastPaidDate: null,
                salarySlip: "",
              },
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className="relative w-full max-w-4xl mx-auto backdrop-blur-xl bg-white/10 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-4 md:p-8 border border-white/20 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-transparent pointer-events-none"></div>

              <div className="relative">
                <h2 className="text-4xl font-bold text-center mb-2 bg-gradient-to-r from-teal-300 via-teal-300 to-teal-300 text-transparent bg-clip-text">
                  {activeSociety.name} - Employee Registration
                </h2>
                <p className="text-teal-300/60 text-center mb-8">
                  Register new employee in {activeSociety.name}
                </p>

                {/* Profile Image Section */}
                <div className="mb-8 md:mb-12 flex flex-col items-center">
                  <div className="relative group">
                    <div className="relative w-24 h-24 md:w-32 md:h-32">
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="Profile Preview"
                          className="w-full h-full rounded-full object-cover ring-4 ring-teal-300/50"
                        />
                      ) : (
                        <div className="w-full h-full rounded-full bg-gradient-to-br from-slate-700/50 to-slate-800/50 flex items-center justify-center ring-4 ring-teal-300/50 backdrop-blur-sm">
                          <Camera size={32} className="text-teal-300" />
                        </div>
                      )}
                      <label className="absolute bottom-0 right-0 bg-gradient-to-r from-teal-400 to-cyan-400 p-1.5 md:p-2 rounded-full cursor-pointer hover:scale-110 transition-transform duration-300 shadow-lg">
                        <Camera size={16} className="text-white" />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) =>
                            handleProfileImageChange(event, setFieldValue)
                          }
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                {/* Form Content */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8">
                  {/* Left Column - Basic Information */}
                  <div className="space-y-4 md:space-y-6">
                    <h3 className="text-lg md:text-xl font-semibold text-teal-300 mb-2 md:mb-4">
                      Basic Information
                    </h3>

                    {/* Username Field */}
                    <div className="relative flex items-center">
                      <User
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="text"
                        name="username"
                        placeholder="Username"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Password Field */}
                    <div className="relative flex items-center">
                      <Lock
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-3"
                      >
                        {showPassword ? (
                          <Eye size={20} className="text-teal-300" />
                        ) : (
                          <EyeOff size={20} className="text-teal-300" />
                        )}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Designation Field */}
                    <div className="relative flex items-center">
                      <Shield
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        as="select"
                        name="employeeData.designation"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      >
                        <option value="">Select Designation</option>
                        {designations.map((designation) => (
                          <option key={designation} value={designation}>
                            {designation}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <ErrorMessage
                      name="employeeData.designation"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  {/* Right Column - Contact Information */}
                  <div className="space-y-4 md:space-y-6">
                    <h3 className="text-lg md:text-xl font-semibold text-teal-300 mb-2 md:mb-4">
                      Contact Information
                    </h3>

                    {/* Employee Name Field */}
                    <div className="relative flex items-center">
                      <UserRoundPen
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="text"
                        name="employeeData.name"
                        placeholder="Employee Name"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.name"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Phone Number Field */}
                    <div className="relative flex items-center">
                      <Phone
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="tel"
                        name="employeeData.personalDetails.phone"
                        placeholder="+92 3XX-XXXXXXX"
                        autoComplete="off"
                        value={values.employeeData.personalDetails.phone}
                        onChange={(e) => {
                          let rawValue = e.target.value.replace(/[^\d]/g, "");
                          if (!rawValue.startsWith("92")) {
                            rawValue = `92${rawValue}`;
                          }
                          const formattedValue = `+${rawValue.slice(
                            0,
                            2
                          )} ${rawValue.slice(2, 5)}-${rawValue.slice(5, 12)}`;
                          setFieldValue(
                            "employeeData.personalDetails.phone",
                            formattedValue
                          );
                        }}
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.personalDetails.phone"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* CNIC */}
                    <div className="relative flex items-center">
                      <IdCard
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="text"
                        name="employeeData.personalDetails.cnic"
                        placeholder="XXXXX-XXXXXXX-X"
                        autoComplete="off"
                        value={formatCNIC(
                          values.employeeData.personalDetails.cnic
                        )}
                        onChange={(e) => {
                          const formattedValue = formatCNIC(e.target.value);
                          setFieldValue(
                            "employeeData.personalDetails.cnic",
                            formattedValue
                          );
                        }}
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.personalDetails.cnic"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Address */}
                    <div className="relative flex items-center">
                      <BookUser
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="text"
                        name="employeeData.personalDetails.address"
                        placeholder="Employee Address"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.personalDetails.address"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Date of Joining */}
                    <div className="relative flex items-center">
                      <CalendarClock
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <DatePicker
                        selected={
                          values.employeeData.personalDetails.dateOfJoining
                        }
                        onChange={(date) =>
                          setFieldValue(
                            "employeeData.personalDetails.dateOfJoining",
                            date
                          )
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        wrapperClassName="w-full"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                        maxDate={new Date()}
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.personalDetails.dateOfJoining"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />

                    {/* Salary */}
                    <div className="relative flex items-center">
                      <CreditCard
                        className="absolute left-3 top-3 text-teal-300"
                        size={20}
                      />
                      <Field
                        type="number"
                        name="employeeData.salaryDetails.salary"
                        placeholder="Employee Salary"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-3 bg-slate-800/50 rounded-lg border border-white/10 focus:border-teal-300 text-teal-300 placeholder-teal-300/50 outline-none transition-all duration-300 backdrop-blur-md"
                      />
                    </div>
                    <ErrorMessage
                      name="employeeData.salaryDetails.salary"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>

                  <div className="lg:col-span-2 flex items-center justify-center mt-6 md:mt-8">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full max-w-xs py-2 md:py-3 px-6 md:px-8 bg-gradient-to-r from-teal-400 to-cyan-400 rounded-lg text-white font-semibold text-base md:text-lg shadow-lg hover:scale-[1.02] transform transition duration-300 disabled:opacity-50"
                    >
                      {isSubmitting ? "Registering..." : "Register Employee"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EmployeeCreation;
