import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createResell, clearResell } from "../store/slices/resellSlice";
import { fetchAllUsers, fetchCustomerPlots } from "../store/slices/authSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import {
  Users,
  MapPin,
  Calendar,
  CreditCard,
  FileText,
  Building2,
  UserPlus,
  PlusCircle,
} from "lucide-react";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ResellManagement = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const users = useSelector((state) => state.auth.users) || [];
  // const { societies } = useSelector((state) => state.societies) || {
  //   societies: [],
  // };
  const { activeSociety } = useSidebarContext();

  const societyUsers = activeSociety?.users || [];
  const filteredUsers = users.filter((user) =>
    societyUsers.some((societyUser) => societyUser._id === user._id)
  );
  const [formData, setFormData] = useState({
    plot_id: "",
    previous_customer_id: "",
    new_customer_id: "",
    resell_fee: 0,
    resell_date: "",
    description: "",
  });
  const [previousCustomerPlots, setPreviousCustomerPlots] = useState([]);
  const [previousSocietyId, setPreviousSocietyId] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchSocieties());
  }, [dispatch]);

  useEffect(() => {
    if (activeSociety?._id !== previousSocietyId) {
      setFormData({
        plot_id: "",
        previous_customer_id: "",
        new_customer_id: "",
        resell_fee: 0,
        resell_date: "",
        description: "",
      });
      setFormErrors({});
      setPreviousCustomerPlots([]);
      setPreviousSocietyId(activeSociety?._id);
    }
  }, [activeSociety?._id]);

  useEffect(() => {
    if (
      formData.previous_customer_id &&
      activeSociety?._id === previousSocietyId
    ) {
      dispatch(fetchCustomerPlots(formData.previous_customer_id)).then(
        (result) => {
          const societyPlots = (result.payload || []).filter((plot) =>
            activeSociety?.plots?.some(
              (societyPlot) => societyPlot._id === plot._id
            )
          );
          setPreviousCustomerPlots(societyPlots);
        }
      );
    } else {
      setPreviousCustomerPlots([]);
    }
  }, [
    formData.previous_customer_id,
    activeSociety,
    previousSocietyId,
    dispatch,
  ]);

  const handleDateChange = (date) => {
    if (date) {
      setFormData((prevData) => ({
        ...prevData,
        resell_date: date,
      }));
      if (formErrors.resell_date) {
        setFormErrors((prev) => ({
          ...prev,
          resell_date: "",
        }));
      }
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.previous_customer_id) {
      errors.previous_customer_id = "Previous customer is required";
    }

    if (!formData.new_customer_id) {
      errors.new_customer_id = "New customer is required";
    }

    if (!formData.plot_id) {
      errors.plot_id = "Please select a plot";
    }

    if (!formData.resell_fee || formData.resell_fee <= 0) {
      errors.resell_fee = "Please enter a valid resell fee";
    }

    if (!formData.resell_date) {
      errors.resell_date = "Resell date is required";
    }

    if (!formData.description.trim()) {
      errors.description = "Description is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue =
      name === "resell_fee" ? value.replace(/,/g, "") : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "resell_fee" ? Number(formattedValue) : formattedValue,
    }));
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleCreate = async () => {
    if (!validateForm()) {
      enqueueSnackbar("Please fill in all required fields", {
        variant: "error",
      });
      return;
    }
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to create this resell record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
      background: "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
      color: "#5EEAD4",
    });

    if (result.isConfirmed) {
      try {
        const response = await dispatch(createResell(formData));
        if (response?.type === "resell/createResell/fulfilled") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text:
              response?.payload?.message ||
              "Resell record created successfully.",
            confirmButtonColor: "#008000",
            background:
              "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
            color: "#5EEAD4",
          });
          setFormData({
            plot_id: "",
            previous_customer_id: "",
            new_customer_id: "",
            resell_fee: 0,
            resell_date: "",
            description: "",
          });
          setFormErrors({});
          enqueueSnackbar(
            response?.payload?.message || "Created successfully resell record.",
            {
              variant: "success",
            }
          );
          dispatch(clearResell());
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: response?.payload?.message || "An unexpected error occurred.",
            confirmButtonColor: "#f27474",
            background:
              "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
            color: "#FCA5A5",
          });
          enqueueSnackbar(
            response?.payload?.message || "Failed to create resell record.",
            {
              variant: "error",
            }
          );
        }
      } catch (error) {
        console.error("Error creating resell record:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while creating the record.",
          confirmButtonColor: "#f27474",
          background:
            "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
          color: "#FCA5A5",
        });
        enqueueSnackbar("An error occurred. Please try again.", {
          variant: "error",
        });
      }
    }
  };

  const stats = [
    {
      title: "Total Plots",
      value: previousCustomerPlots.length,
      icon: <Building2 className="w-6 h-6" />,
      description: "Available for resell",
    },
    {
      title: "Total Customers",
      value:
        activeSociety?.users?.filter((user) => user.role === "Customer")
          .length || 0,
      icon: <Users className="w-6 h-6" />,
      description: "Active in system",
    },
    {
      title: `Total Blocks in this Society - ${
        [...new Set(activeSociety?.plots?.map((plot) => plot.block))].length ||
        0
      }`,

      icon: <MapPin className="w-6 h-6" />,
      description: "Registered blocks",
      blockNamesArray: [
        ...new Set(activeSociety?.plots?.map((plot) => plot.block)),
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
      <div className="max-w-7xl mx-auto p-6 lg:p-8 space-y-8">
        {/* Header Section */}
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-br from-slate-800/80 via-slate-900/80 to-slate-800/80 backdrop-blur-xl border border-slate-700/50 p-8">
          <div className="absolute inset-0 bg-gradient-to-br from-teal-500/10 via-teal-500/10 to-slate-500/10" />
          <div className="absolute -top-24 -right-24 w-48 h-48 bg-teal-500/20 rounded-full blur-3xl" />
          <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-slate-500/20 rounded-full blur-3xl" />
          <div className="relative">
            <h3 className="text-2xl font-bold mt-2 mb-2 text-teal-400">
              Manage property transfers and resell operations
            </h3>
            <h1 className="text-4xl font-bold bg-gradient-to-r from-teal-300 via-teal-200 to-purple-300 bg-clip-text text-transparent">
              {activeSociety?.name || "Society Name"}
            </h1>

            <div className="flex items-center gap-2 mb-4">
              <MapPin className="w-6 h-6 text-teal-400" />
              <h2 className="text-lg font-medium text-teal-300">
                {activeSociety?.location || "Location"}
              </h2>
            </div>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="group relative overflow-hidden rounded-2xl bg-slate-800/50 backdrop-blur-xl border border-slate-700/50 p-6 transition-all duration-300 hover:bg-slate-800/70 hover:border-teal-500/50"
            >
              <div className="flex items-center gap-4">
                <div className="p-3 rounded-xl bg-gradient-to-br from-teal-500/20 to-slate-500/20 text-teal-300">
                  {stat.icon}
                </div>
                <div>
                  <p className="text-sm text-slate-400">{stat.title}</p>
                  <p className="text-3xl font-bold text-teal-300">
                    {stat.value}
                  </p>
                  {stat.blockNamesArray && (
                    <div className="flex flex-wrap gap-2 mt-2">
                      {stat.blockNamesArray.map((block, index) => (
                        <span
                          key={index}
                          className="bg-teal-900 text-teal-300 font-semibold px-3 py-1 rounded-full text-sm shadow-md"
                        >
                          {block}
                        </span>
                      ))}
                    </div>
                  )}
                  <p className="text-xs text-slate-500 mt-1">
                    {stat.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Main Form Section */}
        <div className="rounded-2xl bg-slate-800/50 backdrop-blur-xl border border-slate-700/50 p-8">
          <div className="space-y-8">
            {/* Customer Selection */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Previous Customer Select */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-teal-300">
                  Previous Customer
                </label>
                <div className="relative">
                  <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                  <select
                    name="previous_customer_id"
                    value={formData.previous_customer_id}
                    onChange={handleInputChange}
                    className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                  >
                    <option value="">Select Customer</option>
                    {filteredUsers
                      .filter((user) => user.role === "Customer")
                      .map((user) => (
                        <option
                          key={user._id}
                          value={user._id}
                          className="bg-slate-800 text-teal-300"
                        >
                          {user.username}
                        </option>
                      ))}
                  </select>
                </div>
                {formErrors.previous_customer_id && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.previous_customer_id}
                  </p>
                )}
              </div>

              {/* New Customer Select */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-teal-300">
                  New Customer
                </label>
                <div className="relative">
                  <UserPlus className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                  <select
                    name="new_customer_id"
                    value={formData.new_customer_id}
                    onChange={handleInputChange}
                    className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                  >
                    <option value="">Select New Customer</option>
                    {filteredUsers
                      .filter(
                        (user) =>
                          user.role === "Customer" &&
                          user._id !== formData.previous_customer_id
                      )
                      .map((user) => (
                        <option
                          key={user._id}
                          value={user._id}
                          className="bg-slate-800 text-teal-300"
                        >
                          {user.username}
                        </option>
                      ))}
                  </select>
                </div>
                {formErrors.new_customer_id && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.new_customer_id}
                  </p>
                )}
              </div>
            </div>

            {/* Plots Grid */}
            {previousCustomerPlots.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-teal-300">
                  Available Plots
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {previousCustomerPlots.map((plot) => (
                    <div
                      key={plot._id}
                      onClick={() =>
                        setFormData((prev) => ({ ...prev, plot_id: plot._id }))
                      }
                      className={`relative overflow-hidden rounded-xl cursor-pointer transition-all duration-300 ${
                        formData.plot_id === plot._id
                          ? "bg-gradient-to-br from-teal-500/20 to-purple-500/20 border-teal-500"
                          : "bg-slate-900/50 hover:bg-slate-800/70"
                      } border border-slate-700/50 p-4 group`}
                    >
                      <div className="space-y-3">
                        <div className="flex items-center justify-between">
                          <span className="text-teal-300 font-medium">
                            Plot #{plot.plot_number}
                          </span>
                          <span className="px-2 py-1 rounded-full text-xs bg-slate-700/50 text-slate-300">
                            {plot.category}
                          </span>
                        </div>
                        <div className="space-y-1 text-sm text-slate-400">
                          <p>Block: {plot.block}</p>
                          <p>Type: {plot.plot_type}</p>
                          <p>Status: {plot.status}</p>
                          <p>Total Plot Amount: {plot.price}</p>
                          <p>
                            Plot size: {plot.size}sq(ft) Plot Size in Marla:{" "}
                            {plot.marla}
                          </p>
                        </div>
                      </div>
                      <div className="absolute inset-0 border border-teal-500/0 group-hover:border-teal-500/50 rounded-xl transition-all duration-300" />
                    </div>
                  ))}
                </div>
                {formErrors.plot_id && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.plot_id}
                  </p>
                )}
              </div>
            )}

            {/* Additional Fields */}
            {formData.plot_id && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Resell Fee Input */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-teal-300">
                      Resell Fee
                    </label>
                    <div className="relative">
                      <CreditCard className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                      <input
                        type="text"
                        name="resell_fee"
                        value={formData.resell_fee.toLocaleString()}
                        onChange={handleInputChange}
                        className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                        placeholder="Enter amount"
                      />
                    </div>
                    {formErrors.resell_fee && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.resell_fee}
                      </p>
                    )}
                  </div>

                  {/* Resell Date Input */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-teal-300">
                      Resell Date
                    </label>
                    <div className="relative">
                      <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400 w-5 h-5" />
                      <DatePicker
                        selected={
                          formData.resell_date
                            ? new Date(formData.resell_date)
                            : null
                        }
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        wrapperClassName="w-full"
                        className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                        maxDate={new Date()}
                      />
                    </div>
                    {formErrors.resell_date && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.resell_date}
                      </p>
                    )}
                  </div>
                </div>

                {/* Description Textarea */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-teal-300">
                    Description
                  </label>
                  <div className="relative">
                    <FileText className="absolute left-3 top-3 text-slate-400 w-5 h-5" />
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      rows="4"
                      className="w-full bg-slate-900/50 border border-slate-700 rounded-xl pl-12 pr-4 py-3 text-slate-300 focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-200"
                      placeholder="Enter description"
                    />
                  </div>
                  {formErrors.resell_fee && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.resell_fee}
                    </p>
                  )}
                </div>

                {/* Submit Button */}
                <button
                  onClick={handleCreate}
                  className="w-full group relative overflow-hidden rounded-xl bg-gradient-to-br from-teal-500 to-teal-500 px-8 py-3 text-white transition-all duration-300 hover:from-teal-600 hover:to-purple-600"
                >
                  <div className="relative flex items-center justify-center gap-2">
                    <PlusCircle className="w-5 h-5" />
                    <span className="font-medium">Resell the Plot</span>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResellManagement;
