import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  payInstallment,
  getInstallmentsForUser,
  getPaidInstallmentsForUser,
  getInstallmentsByPlotId,
  getInstallmentsByPlotIdAndStatus,
  checkOverdueInstallments,
  updateInstallmentStatus,
} from "../../api"; // Ensure this function is correctly imported

// Async thunks

// Record an installment payment
export const payInstallmentAsync = createAsyncThunk(
  "installments/payInstallment",
  async (paymentData, { rejectWithValue }) => {
    try {
      const response = await payInstallment(paymentData);
      return response; // Ensure this returns the expected format
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to pay installment"
      );
    }
  }
);

// Fetch installments for a specific user
export const fetchInstallmentsForUser = createAsyncThunk(
  "installments/fetchInstallmentsForUser",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await getInstallmentsForUser(customerId);
      return response.installments; // Ensure response structure matches
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch installments"
      );
    }
  }
);

// Fetch paid installments for a specific user
export const fetchPaidInstallmentsForUser = createAsyncThunk(
  "installments/fetchPaidInstallmentsForUser",
  async ({ customerId, plotId }, { rejectWithValue }) => {
    // Pass both customerId and plotId
    try {
      // Update this call to include both customerId and plotId
      const response = await getPaidInstallmentsForUser(customerId, plotId);
      // console.log("Full API Response:", response); // Log the entire response

      // Adjust based on actual API response structure
      return {
        completedInstallments: response.completedInstallments || [],
        totalPaidAmount: response.totalPaidAmount || 0,
        totalPendingInstallmentsAmount:
          response.totalPendingInstallmentsAmount || 0,
        totalPlotAmount: response.totalPlotAmount || 0,
        plotDetails: response.plotDetails || {}, // Store plot details if available
      };
    } catch (error) {
      console.error("Detailed Error:", error);
      return rejectWithValue(
        error.response?.data || "Failed to fetch paid installments"
      );
    }
  }
);

export const fetchInstallmentsByPlotId = createAsyncThunk(
  "installments/fetchInstallmentsByPlotId",
  async (plotId, { rejectWithValue }) => {
    try {
      const response = await getInstallmentsByPlotId(plotId);
      return response.installments;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch installments by plot ID"
      );
    }
  }
);

// Fetch installments for a specific plot by status
export const fetchInstallmentsByPlotIdAndStatus = createAsyncThunk(
  "installments/fetchInstallmentsByPlotIdAndStatus",
  async ({ plotId, status }, { rejectWithValue }) => {
    try {
      const response = await getInstallmentsByPlotIdAndStatus(plotId, status);
      return response.installments;
    } catch (error) {
      return rejectWithValue(
        error.response?.data ||
          "Failed to fetch installments by plot ID and status"
      );
    }
  }
);

export const fetchOverdueInstallments = createAsyncThunk(
  "installments/fetchOverdueInstallments",
  async (plotId, { rejectWithValue }) => {
    try {
      const overdueInstallments = await checkOverdueInstallments(plotId);
      return overdueInstallments;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateInstallmentStatusAsync = createAsyncThunk(
  "installments/updateInstallmentStatus",
  async (updateData, { rejectWithValue }) => {
    try {
      const { installmentId, ...rest } = updateData;
      const response = await updateInstallmentStatus(installmentId, rest); // Ensure `installmentId` is sent correctly
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update installment");
    }
  }
);

// Installment slice
const installmentSlice = createSlice({
  name: "installments",
  initialState: {
    installments: [],
    paidInstallments: [],
    pendingInstallments: [],
    partiallyPaidInstallments: [],
    completedInstallments: [],
    overdueInstallments: [],
    status: "idle", // can be 'idle', 'loading', 'succeeded', or 'failed'
    error: null,
  },
  reducers: {
    // Additional non-async reducers if needed
  },
  extraReducers: (builder) => {
    builder
      // Fetch installments for user
      .addCase(fetchInstallmentsForUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchInstallmentsForUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.installments = action.payload;

        // Populate paidInstallments separately based on status
        state.paidInstallments = action.payload.filter(
          (inst) => inst.status === "Completed"
        );

        console.log("Fetched installments for user:", action.payload);
      })
      .addCase(fetchInstallmentsForUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Pay installment
      .addCase(payInstallmentAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(payInstallmentAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedInstallment = action.payload.installment;

        // Update or add the installment to installments array
        const index = state.installments.findIndex(
          (inst) => inst._id === updatedInstallment._id
        );

        if (index !== -1) {
          state.installments[index] = updatedInstallment;
        } else {
          state.installments.push(updatedInstallment);
        }

        // If installment is completed, add or update it in paidInstallments
        if (updatedInstallment.status === "Completed") {
          const paidIndex = state.paidInstallments.findIndex(
            (inst) => inst._id === updatedInstallment._id
          );

          if (paidIndex !== -1) {
            state.paidInstallments[paidIndex] = updatedInstallment;
          } else {
            state.paidInstallments.push({
              ...updatedInstallment,
              payment_date: new Date().toISOString(),
            });
          }
        }
      })
      .addCase(payInstallmentAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Update the reducer handling the API responses
      .addCase(fetchPaidInstallmentsForUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPaidInstallmentsForUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.paidInstallments = action.payload.completedInstallments; // Update paid installments
        state.totalPaidAmount = action.payload.totalPaidAmount;
        state.totalPendingInstallmentsAmount =
          action.payload.totalPendingInstallmentsAmount;
        state.totalPlotAmount = action.payload.totalPlotAmount;
        state.plotDetails = action.payload.plotDetails; // Store plot details

        // console.log(
        //   "Updated paidInstallments in state:",
        //   state.paidInstallments
        // );
      })
      .addCase(fetchPaidInstallmentsForUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch installments by plot ID
      .addCase(fetchInstallmentsByPlotId.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchInstallmentsByPlotId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.installments = action.payload;
        // console.log("Fetched installments by plot ID:", action.payload);
      })
      .addCase(fetchInstallmentsByPlotId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Fetch installments by plot ID and status
      .addCase(fetchInstallmentsByPlotIdAndStatus.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchInstallmentsByPlotIdAndStatus.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          const { status } = action.meta.arg;

          if (status === "Pending") {
            state.pendingInstallments = action.payload;
          } else if (status === "Partially Paid") {
            state.partiallyPaidInstallments = action.payload;
          } else if (status === "Completed") {
            state.completedInstallments = action.payload;
          }
        }
      )

      .addCase(fetchInstallmentsByPlotIdAndStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchOverdueInstallments.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOverdueInstallments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.overdueInstallments = action.payload;
      })
      .addCase(fetchOverdueInstallments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateInstallmentStatusAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateInstallmentStatusAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedInstallment = action.payload.installment;
      
        // Find and update the installment in the main array
        const index = state.installments.findIndex(
          (inst) => inst._id === updatedInstallment._id
        );
        if (index !== -1) {
          state.installments[index] = updatedInstallment;
        } else {
          state.installments.push(updatedInstallment);
        }
      
        // Remove the installment from other status arrays
        state.pendingInstallments = state.pendingInstallments.filter(
          (inst) => inst._id !== updatedInstallment._id
        );
        state.partiallyPaidInstallments = state.partiallyPaidInstallments.filter(
          (inst) => inst._id !== updatedInstallment._id
        );
        state.completedInstallments = state.completedInstallments.filter(
          (inst) => inst._id !== updatedInstallment._id
        );
      
        // Add the updated installment to the appropriate array
        if (updatedInstallment.status === "Completed") {
          state.completedInstallments.push(updatedInstallment);
        } else if (updatedInstallment.status === "Pending") {
          state.pendingInstallments.push(updatedInstallment);
        } else if (updatedInstallment.status === "Partially Paid") {
          state.partiallyPaidInstallments.push(updatedInstallment);
        }
      
        console.log("Updated installment status:", updatedInstallment);
      })
      .addCase(updateInstallmentStatusAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default installmentSlice.reducer;
