import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTransaction,
  getAllTransactions,
  getTransactionById,
  updateTransaction,
  deleteTransaction,

} from "../../api";
import {
  fetchSocieties,
} from "./societySlice";

// Thunks for async actions
// export const fetchTransactions = createAsyncThunk(
//   "transactions/fetchAll",
//   async () => {
//     const data = await getAllTransactions();
//     return data;
//   }
// );

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchAll",
  async (societyId) => {
    const data = await getAllTransactions(societyId);
    return data;
  }
);


export const fetchTransactionById = createAsyncThunk(
  "transactions/fetchById",
  async (id) => {
    const data = await getTransactionById(id);
    return data;
  }
);

export const addTransaction = createAsyncThunk(
  "transactions/add",
  async (transactionData) => {
    const data = await createTransaction(transactionData);
    return data;
  }
);

export const modifyTransaction = createAsyncThunk(
  'transactions/modifyTransaction',
  async ({ id, transactionData, societyId }, { dispatch }) => {
    try {
      const response = await updateTransaction(id, transactionData); // Your API call
      // After successful update, fetch the updated society data
      dispatch(fetchSocieties());
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const removeTransaction = createAsyncThunk(
  'transactions/removeTransaction',
  async (transactionId, { dispatch }) => {
    try {
      const response = await deleteTransaction(transactionId); // Your API call
      // After successful deletion, fetch the updated society data
      dispatch(fetchSocieties());
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Slice
const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    transactions: [], // This is an array, but the reducer treats it as an object
    currentTransaction: null,
    loading: false,
    error: null,
  },
  reducers: {updateSocietyTransaction: (state, action) => {
    const { societyId, transactionData } = action.payload;
    // Update the transaction in the society's FinancialTransaction array
    if (state.transactions[societyId]) {
      const index = state.transactions[societyId].findIndex(
        (t) => t._id === transactionData._id
      );
      if (index !== -1) {
        state.transactions[societyId][index] = transactionData;
      }
    }
  },
},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTransactionById.fulfilled, (state, action) => {
        state.currentTransaction = action.payload;
      })
      .addCase(addTransaction.fulfilled, (state, action) => {
        state.transactions.push(action.payload);
      })
      .addCase(modifyTransaction.fulfilled, (state, action) => {
        const { updatedTransaction, societyId } = action.payload;
        if (Array.isArray(state.transactions)) {
          // If transactions is an array
          const index = state.transactions.findIndex(
            (t) => t._id === updatedTransaction._id
          );
          if (index !== -1) {
            state.transactions[index] = updatedTransaction;
          }
        } else if (state.transactions[societyId]) {
          // If transactions is organized by societyId
          const index = state.transactions[societyId].findIndex(
            (t) => t._id === updatedTransaction._id
          );
          if (index !== -1) {
            state.transactions[societyId][index] = updatedTransaction;
          }
        }
      })
      .addCase(removeTransaction.fulfilled, (state, action) => {
        const { id, societyId } = action.payload;
        if (state.transactions[societyId]) {
          state.transactions[societyId] = state.transactions[societyId].filter(
            (t) => t._id !== id
          );
        }
      });
  },
});

export default transactionSlice.reducer;
