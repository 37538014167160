import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSocieties } from "../store/slices/societySlice";
import { editPlot, deletePlot } from "../store/slices/plotSlice";
import {
  Edit as EditIcon,
  Trash2 as DeleteIcon,
  ShieldX,
  Filter,
  Search,
  X,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";

const PlotList = () => {
  const dispatch = useDispatch();
  const { societies } = useSelector((state) => state.societies);
  const { activeSociety } = useSidebarContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);
  const plotsPerPage = 10;
  const { enqueueSnackbar } = useSnackbar();

  console.log(activeSociety);
  const [filters, setFilters] = useState({
    plotNumber: "",
    block: "",
    size: "",
    marla: "",
    category: "",
    booking_status: "",
    type: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPlot, setCurrentPlot] = useState(null);

  useEffect(() => {
    dispatch(fetchSocieties());
  }, [dispatch]);

  useEffect(() => {
    if (societies.length > 0) {
      setCurrentPage(1);
    }
  }, [societies]);
  useEffect(() => {
    if (
      activeSociety &&
      activeSociety.plots &&
      activeSociety.plots.length > 0
    ) {
      console.log("First plot data:", {
        plotNumber: activeSociety.plots[0].plot_number,
        marla: activeSociety.plots[0].marla,
        size: activeSociety.plots[0].size,
      });
    }
  }, [activeSociety]);
  const filteredPlots =
    activeSociety && activeSociety.plots
      ? activeSociety.plots
          .filter(
            (plot) =>
              (filters.plotNumber === "" ||
                plot.plot_number
                  .toString()
                  .toLowerCase()
                  .includes(filters.plotNumber.toLowerCase())) &&
              (filters.block === "" ||
                plot.block
                  .toLowerCase()
                  .includes(filters.block.toLowerCase())) &&
              (filters.size === "" ||
                plot.size.toString().includes(filters.size)) &&
              (filters.marla === "" ||
                plot.marla.toString().includes(filters.marla)) &&
              (filters.booking_status === "" ||
                plot.booking_status
                  .toString()
                  .toLowerCase()
                  .includes(filters.booking_status.toLowerCase())) &&
              (filters.category === "" ||
                plot.category.toLowerCase() ===
                  filters.category.toLowerCase()) &&
              (filters.type === "" ||
                plot.plot_type.toLowerCase() === filters.type.toLowerCase())
          )
          .sort((a, b) =>
            a.plot_number.localeCompare(b.plot_number, undefined, {
              numeric: true,
            })
          )
      : [];

  const indexOfLastPlot = currentPage * plotsPerPage;
  const indexOfFirstPlot = indexOfLastPlot - plotsPerPage;
  const currentPlots = filteredPlots.slice(indexOfFirstPlot, indexOfLastPlot);

  const refreshPlots = async () => {
    await dispatch(fetchSocieties());
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
    setCurrentPlot(null);
  };

  const openEditModal = (plot) => {
    console.log("Opening modal for plot:", plot);

    if (!plot) {
      console.error("Invalid plot passed into openEditModal");
      return;
    }

    setCurrentPlot(plot);
    setIsModalOpen(true);
  };

  const handleDelete = async (plotId, currentStatus) => {
    if (!activeSociety || !activeSociety._id) {
      console.error("Society data is missing or invalid");
      alert("Please select a society before proceeding.");
      return;
    }

    if (currentStatus === "Available") {
      // Logic for deleting a plot
      Swal.fire({
        title: "Delete Plot?",
        text: "Are you sure you want to delete this plot permanently?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete!",
        cancelButtonText: "No, cancel!",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#5EEAD4",
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748b",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deletePlot({ societyId: activeSociety._id, plotId }))
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "The plot has been deleted.",
                background:
                  "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
                color: "#5EEAD4",
                confirmButtonColor: "#008000",
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Failed to delete the plot.",
                background:
                  "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
                color: "#5EEAD4",
                confirmButtonColor: "#f27474",
              });
            });
        }
      });
    } else if (currentStatus === "Booked") {
      // Logic for changing status to "Available"
      Swal.fire({
        title: "Change Plot Status?",
        text: "This will cancel the booking and make the plot available.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, make available!",
        cancelButtonText: "No, cancel!",
        background:
          "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
        color: "#5EEAD4",
        confirmButtonColor: "#EF4444",
        cancelButtonColor: "#64748b",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deletePlot({ societyId: activeSociety._id, plotId }))
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Updated!",
                text: "Plot status changed to Available.",
                background:
                  "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
                color: "#5EEAD4",
                confirmButtonColor: "#008000",
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Failed to change plot status.",
                background:
                  "linear-gradient(to bottom right, #1e293b, #0f172a, #1e293b)",
                color: "#5EEAD4",
                confirmButtonColor: "#f27474",
              });
            });
        }
      });
    } else {
      alert("This action is not allowed for the current plot status.");
    }
    await refreshPlots();
  };

  const handleEdit = async (updatedPlotData) => {
    if (!currentPlot) {
      console.error("currentPlot is null or undefined");
      return;
    }

    const duplicatePlot = activeSociety.plots.find(
      (plot) =>
        plot.plot_number === updatedPlotData.plot_number &&
        plot._id !== currentPlot._id
    );

    if (duplicatePlot) {
      setErrorMessage(
        `Plot number "${duplicatePlot.plot_number}" already exists in "${activeSociety.name}"! Please choose a different one.`
      );
      return;
    }
    setErrorMessage("");

    const plotData = {
      societyId: activeSociety._id,
      plotId: currentPlot._id,
      plotData: updatedPlotData,
    };

    dispatch(editPlot(plotData))
      .then(() => {
        closeEditModal();
        enqueueSnackbar("Plot updated successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      })
      .catch((error) => {
        console.error("Error editing plot:", error);
        enqueueSnackbar("An error occurred while editing the plot.", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        });
      });
    await refreshPlots();
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1); // Use setCurrentPage instead of onPageChange
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(filteredPlots.length / plotsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1); // Use setCurrentPage instead of onPageChange
    }
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(filteredPlots.length / plotsPerPage);
    let pages = [];

    if (totalPages <= 5) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, 5];
      } else if (currentPage >= totalPages - 2) {
        pages = Array.from({ length: 5 }, (_, i) => totalPages - 4 + i);
      } else {
        pages = [
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        ];
      }
    }

    return pages.map((pageNum) => (
      <button
        key={pageNum}
        onClick={() => setCurrentPage(pageNum)} // Use setCurrentPage instead of onPageChange
        className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium transition-all duration-300 ${
          pageNum === currentPage
            ? "bg-teal-500 text-white shadow-lg scale-110"
            : "bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white"
        }`}
      >
        {pageNum}
      </button>
    ));
  };

  if (!activeSociety) {
    return (
      <div className="bg-slate-800 text-teal-300 p-8 rounded-xl shadow-xl animate-fade-in">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-teal-300 mb-6">
            Plot Management System
          </h2>
          <p className="text-xl text-teal-400">
            Please select a society from the sidebar to view plots.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="container mx-auto max-w-7xl space-y-8 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-3xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)]">
        {/* Header Section */}
        <div className="flex justify-between items-center p-4 py-12">
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <h2 className="text-5xl font-bold text-teal-300 tracking-tight">
                Plot Management
              </h2>
              {activeSociety && (
                <div className="px-3 py-1 bg-teal-700/20 text-teal-300 rounded-full text-sm font-medium">
                  {activeSociety.name}
                </div>
              )}
            </div>
            <p className="text-teal-400 text-xl max-w-xl leading-relaxed">
              Seamlessly manage and track your society's plots with intuitive
              precision
            </p>
          </div>

          {/* Filters Toggle with Hover Effects */}
          <button
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            className="group relative flex items-center gap-3 px-6 py-3 
            bg-teal-600/10 text-teal-300 
            rounded-xl 
            hover:bg-teal-600/20 
            transition-all duration-300 
            transform hover:scale-105 
            hover:shadow-lg 
            hover:ring-2 hover:ring-teal-300/50"
          >
            <div className="absolute inset-0 bg-teal-300/10 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity"></div>
            <span className="relative z-10">
              {isFiltersExpanded ? <X size={24} /> : <Filter size={24} />}
            </span>
            <span className="relative z-10 font-semibold">
              {isFiltersExpanded ? "Close Filters" : "Open Filters"}
            </span>
          </button>
        </div>

        {isFiltersExpanded && (
          <div className="grid grid-cols-3 gap-4 bg-slate-800/50 p-6 rounded-xl border border-slate-700 mb-6 animate-slide-down">
            {/* Filter inputs */}
            <div className="relative">
              <label className="block text-teal-300 mb-2">Plot Number</label>
              <div className="relative">
                <input
                  type="text"
                  name="plotNumber"
                  value={filters.plotNumber}
                  onChange={handleFilterChange}
                  placeholder="Search Plot Number"
                  className="w-full pl-10 pr-4 py-3 rounded-lg bg-slate-700 border border-slate-600 focus:border-teal-500 focus:ring-2 focus:ring-teal-500/50 text-teal-300"
                />
                <Search
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-teal-400"
                  size={20}
                />
              </div>
            </div>
            <div className="relative">
              <label className="block text-teal-300 mb-2">Plot Block</label>
              <div className="relative">
                <input
                  type="text"
                  name="block"
                  value={filters.block}
                  onChange={handleFilterChange}
                  placeholder="Search Block"
                  className="w-full pl-10 pr-4 py-3 rounded-lg bg-slate-700 border border-slate-600 focus:border-teal-500 focus:ring-2 focus:ring-teal-500/50 text-teal-300"
                />
                <Search
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-teal-400"
                  size={20}
                />
              </div>
            </div>
            <div className="relative">
              <label className="block text-teal-300 mb-2">Plot Size</label>
              <div className="relative">
                <input
                  type="text"
                  name="size"
                  value={filters.size}
                  onChange={handleFilterChange}
                  placeholder="Search Size"
                  className="w-full pl-10 pr-4 py-3 rounded-lg bg-slate-700 border border-slate-600 focus:border-teal-500 focus:ring-2 focus:ring-teal-500/50 text-teal-300"
                />
                <Search
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-teal-400"
                  size={20}
                />
              </div>
            </div>
            <div className="relative">
              <label className="block text-teal-300 mb-2">Plot Marla</label>
              <div className="relative">
                <input
                  type="text"
                  name="marla"
                  value={filters.marla}
                  onChange={handleFilterChange}
                  placeholder="Search Size"
                  className="w-full pl-10 pr-4 py-3 rounded-lg bg-slate-700 border border-slate-600 focus:border-teal-500 focus:ring-2 focus:ring-teal-500/50 text-teal-300"
                />
                <Search
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-teal-400"
                  size={20}
                />
              </div>
            </div>
            <div className="relative">
              <label className="block text-teal-300 mb-2">Plot Category</label>
              <div className="relative">
                <select
                  name="category"
                  value={filters.category}
                  onChange={handleFilterChange}
                  className="w-full pl-10 pr-4 py-3 rounded-lg bg-slate-700 border border-slate-600 focus:border-teal-500 focus:ring-2 focus:ring-teal-500/50 text-teal-300"
                >
                  <option value="">All Categories</option>
                  <option value="General">General</option>
                  <option value="Corner">Corner</option>
                  <option value="Park Face">Park Face</option>
                  <option value="Boulevard">Boulevard</option>
                </select>
                <Search
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-teal-400"
                  size={20}
                />
              </div>
            </div>
            <div className="relative">
              <label className="block text-teal-300 mb-2">
                Plot Booking Status
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="booking_status"
                  value={filters.booking_status}
                  onChange={handleFilterChange}
                  placeholder="Search Status"
                  className="w-full pl-10 pr-4 py-3 rounded-lg bg-slate-700 border border-slate-600 focus:border-teal-500 focus:ring-2 focus:ring-teal-500/50 text-teal-300"
                />
                <Search
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-teal-400"
                  size={20}
                />
              </div>
            </div>
            <div className="relative">
              <label className="block text-teal-300 mb-2">Plot Type</label>
              <div className="relative">
                <select
                  name="type"
                  value={filters.type}
                  onChange={handleFilterChange}
                  className="w-full pl-10 pr-4 py-3 rounded-lg bg-slate-700 border border-slate-600 focus:border-teal-500 focus:ring-2 focus:ring-teal-500/50 text-teal-300"
                >
                  <option value="">All Types</option>
                  <option value="Residential">Residential</option>
                  <option value="Commercial">Commercial</option>
                </select>
                <Search
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-teal-400"
                  size={20}
                />
              </div>
            </div>
          </div>
        )}
        {/* Plot List */}
        <div className="bg-slate-800/60 rounded-2xl shadow-2xl overflow-hidden border border-slate-700 backdrop-blur-sm">
          <div className="p-6 border-b border-slate-700 flex justify-between items-center">
            <h3 className="text-2xl font-semibold text-teal-300">
              Plots Overview
            </h3>
            <div className="flex items-center space-x-4">
              <span className="text-teal-400">
                Total Plots: {filteredPlots.length}
              </span>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-slate-900/50">
                <tr>
                  {[
                    "Plot Number",
                    "Block",
                    "Marla",
                    "Size Sq.ft",
                    "Type",
                    "Category",
                    "Status",
                    "Actions",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-4 text-left text-teal-300 font-semibold tracking-wider uppercase text-sm"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentPlots.map((plot, index) => (
                  <tr
                    key={plot._id}
                    className={`
                      transition-all duration-300 
                      ${index % 2 === 0 ? "bg-slate-800/60" : "bg-slate-700/60"}
                      hover:bg-teal-900/20
                    `}
                  >
                    {/* Table row content with enhanced styling */}
                    <td className="px-6 py-4 text-teal-300 font-medium">
                      {plot.plot_number}
                    </td>
                    <td className="px-6 py-4 text-teal-400">{plot.block}</td>
                    <td className="px-6 py-4 text-teal-400">
                      {plot.marla} Marla
                    </td>
                    <td className="px-6 py-4 text-teal-400">
                      {plot.size} Sq.ft
                    </td>
                    <td className="px-6 py-4">
                      <span
                        className={`
                                  px-3 py-1 rounded-full text-xs font-semibold ${
                                    plot.plot_type === "Residential"
                                      ? "bg-blue-500/20 text-blue-300"
                                      : plot.plot_type === "Commercial"
                                      ? "bg-purple-500/20 text-purple-300"
                                      : "bg-green-500/20 text-green-300"
                                  }
                                `}
                      >
                        {plot.plot_type}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <span
                        className={`px-3 py-1 rounded-full text-xs font-semibold ${
                          plot.category === "Corner"
                            ? "bg-yellow-500/20 text-yellow-300"
                            : plot.category === "Park Face"
                            ? "bg-pink-500/20 text-pink-300"
                            : plot.category === "Boulevard"
                            ? "bg-red-500/20 text-red-300"
                            : "bg-teal-500/20 text-teal-300"
                        }`}
                      >
                        {plot.category}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <span
                        className={`
                        px-3 py-1 rounded-full text-xs font-semibold
                        ${
                          plot.booking_status === "Booked"
                            ? "bg-yellow-500/20 text-yellow-400"
                            : plot.booking_status === "Available"
                            ? "bg-green-500/20 text-green-400"
                            : "bg-red-500/20 text-red-400"
                        }
                      `}
                      >
                        {plot.booking_status}
                      </span>
                    </td>
                    {activeSociety && (
                      <td className="px-6 py-4">
                        <div className="flex gap-3">
                          <button
                            onClick={() => openEditModal(plot)}
                            className="p-2 rounded-lg bg-teal-500/10 text-teal-400 hover:bg-teal-500/20 transition-colors duration-200"
                          >
                            <EditIcon size={18} />
                          </button>
                          {plot.booking_status === "Transfer" ? (
                            <button
                              className="p-2 rounded-lg bg-gray-500/10 text-gray-400 cursor-not-allowed"
                              disabled
                            >
                              <DeleteIcon size={18} />
                            </button>
                          ) : plot.booking_status === "Booked" ? (
                            <button
                              onClick={() =>
                                handleDelete(plot._id, plot.booking_status)
                              }
                              className="p-2 rounded-lg bg-yellow-500/10 text-yellow-400 hover:bg-yellow-500/20 transition-colors duration-200"
                            >
                              <ShieldX size={18} />
                            </button>
                          ) : plot.booking_status === "Available" ? (
                            <button
                              onClick={() =>
                                handleDelete(plot._id, plot.booking_status)
                              }
                              className="p-2 rounded-lg bg-red-500/10 text-red-400 hover:bg-red-500/20 transition-colors duration-200"
                            >
                              <DeleteIcon size={18} />
                            </button>
                          ) : null}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="flex justify-center items-center gap-2 sm:gap-4 mt-4 sm:mt-6 mb-4 sm:mb-5 p-2 sm:p-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`
        flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg
        transition-all duration-300
        ${
          currentPage === 1
            ? "bg-slate-600 cursor-not-allowed"
            : "bg-slate-700 text-teal-300 hover:bg-slate-600"
        }
      `}
          >
            <ChevronLeft
              className={`w-4 h-4 ${
                currentPage === 1 ? "text-slate-400" : "text-teal-300"
              }`}
            />
          </button>

          <div className="flex items-center gap-2">
            {currentPage > 3 &&
              Math.ceil(filteredPlots.length / plotsPerPage) > 5 && (
                <>
                  <button
                    onClick={() => setCurrentPage(1)}
                    className="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white transition-all duration-300"
                  >
                    1
                  </button>
                  <span className="text-teal-300">...</span>
                </>
              )}

            {renderPageNumbers()}

            {currentPage < Math.ceil(filteredPlots.length / plotsPerPage) - 2 &&
              Math.ceil(filteredPlots.length / plotsPerPage) > 5 && (
                <>
                  <span className="text-teal-300">...</span>
                  <button
                    onClick={() =>
                      setCurrentPage(
                        Math.ceil(filteredPlots.length / plotsPerPage)
                      )
                    }
                    className="flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg text-sm font-medium bg-slate-700 text-teal-300 hover:bg-slate-600 transition-all duration-300"
                  >
                    {Math.ceil(filteredPlots.length / plotsPerPage)}
                  </button>
                </>
              )}
          </div>

          <button
            onClick={handleNextPage}
            disabled={
              currentPage === Math.ceil(filteredPlots.length / plotsPerPage)
            }
            className={`
        flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg
        transition-all duration-300
        ${
          currentPage === Math.ceil(filteredPlots.length / plotsPerPage)
            ? "bg-slate-600 cursor-not-allowed"
            : "bg-slate-700 text-teal-300 hover:bg-slate-600"
        }
      `}
          >
            <ChevronRight
              className={`w-4 h-4 ${
                currentPage === Math.ceil(filteredPlots.length / plotsPerPage)
                  ? "text-slate-400"
                  : "text-teal-300"
              }`}
            />
          </button>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 top-0 left-0  bg-slate-900/80 backdrop-blur-sm flex items-center justify-center p-6 z-50 overflow-y-auto">
            <div className="bg-slate-800 rounded-xl shadow-xl w-full max-w-md transform transition-all duration-300 scale-100">
              <div className="p-6 border-b border-slate-700">
                <h3 className="text-2xl font-semibold text-teal-300">
                  Edit Plot Details
                </h3>
              </div>

              <div className="p-6 space-y-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Number
                  </label>
                  <input
                    type="text"
                    value={currentPlot.plot_number}
                    onChange={(e) => {
                      setCurrentPlot({
                        ...currentPlot,
                        plot_number: e.target.value,
                      });
                      setErrorMessage("");
                    }}
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  />
                  {errorMessage && (
                    <p className="mt-1 text-sm text-red-500">{errorMessage}</p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Block
                  </label>
                  <input
                    type="text"
                    value={currentPlot.block}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        block: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Size (Sq.ft)
                  </label>
                  <input
                    type="text"
                    value={currentPlot.size}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        size: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Marla
                  </label>
                  <input
                    type="text"
                    value={currentPlot.marla}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        marla: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Type
                  </label>
                  <select
                    value={currentPlot.plot_type}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        plot_type: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  >
                    <option value="">All Types</option>
                    <option value="Residential">Residential</option>
                    <option value="Commercial">Commercial</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-teal-300">
                    Plot Category
                  </label>
                  <select
                    value={currentPlot.category}
                    onChange={(e) =>
                      setCurrentPlot({
                        ...currentPlot,
                        category: e.target.value,
                      })
                    }
                    className="w-full px-4 py-3 rounded-lg bg-slate-700/50 border border-slate-600 focus:border-teal-400 focus:ring-1 focus:ring-teal-400 focus:outline-none text-teal-300"
                  >
                    <option value="General">General</option>
                    <option value="Corner">Corner</option>
                    <option value="Park Face">Park Face</option>
                    <option value="Boulevard">Boulevard</option>
                  </select>
                </div>
              </div>

              <div className="p-6 border-t border-slate-700 flex justify-end gap-4">
                <button
                  onClick={closeEditModal}
                  className="px-6 py-2 rounded-lg bg-slate-700 text-teal-300 hover:bg-slate-600 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleEdit(currentPlot)}
                  className="px-6 py-2 rounded-lg bg-teal-500 text-white hover:bg-teal-600 transition-colors duration-200"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlotList;
