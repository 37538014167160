// import React, { useState, useEffect, useCallback, useMemo } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Delete,
//   Search,
//   ChevronLeft,
//   ChevronRight,
//   Users,
//   Filter,
//   SearchCode,
// } from "lucide-react";
// import Swal from "sweetalert2";
// import { useSnackbar } from "notistack";
// import { fetchAllUsers, deleteUser } from "../store/slices/authSlice";
// import { fetchSocieties } from "../store/slices/societySlice";

// const UserManagement = () => {
//   const dispatch = useDispatch();
//   const { users, status } = useSelector((state) => state.auth);
//   const { societies } = useSelector((state) => state.societies);
//   const { enqueueSnackbar } = useSnackbar();

//   const [societiesFetched, setSocietiesFetched] = useState(false);
//   const [filteredUsers, setFilteredUsers] = useState(users);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [page, setPage] = useState(1);
//   const [pageSize] = useState(10);
//   const [activeFilter, setActiveFilter] = useState(null);

//   useEffect(() => {
//     if (!societiesFetched) {
//       dispatch(fetchSocieties()).then(() => setSocietiesFetched(true));
//     }
//     if (status === "idle" || (status === "failed" && users.length === 0)) {
//       dispatch(fetchAllUsers());
//     }
//   }, [dispatch, societiesFetched, status, users.length]);

//   useEffect(() => {
//     setFilteredUsers(
//       users.filter((user) => {
//         const matchesSearch = () => {
//           const searchRegex = new RegExp(searchQuery, "i");
//           return (
//             (user.username && user.username.match(searchRegex)) ||
//             (user.customerData?.name &&
//               user.customerData.name.match(searchRegex)) ||
//             (user.employeeData?.name &&
//               user.employeeData?.name.match(searchRegex)) ||
//             (user.investorData?.name &&
//               user.investorData?.name.match(searchRegex)) ||
//             (user.role && user.role.match(searchRegex)) ||
//             user.societies.some((society) => society.name.match(searchRegex)) ||
//             (user.employeeData?.active !== false &&
//               "Active".match(searchRegex)) ||
//             (user.employeeData?.active === false &&
//               "Inactive".match(searchRegex))
//           );
//         };

//         const matchesFilter = () => {
//           return (
//             !activeFilter || user.societies.some((s) => s._id === activeFilter)
//           );
//         };

//         return matchesSearch() && matchesFilter();
//       })
//     );
//   }, [searchQuery, users, activeFilter]);

//   const handleFilter = useCallback(
//     (societyId = null) => {
//       setActiveFilter(societyId);
//       if (societyId) {
//         setFilteredUsers(
//           users.filter((user) =>
//             user.societies.some((s) => s._id === societyId)
//           )
//         );
//       } else {
//         setFilteredUsers(users);
//       }
//     },
//     [users]
//   );

//   const handleDeleteUser = useCallback(
//     async (userId) => {
//       Swal.fire({
//         title: "Delete User",
//         text: "Are you sure you want to permanently delete this user?",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#10B981",
//         cancelButtonColor: "#EF4444",
//         background: "#1E293B",
//         color: "#5EEAD4",
//         confirmButtonText: "Delete",
//         cancelButtonText: "Cancel",
//         customClass: {
//           popup: "rounded-xl shadow-2xl",
//           confirmButton: "hover:bg-emerald-600 transition-colors",
//           cancelButton: "hover:bg-red-600 transition-colors",
//         },
//       }).then(async (result) => {
//         if (result.isConfirmed) {
//           try {
//             await dispatch(deleteUser(userId));
//             dispatch(fetchAllUsers());
//             enqueueSnackbar("User deleted successfully!", {
//               variant: "success",
//               anchorOrigin: {
//                 vertical: "bottom",
//                 horizontal: "right",
//               },
//             });
//           } catch (error) {
//             console.error("Error deleting user:", error);
//             enqueueSnackbar("Failed to delete user. Please try again.", {
//               variant: "error",
//               anchorOrigin: {
//                 vertical: "bottom",
//                 horizontal: "right",
//               },
//             });
//           }
//         }
//       });
//     },
//     [dispatch, enqueueSnackbar]
//   );

//   const columns = useMemo(
//     () => [
//       {
//         field: "profile_picture",
//         headerName: "Profile",
//         renderCell: (user) => (
//           <div className="relative group">
//             <div className="w-14 h-14 rounded-full border-2 border-teal-400/30 overflow-hidden transition-all duration-300 group-hover:scale-105 group-hover:border-teal-400/60">
//               {user.profile_picture ? (
//                 <img
//                   src={user.profile_picture}
//                   alt={user.username}
//                   className="w-full h-full object-cover"
//                 />
//               ) : (
//                 <div className="w-full h-full bg-teal-500/20 flex items-center justify-center">
//                   <span className="text-xl font-bold text-teal-600">
//                     {user.username.charAt(0).toUpperCase()}
//                   </span>
//                 </div>
//               )}
//             </div>
//           </div>
//         ),
//       },
//       {
//         field: "username",
//         headerName: "Username",
//         renderCell: (user) => (
//           <span className="font-medium text-slate-200">{user.username}</span>
//         ),
//       },
//       {
//         field: "name",
//         headerName: "Name",
//         renderCell: (user) => (
//           <span className="text-slate-300">
//             {user.adminData?.name ||
//               user.customerData?.name ||
//               user.employeeData?.name ||
//               user.investorData?.name ||
//               "No Name"}
//           </span>
//         ),
//       },
//       {
//         field: "role",
//         headerName: "Role",
//         renderCell: (user) => (
//           <span className="bg-teal-500/20 px-3 py-1 rounded-full text-teal-300 text-sm">
//             {user.role || "Unassigned"}
//           </span>
//         ),
//       },
//       {
//         field: "societies",
//         headerName: "Societies",
//         renderCell: (user) => {
//           const societies = user.societies;
//           return (
//             <div className="flex flex-col space-y-1">
//               {societies.length > 0 ? (
//                 societies.map((society, index) => (
//                   <span key={index} className="text-slate-400 text-sm">
//                     {society.name}
//                   </span>
//                 ))
//               ) : (
//                 <span className="text-slate-500 italic">No Societies</span>
//               )}
//             </div>
//           );
//         },
//       },
//       {
//         field: "status",
//         headerName: "Status",
//         renderCell: (user) => (
//           <span
//             className={`px-4 py-1 rounded-full text-xs font-medium ${
//               user.status === "active"
//                 ? "bg-emerald-500/20 text-emerald-400"
//                 : "bg-rose-500/20 text-rose-400"
//             }`}
//           >
//             {user.status}
//           </span>
//         ),
//       },
//     ],
//     []
//   );

//   const paginatedUsers = useMemo(() => {
//     const startIndex = (page - 1) * pageSize;
//     const endIndex = startIndex + pageSize;
//     return filteredUsers.slice(startIndex, endIndex);
//   }, [filteredUsers, page, pageSize]);

//   const totalPages = Math.ceil(filteredUsers.length / pageSize);

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 text-teal-300 p-6">
//       <div className="max-w-7xl mx-auto space-y-8">
//         {/* Header */}
//         <div className="flex items-center justify-between bg-white/5 backdrop-blur-lg border border-white/10 rounded-2xl p-6 shadow-2xl">
//           <div className="space-y-2">
//             <h1 className="text-4xl font-extrabold bg-gradient-to-r from-teal-300 to-teal-400 glow bg-clip-text text-transparent">
//               User Management
//             </h1>
//             <p className="text-slate-400 text-sm">
//               Manage {filteredUsers.length} user accounts with precision
//             </p>
//           </div>
//           <Users className="w-12 h-12 text-teal-300 opacity-70" />
//         </div>

//         {/* Filters & Search Container */}
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//           {/* Society Filters */}
//           <div className="bg-white/5 backdrop-blur-lg border border-white/10 rounded-2xl p-6 space-y-4 shadow-xl">
//             <div className="flex items-center space-x-3 mb-4">
//               <Filter className="w-6 h-6 text-teal-300" />
//               <h3 className="text-xl font-semibold text-teal-300">Filters</h3>
//             </div>
//             <div className="flex flex-wrap gap-3">
//               <button
//                 onClick={() => handleFilter()}
//                 className={`px-4 py-2 rounded-xl text-sm font-medium transition-all duration-300 ${
//                   activeFilter === null
//                     ? "bg-gradient-to-r from-teal-400 to-teal-400 text-slate-900"
//                     : "bg-white/10 text-teal-300 hover:bg-white/20"
//                 }`}
//               >
//                 All Users
//               </button>
//               {societies.map((society) => (
//                 <button
//                   key={society._id}
//                   onClick={() => handleFilter(society._id)}
//                   className={`px-4 py-2 rounded-xl text-sm font-medium transition-all duration-300 ${
//                     activeFilter === society._id
//                       ? "bg-gradient-to-r from-teal-400 to-teal-400 text-slate-900"
//                       : "bg-white/10 text-teal-300 hover:bg-white/20"
//                   }`}
//                 >
//                   {society.name}
//                 </button>
//               ))}
//             </div>
//           </div>

//           {/* Search */}
//           <div className="bg-white/5 backdrop-blur-lg border border-white/10 rounded-2xl p-6 space-y-4 shadow-xl">
//             <div className="flex items-center space-x-3 mb-4">
//               <SearchCode className="w-6 h-6 text-teal-300" />
//               <h3 className="text-xl font-semibold text-teal-300">Search</h3>
//             </div>
//             <div className="relative">
//               <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-teal-300/50 w-6 h-6" />
//               <input
//                 type="text"
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 placeholder="Search users by username, role, name or society..."
//                 className="w-full pl-14 pr-6 py-3 bg-white/10 rounded-xl border border-white/20 focus:border-teal-300/50 focus:ring-2 focus:ring-teal-300/50 transition-all duration-300 text-teal-300 placeholder-teal-300/50"
//               />
//             </div>
//           </div>
//         </div>

//         {/* User Table */}
//         <div className="bg-white/5 backdrop-blur-lg border border-white/10 rounded-2xl shadow-2xl overflow-hidden">
//           <div className="overflow-x-auto">
//             <table className="w-full">
//               <thead className="bg-white/10 border-b border-white/20">
//                 <tr>
//                   {columns.map((column) => (
//                     <th
//                       key={column.field}
//                       className="px-6 py-4 text-left text-sm font-semibold text-teal-300/70 uppercase tracking-wider"
//                     >
//                       {column.headerName}
//                     </th>
//                   ))}
//                   <th className="px-6 py-4 text-left text-sm font-semibold text-teal-300/70 uppercase tracking-wider">
//                     Actions
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {paginatedUsers.map((user, index) => (
//                   <tr
//                     key={user._id}
//                     className={`transition-colors duration-300 ${
//                       index % 2 === 0 ? "bg-white/5" : "bg-white/10"
//                     } hover:bg-white/20`}
//                   >
//                     {columns.map((column) => (
//                       <td key={column.field} className="px-6 py-4">
//                         {column.renderCell
//                           ? column.renderCell(user)
//                           : user[column.field]}
//                       </td>
//                     ))}
//                     <td className="px-6 py-4">
//                       <button
//                         onClick={() => handleDeleteUser(user._id)}
//                         className="p-2.5 rounded-lg bg-rose-500/20 text-rose-400 transition-all duration-300 hover:bg-rose-500/40 hover:scale-110"
//                       >
//                         <Delete className="h-5 w-5" />
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           {/* Pagination */}
//           <div className="px-6 py-4 bg-white/10 flex items-center justify-between">
//             <span className="text-sm text-teal-300/70">
//               Showing {(page - 1) * pageSize + 1} to{" "}
//               {Math.min(page * pageSize, filteredUsers.length)} of{" "}
//               {filteredUsers.length} users
//             </span>
//             <div className="flex items-center space-x-4">
//               <button
//                 onClick={() => setPage(Math.max(1, page - 1))}
//                 disabled={page === 1}
//                 className="p-2 rounded-lg bg-white/10 text-teal-300 disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-300 hover:bg-white/20"
//               >
//                 <ChevronLeft className="h-6 w-6" />
//               </button>
//               <span className="text-sm font-medium text-teal-300">
//                 Page {page} of {totalPages}
//               </span>
//               <button
//                 onClick={() => setPage(Math.min(totalPages, page + 1))}
//                 disabled={page === totalPages}
//                 className="p-2 rounded-lg bg-white/10 text-teal-300 disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-300 hover:bg-white/20"
//               >
//                 <ChevronRight className="h-6 w-6" />
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserManagement;
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Delete,
  Search,
  ChevronLeft,
  ChevronRight,
  Users,
  SearchCode,
  MapPin,
} from "lucide-react";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";
import { fetchAllUsers, deleteUser } from "../store/slices/authSlice";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";

const UserManagement = () => {
  const dispatch = useDispatch();
  const { users, status, activeUser } = useSelector((state) => state.auth);
  const { activeSociety } = useSidebarContext();
  const { enqueueSnackbar } = useSnackbar();

  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);

  // Fetch users on component mount
  useEffect(() => {
    if (status === "idle" || (status === "failed" && users.length === 0)) {
      dispatch(fetchAllUsers());
    }
  }, [dispatch, status, users.length]);

  useEffect(() => {
    if (!activeSociety) return;

    setFilteredUsers(
      users.filter((user) => {
        const belongsToActiveSociety = user.societies.some(
          (s) => s._id === activeSociety._id
        );

        const matchesSearch = () => {
          const searchRegex = new RegExp(searchQuery, "i");
          return (
            (user.username && user.username.match(searchRegex)) ||
            (user.customerData?.name &&
              user.customerData.name.match(searchRegex)) ||
            (user.employeeData?.name &&
              user.employeeData?.name.match(searchRegex)) ||
            (user.investorData?.name &&
              user.investorData?.name.match(searchRegex)) ||
            (user.adminData?.name && user.adminData.name.match(searchRegex)) ||
            (user.role && user.role.match(searchRegex))
          );
        };

        return belongsToActiveSociety && (!searchQuery || matchesSearch());
      })
    );
  }, [searchQuery, users, activeSociety]);

  const handleDeleteUser = useCallback(
    async (userId) => {
      Swal.fire({
        title: "Delete User",
        text: "Are you sure you want to permanently delete this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#10B981",
        cancelButtonColor: "#EF4444",
        background: "#1E293B",
        color: "#5EEAD4",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        customClass: {
          popup: "rounded-xl shadow-2xl",
          confirmButton: "hover:bg-emerald-600 transition-colors",
          cancelButton: "hover:bg-red-600 transition-colors",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await dispatch(deleteUser(userId));
            dispatch(fetchAllUsers());
            enqueueSnackbar("User deleted successfully!", {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          } catch (error) {
            console.error("Error deleting user:", error);
            enqueueSnackbar("Failed to delete user. Please try again.", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          }
        }
      });
    },
    [dispatch, enqueueSnackbar]
  );

  const columns = useMemo(
    () => [
      {
        field: "profile_picture",
        headerName: "Profile",
        renderCell: (user) => (
          <div className="relative group">
            <div className="w-14 h-14 rounded-full border-2 border-teal-400/30 overflow-hidden transition-all duration-300 group-hover:scale-105 group-hover:border-teal-400/60">
              {user.profile_picture ? (
                <img
                  src={user.profile_picture}
                  alt={user.username}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-teal-500/20 flex items-center justify-center">
                  <span className="text-xl font-bold text-teal-600">
                    {user.username.charAt(0).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        field: "username",
        headerName: "Username",
        renderCell: (user) => (
          <span className="font-medium text-slate-200">{user.username}</span>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        renderCell: (user) => (
          <span className="text-slate-300">
            {user.adminData?.name ||
              user.customerData?.name ||
              user.employeeData?.name ||
              user.investorData?.name ||
              "No Name"}
          </span>
        ),
      },
      {
        field: "role",
        headerName: "Role",
        renderCell: (user) => (
          <span className="bg-teal-500/20 px-3 py-1 rounded-full text-teal-300 text-sm">
            {user.role || "Unassigned"}
          </span>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        renderCell: (user) => (
          <span
            className={`px-4 py-1 rounded-full text-xs font-medium ${
              user.status === "active"
                ? "bg-emerald-500/20 text-emerald-400"
                : "bg-rose-500/20 text-rose-400"
            }`}
          >
            {user.status}
          </span>
        ),
      },
    ],
    []
  );

  const paginatedUsers = useMemo(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredUsers.slice(startIndex, endIndex);
  }, [filteredUsers, page, pageSize]);

  const totalPages = Math.ceil(filteredUsers.length / pageSize);

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] text-teal-300 p-6">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header */}
        <div className="flex items-center justify-between bg-white/5 backdrop-blur-lg border border-white/10 rounded-2xl p-6 shadow-2xl">
          <div className="space-y-2">
            <h1 className="text-4xl font-extrabold bg-gradient-to-r from-teal-300 to-teal-400 glow bg-clip-text text-transparent">
              <span className=" inline-flex items-center px-6 py-1 rounded-full bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 text-teal-600 border border-teal-300/50 shadow-md">
                <MapPin className="mr-2 w-5 h-5 text-teal-500" />
                {activeSociety?.name || "Loading..."}
              </span>
              <span className="m-2">User Management</span>
            </h1>
            <p className="text-slate-400 text-sm ml-1">
              Managing users for {activeSociety?.name || "Loading..."} -{" "}
              {filteredUsers.length} users
            </p>
          </div>
          <Users className="w-12 h-12 text-teal-300 opacity-70" />
        </div>

        {/* Search */}
        <div className="bg-white/5 backdrop-blur-lg border border-white/10 rounded-2xl p-6 space-y-4 shadow-xl">
          <div className="flex items-center space-x-3 mb-4">
            <SearchCode className="w-6 h-6 text-teal-300" />
            <h3 className="text-xl font-semibold text-teal-300">Search</h3>
          </div>
          <div className="relative">
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-teal-300/50 w-6 h-6" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search users by username, role, or name..."
              className="w-full pl-14 pr-6 py-3 bg-white/10 rounded-xl border border-white/20 focus:border-teal-300/50 focus:ring-2 focus:ring-teal-300/50 transition-all duration-300 text-teal-300 placeholder-teal-300/50"
            />
          </div>
        </div>

        {/* User Table */}
        <div className="bg-white/5 backdrop-blur-lg border border-white/10 rounded-2xl shadow-2xl overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-white/10 border-b border-white/20">
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.field}
                      className="px-6 py-4 text-left text-sm font-semibold text-teal-300/70 uppercase tracking-wider"
                    >
                      {column.headerName}
                    </th>
                  ))}
                  <th className="px-6 py-4 text-left text-sm font-semibold text-teal-300/70 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedUsers.map((user, index) => (
                  <tr
                    key={user._id}
                    className={`transition-colors duration-300 ${
                      index % 2 === 0 ? "bg-white/5" : "bg-white/10"
                    } hover:bg-white/20`}
                  >
                    {columns.map((column) => (
                      <td key={column.field} className="px-6 py-4">
                        {column.renderCell
                          ? column.renderCell(user)
                          : user[column.field]}
                      </td>
                    ))}
                    <td className="px-6 py-4">
                      {activeUser?.role === "admin" && user.role === "admin" ? (
                        <button
                          disabled
                          className="p-2.5 rounded-lg bg-rose-500/10 text-rose-400/50 cursor-not-allowed"
                        >
                          <Delete className="h-5 w-5" />
                        </button>
                      ) : (
                        <button
                          onClick={() => handleDeleteUser(user._id)}
                          className="p-2.5 rounded-lg bg-rose-500/20 text-rose-400 transition-all duration-300 hover:bg-rose-500/40 hover:scale-110"
                        >
                          <Delete className="h-5 w-5" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="px-6 py-4 bg-white/10 flex items-center justify-between">
            <span className="text-sm text-teal-300/70">
              Showing {(page - 1) * pageSize + 1} to{" "}
              {Math.min(page * pageSize, filteredUsers.length)} of{" "}
              {filteredUsers.length} users
            </span>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setPage(Math.max(1, page - 1))}
                disabled={page === 1}
                className="p-2 rounded-lg bg-white/10 text-teal-300 disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-300 hover:bg-white/20"
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
              <span className="text-sm font-medium text-teal-300">
                Page {page} of {totalPages}
              </span>
              <button
                onClick={() => setPage(Math.min(totalPages, page + 1))}
                disabled={page === totalPages}
                className="p-2 rounded-lg bg-white/10 text-teal-300 disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-300 hover:bg-white/20"
              >
                <ChevronRight className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
