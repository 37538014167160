




import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle, AlertCircle, X, Search, ChevronRight, ChevronLeft, DollarSign, Calendar, MapPin } from "lucide-react";
import {
  fetchOverdueInstallments,
  payInstallmentAsync,
} from "../store/slices/installmentSlice";
import { fetchAllUsers } from "../store/slices/authSlice";
import { getAllBookings } from "../store/slices/bookingSlice";
import { addNotification } from "../store/slices/notificationSlice";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";

const OverduePayments = () => {
  const dispatch = useDispatch();
  const [reminderSent, setReminderSent] = useState({});
  const [viewDetails, setViewDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const { activeSociety } = useSidebarContext();
  const {
    overdueInstallments = [],
    status: installmentsStatus,
    error: installmentsError,
  } = useSelector((state) => state.installments || {});
  const {
    users = [],
    status: userStatus,
    error: userError,
  } = useSelector((state) => state.auth || {});
  const {
    bookings = [],
    loading: bookingsLoading,
    error: bookingsError,
  } = useSelector((state) => state.booking || {});

  useEffect(() => {
    dispatch(fetchOverdueInstallments());
    dispatch(getAllBookings());
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const filteredInstallments = useMemo(() => {
    if (!activeSociety || !activeSociety.users) return [];
    return overdueInstallments.filter((installment) => {
      const customer = activeSociety.users.find(
        (user) =>
          user._id === installment.customer_id && user.role === "Customer"
      );
      return Boolean(customer);
    });
  }, [overdueInstallments, activeSociety]);

  const groupInstallmentsByPlot = useMemo(() => {
    const plotGroups = {};
    filteredInstallments.forEach((installment) => {
      const plotNumber = installment.plot_id?.plot_number;
      if (!plotNumber) return;
      if (!plotGroups[plotNumber]) plotGroups[plotNumber] = [];
      plotGroups[plotNumber].push(installment);
    });
    return plotGroups;
  }, [filteredInstallments]);

  // Search and filter functionality
  const searchResults = useMemo(() => {
    if (!searchTerm) return [];
    return users
      .filter(
        (user) =>
          user.customerData?.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
          filteredInstallments.some((inst) => inst.customer_id === user._id)
      )
      .slice(0, 5);
  }, [searchTerm, users, filteredInstallments]);

  // Pagination logic
  const paginatedData = useMemo(() => {
    const entries = Object.entries(groupInstallmentsByPlot);
    const startIndex = (currentPage - 1) * itemsPerPage;
    return entries.slice(startIndex, startIndex + itemsPerPage);
  }, [groupInstallmentsByPlot, currentPage]);

  const totalPages = Math.ceil(Object.keys(groupInstallmentsByPlot).length / itemsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium transition-all duration-300 ${
            currentPage === i
            ? "bg-teal-500 text-white shadow-lg scale-110"
            : "bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white"
        }`}
        >
          {i}
        </button>
       
      );
    }
    return pages;
  };

  const handleSendReminder = (installmentId, customerId, plotId) => {
    dispatch(
      addNotification({
        userId: customerId,
        message: `Your installment for Plot #${plotId} is overdue. Please make the payment.`,
        type: "Overdue",
        recipientRole: "Customer",
      })
    );

    dispatch(
      addNotification({
        userId: null,
        message: `Installment for Plot #${plotId} is overdue. Please follow up with the customer.`,
        type: "Overdue",
        recipientRole: "Admin",
      })
    );

    setReminderSent((prev) => ({
      ...prev,
      [installmentId]: Date.now(),
    }));
  };

  const getInstallmentRowColor = (installment) => {
    const overdueMonths = Math.ceil(
      (Date.now() - new Date(installment.due_date).getTime()) /
        (1000 * 60 * 60 * 24 * 30)
    );

    if (overdueMonths === 1) return "bg-yellow-400/30";
  if (overdueMonths === 2) return "bg-orange-400/30";
  return "bg-red-400/30";
  };

  if (installmentsStatus === "loading" || userStatus === "loading" || bookingsLoading) {
    return (
      <div className="flex items-center justify-center h-64 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="flex items-center space-x-2 text-teal-300">
          <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300"></div>
          <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300 delay-75"></div>
          <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300 delay-150"></div>
        </div>
      </div>
    );
  }

  if (installmentsStatus === "failed" || userStatus === "failed" || bookingsError) {
    return (
      <div className="flex items-center justify-center h-64 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="flex items-center space-x-2 text-red-400">
          <AlertCircle className="w-5 h-5" />
          <span>Error: {installmentsError || userError || bookingsError || "Unknown error"}</span>
        </div>
      </div>
    );
  }

  const isReminderDisabled = (installmentId) => {
    const reminderTimestamp = reminderSent[installmentId];
    return reminderTimestamp && Date.now() - reminderTimestamp < 86400000;
  };

  const LoadingSpinner = () => (
    <div className="flex items-center justify-center h-64 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
      <div className="flex space-x-2">
        <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300"></div>
        <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300 delay-75"></div>
        <div className="w-3 h-3 rounded-full animate-pulse bg-teal-300 delay-150"></div>
      </div>
    </div>
  );

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-64 space-y-4">
      <div className="relative">
        <CheckCircle className="w-16 h-16 text-teal-300 animate-pulse" />
        <div className="absolute inset-0 bg-teal-300/20 blur-xl rounded-full"></div>
      </div>
      <span className="text-teal-300 text-xl font-medium">No overdue installments</span>
    </div>
  );

  return (
    <div className="p-8 m-8 rounded-2xl bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 shadow-2xl border border-slate-700/30 backdrop-blur-sm">
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-6 mb-8">
        <div className="space-y-2">
          <h2 className="text-3xl font-bold text-teal-300 flex items-center gap-3">
            <div className="h-8 w-1 bg-teal-400/60 rounded-full"></div>
            Overdue Payments
            <span className="text-lg font-normal text-teal-300/70">
              {activeSociety?.name || "No Active Society"}
            </span>
          </h2>
          <p className="text-teal-300/70 ml-11">
            {filteredInstallments.length} pending payments
          </p>
        </div>

        <div className="relative w-full lg:w-auto">
          <div className="flex items-center space-x-3 bg-slate-700/30 rounded-xl p-3 backdrop-blur-sm border border-slate-600/30 shadow-lg">
            <Search className="w-5 h-5 text-teal-300/70" />
            <input
              type="text"
              placeholder="Search by customer name..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setShowDropdown(true);
              }}
              className="bg-transparent w-full lg:w-64 text-teal-300 placeholder-teal-300/50 focus:outline-none"
            />
          </div>

          {/* Enhanced Dropdown */}
          {showDropdown && searchResults.length > 0 && (
            <div className="absolute z-10 w-full mt-2 bg-slate-800/95 rounded-xl shadow-2xl border border-slate-700/50 backdrop-blur-md overflow-hidden">
              {searchResults.map((user) => (
                <div
                  key={user._id}
                  className="p-4 hover:bg-slate-700/50 cursor-pointer transition-all duration-300"
                  onClick={() => {
                    setSearchTerm(user.customerData.name);
                    setShowDropdown(false);
                  }}
                >
                  <div className="flex items-center space-x-4">
                    <div className="relative w-10 h-10">
                      <div className="absolute inset-0 bg-teal-400/20 rounded-full blur-md"></div>
                      <img
                        src={user.profile_picture || "/default-profile-pic.png"}
                        alt={user.customerData.name}
                        className="w-10 h-10 rounded-full object-cover relative z-10"
                      />
                    </div>
                    <div>
                      <div className="text-teal-300 font-medium">
                        {user.customerData.name}
                      </div>
                      <div className="text-teal-300/60 text-sm">
                        {user.customerData.contactInfo?.phone || "No phone"}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="overflow-hidden rounded-xl border border-slate-700/30 backdrop-blur-sm">
        {!filteredInstallments.length ? (
          <EmptyState />
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-slate-800/50">
                  <th className="p-4 text-left text-teal-300 font-medium">Customer</th>
                  <th className="p-4 text-left text-teal-300 font-medium">Plot Details</th>
                  <th className="p-4 text-left text-teal-300 font-medium">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map(([plotNumber, installments]) => {
                  const customer = users.find(
                    (user) => user._id === installments[0].customer_id && user.role === "Customer"
                  );
                  return (
                    <tr
                      key={plotNumber}
                      className={`border-b border-slate-700/30 transition-colors duration-300 hover:bg-slate-700/30 ${getInstallmentRowColor(
                        installments[0]
                      )}`}
                    >
                      <td className="p-4">
                        <div className="flex items-center space-x-4">
                          <div className="relative">
                            <div className="absolute inset-0 bg-teal-400/20 rounded-full blur-md"></div>
                            <img
                              src={customer?.profile_picture || "/default-profile-pic.png"}
                              alt={customer?.customerData?.name || "Customer"}
                              className="w-12 h-12 rounded-full object-cover relative z-10"
                            />
                          </div>
                          <div>
                            <div className="font-medium text-teal-300">
                              {customer?.customerData?.name || "Unknown"}
                            </div>
                            <div className="text-sm text-teal-300/70">
                              {customer?.customerData?.contactInfo?.phone || "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="p-4">
                        <div className="text-teal-300 space-y-1">
                          <div className="text-lg font-semibold flex items-center gap-2">
                            <MapPin className="w-4 h-4" />
                            Plot #{plotNumber}
                          </div>
                          <div className="text-sm text-teal-300/70">
                            {installments.length} overdue payment(s)
                          </div>
                        </div>
                      </td>
                      <td className="p-4">
                        <button
                          onClick={() => setViewDetails(installments)}
                          className="px-6 py-2.5 rounded-xl bg-teal-500/10 hover:bg-teal-500/20 
                            transition-all duration-300 
                            focus:outline-none focus:ring-2 focus:ring-teal-500/50
                            text-teal-300 hover:text-teal-200
                            border border-teal-500/20 hover:border-teal-500/30
                            shadow-lg shadow-teal-900/20 hover:shadow-teal-900/30
                            transform hover:-translate-y-0.5"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {Object.keys(groupInstallmentsByPlot).length > itemsPerPage && (
              <div className="flex justify-center items-center gap-2 sm:gap-4 mt-4 sm:mt-6 mb-4 sm:mb-5 p-2 sm:p-4">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className={`
                    flex items-center justify-center h-5 w-5 sm:h-7 sm:w-7 rounded-lg
                    transition-all duration-300
                    ${
                      currentPage === 1
                        ? "bg-slate-600 cursor-not-allowed"
                        : "bg-slate-700 text-teal-300 hover:bg-slate-600"
                    }
                  `}
                >
                  <ChevronLeft
                    className={`w-4 h-4 ${
                      currentPage === 1 ? "text-slate-400" : "text-teal-300"
                    }`}
                  />
                </button>

                <div className="flex items-center gap-2">
                  {currentPage > 3 && totalPages > 5 && (
                    <>
                      <button
                        onClick={() => setCurrentPage(1)}
                        className="flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg text-sm font-medium bg-slate-700 text-teal-300 hover:bg-slate-600 transition-all duration-300"
                      >
                        1
                      </button>
                      <span className="text-teal-300">...</span>
                    </>
                  )}

                  {renderPageNumbers()}

                  {currentPage < totalPages - 2 && totalPages > 5 && (
                    <>
                      <span className="text-teal-300">...</span>
                      <button
                        onClick={() => setCurrentPage(totalPages)}
                        className="flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg text-sm font-medium bg-slate-700 text-teal-300 hover:bg-slate-600 transition-all duration-300"
                      >
                        {totalPages}
                      </button>
                    </>
                  )}
                </div>

                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={`
                    flex items-center justify-center h-5 w-5 sm:h-7 sm:w-7 rounded-lg
                    transition-all duration-300
                    ${
                      currentPage === totalPages
                        ? "bg-slate-600 cursor-not-allowed"
                        : "bg-slate-700 text-teal-300 hover:bg-slate-600"
                    }
                  `}
                >
                  <ChevronRight
                    className={`w-4 h-4 ${
                      currentPage === totalPages
                        ? "text-slate-400"
                        : "text-teal-300"
                    }`}
                  />
                </button>
              </div>
            )}
          
        
          </div>
        )}

        
      </div>
      

      {/* Enhanced Modal */}
      {viewDetails && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex justify-center items-center z-50 p-4">
          <div className="bg-gradient-to-br from-slate-800/95 via-slate-900/95 to-slate-800/95 rounded-3xl shadow-2xl w-full max-w-4xl overflow-hidden border border-slate-700/50">
            <div className="p-6 flex justify-between items-center border-b border-slate-700/30">
              <h3 className="text-2xl font-bold text-teal-300">Payment Details</h3>
              <button
                onClick={() => setViewDetails(null)}
                className="p-2 hover:bg-slate-700/30 rounded-xl transition-all duration-300"
              >
                <X className="w-6 h-6 text-teal-300" />
              </button>
            </div>
            
            <div className="p-6 max-h-[70vh] overflow-y-auto">
              {viewDetails.map((installment, idx) => (
                <div
                  key={installment._id}
                  // className="mb-4 p-4 rounded-xl bg-slate-800/50 border border-slate-700/30 hover:bg-slate-700/50 transition-all duration-300"
                  className={`py-4 px-6 group transition-all duration-200 hover:bg-slate-700/20 
                                       ${idx !== viewDetails.length - 1 ? 'border-b border-slate-700/30' : ''}
                                       ${getInstallmentRowColor(installment)}`}
                >
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="flex items-center space-x-3">
                      <Calendar className="w-5 h-5 text-teal-300/70" />
                      <div>
                        <div className="text-sm text-teal-300/70">Due Date</div>
                        <div className="text-teal-300 font-medium">
                          {new Date(installment.due_date).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex items-center space-x-3">
                      <DollarSign className="w-5 h-5 text-teal-300/70" />
                      <div>
                        <div className="text-sm text-teal-300/70">Amount</div>
                        <div className="text-teal-300 font-medium">
                          Rs. {installment.amount.toLocaleString()}
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex justify-end">
                      <button
                        onClick={() => handleSendReminder(
                          installment._id,
                          installment.customer_id,
                          installment.plot_id?.plot_number
                        )}
                        disabled={isReminderDisabled(installment._id)}
                        className="px-6 py-2.5 rounded-xl bg-teal-500/10 hover:bg-teal-500/20 
                          disabled:opacity-50 disabled:cursor-not-allowed
                          transition-all duration-300
                          text-teal-300 hover:text-teal-200
                          border border-teal-500/20 hover:border-teal-500/30
                          shadow-lg shadow-teal-900/20 hover:shadow-teal-900/30
                          transform hover:-translate-y-0.5"
                      >
                        Send Reminder
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverduePayments;

