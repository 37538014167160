// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { fetchAggregatedData } from "../../api";

// export const fetchAggregation = createAsyncThunk(
//   "aggregation/fetchAggregation",
//   async (societyId, { rejectWithValue }) => {
//     // Accept societyId as a parameter
//     try {
//       console.log(societyId);
//       const response = await fetchAggregatedData(societyId);
//       console.log(response);
//       return response.data; // Return only the data object
//     } catch (error) {
//       return rejectWithValue(
//         error.response ? error.response.data.message : error.message
//       );
//     }
//   }
// );

// const aggregationSlice = createSlice({
//   name: "aggregation",
//   initialState: {
//     data: {
//       societyName: "",
//       totalSoldPlots: 0,
//       totalBookedPlots: 0,
//       totalCustomers: 0,
//       totalUser: 0,
//       totalPlots: 0,
//       totalIncome: 0,
//       totalExpense: 0,
//     },
//     loading: false,
//     error: null,
//   },

//   reducers: {
//     resetAggregation: (state) => {
//       state.data = {
//         totalSoldPlots: 0,
//         totalBookedPlots: 0,
//         totalCustomers: 0,
//         totalIncome: 0,
//         totalExpense: 0,
//       };
//       state.loading = false;
//       state.error = null;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchAggregation.pending, (state) => {
//         console.log("Fetching aggregation data...");
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(fetchAggregation.fulfilled, (state, action) => {
//         console.log("Aggregation data fetched successfully:", action.payload);
//         state.data = action.payload.data;
//         state.loading = false;
//       })
//       .addCase(fetchAggregation.rejected, (state, action) => {
//         console.error("Failed to fetch aggregation data:", action.payload);
//         state.loading = false;
//         state.error = action.payload || "Failed to fetch aggregated data";
//       });
//   },
// });

// export const { resetAggregation } = aggregationSlice.actions;

// export default aggregationSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAggregatedData } from "../../api";

// Fetch Aggregated Data
export const fetchAggregation = createAsyncThunk(
  "aggregation/fetchAggregation",
  async (societyId, { rejectWithValue }) => {
    try {
      const response = await fetchAggregatedData(societyId);
      return response; // Directly returning the data
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data.message : error.message
      );
    }
  }
);

// const aggregationSlice = createSlice({
//   name: "aggregation",
//   initialState: {
//     data: {
//       societyName: "",
//       totalSoldPlots: 0,
//       totalBookedPlots: 0,
//       totalCustomers: 0,
//       totalUser: 0,
//       totalPlots: 0,
//       totalIncome: 0,
//       totalExpense: 0,
//     },
//     loading: false,
//     error: null,
//   },
//   reducers: {
//     resetAggregation: (state) => {
//       state.data = {
//         totalSoldPlots: 0,
//         totalBookedPlots: 0,
//         totalCustomers: 0,
//         totalIncome: 0,
//         totalExpense: 0,
//       };
//       state.loading = false;
//       state.error = null;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchAggregation.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(fetchAggregation.fulfilled, (state, action) => {
//         state.data = action.payload;
//         state.loading = false;
//       })
//       .addCase(fetchAggregation.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload || "Failed to fetch aggregated data";
//       });
//   },
// });

// export const { resetAggregation } = aggregationSlice.actions;

// export default aggregationSlice.reducer;


const aggregationSlice = createSlice({
  name: "aggregation",
  initialState: {
    data: {},
    loading: false,
    error: null,
    hasFetched: false, // Track fetch status
  },
  reducers: {
    resetAggregation: (state) => {
      state.data = {};
      state.loading = false;
      state.error = null;
      state.hasFetched = false; // Reset the fetch flag
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAggregation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAggregation.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.hasFetched = true; // Set to true after successful fetch
      })
      .addCase(fetchAggregation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch aggregated data";
      });
  },
});

export const { resetAggregation } = aggregationSlice.actions;
export default aggregationSlice.reducer;

