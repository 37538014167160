import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchScholarshipsByStatus } from "../store/slices/scholarshipSlice";
import { useSnackbar } from "notistack";
import { Award } from "lucide-react";

const ApprovedScholarships = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { scholarships, loading, error } = useSelector(
    (state) => state.scholarship
  );

  useEffect(() => {
    const status = "Approved";
    dispatch(fetchScholarshipsByStatus(status))
      .unwrap()
      .then(() => {
        enqueueSnackbar("Scholarships loaded successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          style: { backgroundColor: "#0d9488", color: "white" },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message || "Failed to load scholarships", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          style: { backgroundColor: "#dc2626", color: "white" },
        });
      });
  }, [dispatch, enqueueSnackbar]);

  const formatPKR = (amount) => {
    return new Intl.NumberFormat("en-PK", {
      style: "currency",
      currency: "PKR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="flex flex-col items-center gap-4">
          <div className="w-8 h-8 border-4 border-teal-300 border-t-transparent rounded-full animate-spin"></div>
          <p className="text-teal-300 animate-pulse">Loading Scholarships...</p>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
        <div className="bg-red-500/10 px-6 py-4 rounded-lg border border-red-500/20">
          <p className="text-red-400">
            Error: {error?.message || "An error occurred"}
          </p>
        </div>
      </div>
    );

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800">
      <div className="container mx-auto p-6">
        {/* Header */}
        <div className="flex items-center gap-4 mb-8">
          <div className="p-2 bg-teal-400/10 rounded-lg">
            <Award className="w-8 h-8 text-teal-300" />
          </div>
          <h1 className="text-4xl font-bold text-teal-300 tracking-tight">
            Approved Scholarships
          </h1>
        </div>

        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-slate-800/50 border border-slate-700 rounded-xl p-6 backdrop-blur">
            <h3 className="text-teal-300 text-sm font-medium mb-2">
              Total Scholarships
            </h3>
            <p className="text-2xl font-bold text-slate-200">
              {scholarships.length}
            </p>
          </div>

          <div className="bg-slate-800/50 border border-slate-700 rounded-xl p-6 backdrop-blur">
            <h3 className="text-teal-300 text-sm font-medium mb-2">
              Total Amount
            </h3>
            <p className="text-2xl font-bold text-slate-200">
              {formatPKR(scholarships.reduce((sum, s) => sum + s.amount, 0))}
            </p>
          </div>

          <div className="bg-slate-800/50 border border-slate-700 rounded-xl p-6 backdrop-blur">
            <h3 className="text-teal-300 text-sm font-medium mb-2">
              Average Amount
            </h3>
            <p className="text-2xl font-bold text-slate-200">
              {formatPKR(
                scholarships.reduce((sum, s) => sum + s.amount, 0) /
                  scholarships.length
              )}
            </p>
          </div>
        </div>

        {/* Table */}
        <div className="relative overflow-hidden rounded-xl border border-slate-700 bg-slate-800/50 backdrop-blur shadow-2xl">
          <div className="absolute inset-0 bg-gradient-to-br from-teal-500/10 via-transparent to-transparent"></div>
          <div className="relative overflow-x-auto">
            <table className="w-full border-collapse text-sm">
              <thead>
                <tr className="border-b border-slate-700">
                  <th className="px-6 py-5 text-left font-medium text-teal-300">
                    Name
                  </th>
                  <th className="px-6 py-5 text-left font-medium text-teal-300">
                    Email
                  </th>
                  <th className="px-6 py-5 text-left font-medium text-teal-300">
                    Amount
                  </th>
                  <th className="px-6 py-5 text-left font-medium text-teal-300">
                    Status
                  </th>
                  <th className="px-6 py-5 text-left font-medium text-teal-300">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-700">
                {scholarships.map((scholarship) => (
                  <tr
                    key={scholarship._id}
                    className="group transition-colors hover:bg-slate-700/50"
                  >
                    <td className="px-6 py-4 text-slate-300 whitespace-nowrap font-medium">
                      {scholarship.name}
                    </td>
                    <td className="px-6 py-4 text-slate-300 whitespace-nowrap">
                      {scholarship.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="font-medium text-teal-300">
                        {formatPKR(scholarship.amount)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="inline-flex px-3 py-1 rounded-full text-xs font-medium bg-teal-400/10 text-teal-300 border border-teal-400/20">
                        {scholarship.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-slate-300 whitespace-nowrap">
                      {new Date(scholarship.date).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Empty State */}
        {scholarships.length === 0 && (
          <div className="text-center py-12">
            <p className="text-slate-400">No scholarships found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovedScholarships;
