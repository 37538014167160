import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TransactionDatePicker = ({ field, form }) => {
  const handleDateChange = (date) => {
    if (date && !isNaN(date.getTime())) {
      form.setFieldValue(field.name, date);
    } else {
      form.setFieldValue(field.name, null); // Clear the field if the date is invalid
    }
  };

  const handleManualInput = (e) => {
    const inputValue = e?.target?.value;

    if (inputValue) {
      // Parse manually entered date and validate
      const parsedDate = new Date(inputValue.split("/").reverse().join("/")); // Assuming DD/MM/YYYY format
      if (!isNaN(parsedDate.getTime())) {
        form.setFieldValue(field.name, parsedDate);
      } else {
        form.setFieldValue(field.name, null); // Clear if invalid
      }
    } else {
      form.setFieldValue(field.name, null); // Clear if input is empty or undefined
    }
  };

  return (
    <DatePicker
      selected={field.value ? new Date(field.value) : null}
      onChange={handleDateChange}
      onChangeRaw={handleManualInput}
      dateFormat="dd/MM/yyyy"
      className="w-full px-4 py-3 bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl focus:ring-2 focus:ring-teal-500/50 focus:border-teal-400/30 text-gray-100 placeholder-gray-400 transition-all duration-300 shadow-lg shadow-gray-900/20"
      placeholderText="Select or type date...(DD/MM/YYYY)"
      wrapperClassName="w-full"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      showPopperArrow={false}
      isClearable
      calendarClassName="bg-gray-800 border border-gray-700 rounded-lg shadow-lg"
      dayClassName={(date) => "text-gray-100 hover:bg-teal-500/20 rounded-lg"}
      monthClassName={() => "text-gray-100"}
      weekDayClassName={() => "text-teal-400"}
      todayButton="Today"
      todayButtonClassName="bg-teal-500 text-white rounded-lg px-4 py-2 mt-2 hover:bg-teal-600 transition-colors duration-200"
    />
  );
};

export default TransactionDatePicker;
