// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchTransactions,
//   deleteTransaction,
//   updateTransaction,
// } from "../store/slices/generalTransactionSlice";
// import { Pencil, Trash2 } from "lucide-react";

// const TransactionTable = () => {
//   const dispatch = useDispatch();
//   const {
//     transactions = [],
//     loading,
//     error,
//   } = useSelector((state) => state.generalTransactions || {});

//   const [editTransaction, setEditTransaction] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [updatedData, setUpdatedData] = useState({
//     description: "",
//     amount: "",
//     category: "",
//     type: "",
//   });
//   const [alertMessage, setAlertMessage] = useState(null);

//   useEffect(() => {
//     dispatch(fetchTransactions());
//   }, [dispatch]);

//   const handleEditTransaction = (transaction) => {
//     setEditTransaction(transaction);
//     setUpdatedData({
//       description: transaction.description,
//       amount: transaction.amount,
//       category: transaction.category,
//       type: transaction.type,
//     });
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => setIsModalOpen(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setUpdatedData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleUpdateTransaction = async () => {
//     const { type, description, amount, category } = updatedData;

//     if (!description || !amount || !category || !type) {
//       setAlertMessage("All fields are required for update");
//       return;
//     }

//     if (editTransaction?._id) {
//       dispatch(
//         updateTransaction({
//           transactionId: editTransaction._id,
//           updatedData: { type, description, amount, category },
//         })
//       ).then((response) => {
//         if (response.error) {
//           setAlertMessage(
//             "Error updating transaction: " + response.error.message
//           );
//         } else {
//           setIsModalOpen(false);
//         }
//       });
//     }
//   };

//   const handleDeleteTransaction = async (transactionId) => {
//     const response = await dispatch(deleteTransaction(transactionId));
//     if (response.error) {
//       setAlertMessage(`Error deleting transaction: ${response.error.message}`);
//     } else {
//       setAlertMessage("Transaction deleted successfully");
//     }
//   };

//   if (loading) {
//     return (
//       <div className="flex justify-center items-center h-32">
//         <p className="text-teal-400">Loading transactions...</p>
//       </div>
//     );
//   }

//   if (error) {
//     const errorMessage =
//       typeof error === "object" && error.message ? error.message : error;
//     return (
//       <div className="bg-red-900/50 p-4 rounded-lg">
//         <p className="text-red-400">{errorMessage}</p>
//       </div>
//     );
//   }

//   return (
//     <div className="bg-gray-900 rounded-lg shadow-xl">
//       <div className="p-6">
//         <h2 className="text-2xl font-bold text-teal-400 mb-6">
//           Transaction List
//         </h2>

//         <div className="overflow-x-auto">
//           <table className="w-full">
//             <thead className="bg-gray-800">
//               <tr>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Description
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Amount
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Category
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Type
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Actions
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {transactions.length === 0 ? (
//                 <tr>
//                   <td
//                     colSpan="5"
//                     className="px-4 py-3 text-teal-300 text-center"
//                   >
//                     No transactions found.
//                   </td>
//                 </tr>
//               ) : (
//                 transactions.map((transaction) => (
//                   <tr
//                     key={transaction._id}
//                     className="border-b border-gray-700 hover:bg-gray-800"
//                   >
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.description}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       Rs.{transaction.amount}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.category}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.type}
//                     </td>
//                     <td className="px-4 py-3">
//                       <div className="flex space-x-2">
//                         <button
//                           onClick={() => handleEditTransaction(transaction)}
//                           className="p-2 bg-teal-600 text-white rounded hover:bg-teal-500 transition-colors"
//                           title="Edit"
//                         >
//                           <Pencil size={16} />
//                         </button>
//                         <button
//                           onClick={() =>
//                             handleDeleteTransaction(transaction._id)
//                           }
//                           className="p-2 bg-red-600 text-white rounded hover:bg-red-500 transition-colors"
//                           title="Delete"
//                         >
//                           <Trash2 size={16} />
//                         </button>
//                       </div>
//                     </td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>

//         {alertMessage && (
//           <div
//             className={`p-4 rounded-lg mt-4 ${
//               alertMessage.includes("Error") ? "bg-red-900" : "bg-teal-900"
//             }`}
//           >
//             <p
//               className={
//                 alertMessage.includes("Error")
//                   ? "text-red-400"
//                   : "text-teal-400"
//               }
//             >
//               {alertMessage}
//             </p>
//           </div>
//         )}

//         {isModalOpen && (
//           <div className="fixed inset-0 bg-black/50 flex justify-center items-center z-50">
//             <div className="bg-gray-900 p-6 rounded-lg shadow-lg w-full max-w-md">
//               <h3 className="text-2xl font-bold mb-4 text-teal-400">
//                 Edit Transaction
//               </h3>

//               <form className="space-y-4">
//                 <div>
//                   <label
//                     htmlFor="description"
//                     className="block text-teal-400 mb-1"
//                   >
//                     Description
//                   </label>
//                   <input
//                     id="description"
//                     name="description"
//                     type="text"
//                     className="w-full p-2 bg-gray-800 text-teal-300 border border-gray-700 rounded focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
//                     value={updatedData.description}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div>
//                   <label htmlFor="amount" className="block text-teal-400 mb-1">
//                     Amount
//                   </label>
//                   <input
//                     id="amount"
//                     name="amount"
//                     type="number"
//                     className="w-full p-2 bg-gray-800 text-teal-300 border border-gray-700 rounded focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
//                     value={updatedData.amount}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div>
//                   <label
//                     htmlFor="category"
//                     className="block text-teal-400 mb-1"
//                   >
//                     Category
//                   </label>
//                   <input
//                     id="category"
//                     name="category"
//                     type="text"
//                     className="w-full p-2 bg-gray-800 text-teal-300 border border-gray-700 rounded focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
//                     value={updatedData.category}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div>
//                   <label htmlFor="type" className="block text-teal-400 mb-1">
//                     Transaction Type
//                   </label>
//                   <input
//                     id="type"
//                     name="type"
//                     type="text"
//                     className="w-full p-2 bg-gray-800 text-teal-300 border border-gray-700 rounded focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
//                     value={updatedData.type}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="flex justify-between pt-4">
//                   <button
//                     type="button"
//                     onClick={handleUpdateTransaction}
//                     className="px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-500 transition-colors"
//                   >
//                     Save Changes
//                   </button>
//                   <button
//                     type="button"
//                     onClick={handleCloseModal}
//                     className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-500 transition-colors"
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default TransactionTable;
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchTransactions,
//   deleteTransaction,
//   updateTransaction,
// } from "../store/slices/generalTransactionSlice";
// import { Pencil, Trash2 } from "lucide-react";

// const TransactionTable = () => {
//   const dispatch = useDispatch();
//   const {
//     transactions = [],
//     loading,
//     error,
//   } = useSelector((state) => state.generalTransactions || {});
//   console.log(transactions);

//   const [editTransaction, setEditTransaction] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [updatedData, setUpdatedData] = useState({
//     description: "",
//     amount: "",
//     category: "",
//     type: "",
//   });
//   const [alertMessage, setAlertMessage] = useState(null);

//   useEffect(() => {
//     dispatch(fetchTransactions());
//   }, [dispatch]);

//   const handleEditTransaction = (transaction) => {
//     setEditTransaction(transaction);
//     setUpdatedData({
//       description: transaction.description,
//       amount: transaction.amount,
//       category: transaction.category,
//       type: transaction.type,
//     });
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => setIsModalOpen(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setUpdatedData((prev) => ({ ...prev, [name]: value }));
//   };

//   // Removed filtering by role here since you want to show all transactions
//   // If needed, you can still add filters for specific criteria like date, type, etc.
//   const filteredTransactions = transactions; // Showing all transactions without filtering by role

//   const handleUpdateTransaction = async () => {
//     const { type, description, amount, category } = updatedData;

//     if (!description || !amount || !category || !type) {
//       setAlertMessage("All fields are required for update");
//       return;
//     }

//     if (editTransaction?._id) {
//       dispatch(
//         updateTransaction({
//           transactionId: editTransaction._id,
//           updatedData: { type, description, amount, category },
//         })
//       ).then((response) => {
//         if (response.error) {
//           setAlertMessage(
//             "Error updating transaction: " + response.error.message
//           );
//         } else {
//           setIsModalOpen(false);
//         }
//       });
//     }
//   };

//   const handleDeleteTransaction = async (transactionId) => {
//     const response = await dispatch(deleteTransaction(transactionId));
//     if (response.error) {
//       setAlertMessage(`Error deleting transaction: ${response.error.message}`);
//     } else {
//       setAlertMessage("Transaction deleted successfully");
//       dispatch(fetchTransactions());
//     }
//   };

//   if (loading) {
//     return (
//       <div className="flex justify-center items-center h-32">
//         <p className="text-teal-400">Loading transactions...</p>
//       </div>
//     );
//   }

//   if (error) {
//     const errorMessage =
//       typeof error === "object" && error.message ? error.message : error;
//     return (
//       <div className="bg-red-900/50 p-4 rounded-lg">
//         <p className="text-red-400">{errorMessage}</p>
//       </div>
//     );
//   }

//   return (
//     <div className="bg-gray-900 rounded-lg shadow-xl">
//       <div className="p-6">
//         <h2 className="text-2xl font-bold text-teal-400 mb-6">
//           Transaction List
//         </h2>

//         <div className="overflow-x-auto">
//           <table className="w-full">
//             <thead className="bg-gray-800">
//               <tr>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Description
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Amount
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Category
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Type
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Added By (Username)
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Added By (Role)
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Added By (Designation)
//                 </th>
//                 <th className="px-4 py-3 text-left text-teal-400 font-medium">
//                   Actions
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredTransactions.length === 0 ? (
//                 <tr>
//                   <td
//                     colSpan="8"
//                     className="px-4 py-3 text-teal-300 text-center"
//                   >
//                     No transactions found.
//                   </td>
//                 </tr>
//               ) : (
//                 filteredTransactions.map((transaction) => (
//                   <tr
//                     key={transaction._id}
//                     className="border-b border-gray-700 hover:bg-gray-800"
//                   >
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.description}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       Rs.{transaction.amount}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.category}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.type}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.username || "N/A"}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.role || "N/A"}
//                     </td>
//                     <td className="px-4 py-3 text-teal-300">
//                       {transaction.designation || "Admin"}
//                     </td>

//                     <td className="px-4 py-3">
//                       <div className="flex space-x-2">
//                         <button
//                           onClick={() => handleEditTransaction(transaction)}
//                           className="p-2 bg-teal-600 text-white rounded hover:bg-teal-500 transition-colors"
//                           title="Edit"
//                         >
//                           <Pencil size={16} />
//                         </button>
//                         <button
//                           onClick={() =>
//                             handleDeleteTransaction(transaction._id)
//                           }
//                           className="p-2 bg-red-600 text-white rounded hover:bg-red-500 transition-colors"
//                           title="Delete"
//                         >
//                           <Trash2 size={16} />
//                         </button>
//                       </div>
//                     </td>
//                   </tr>
//                 ))
//               )}
//             </tbody>
//           </table>
//         </div>

//         {alertMessage && (
//           <div
//             className={`p-4 rounded-lg mt-4 ${
//               alertMessage.includes("Error") ? "bg-red-900" : "bg-teal-900"
//             }`}
//           >
//             <p
//               className={
//                 alertMessage.includes("Error")
//                   ? "text-red-400"
//                   : "text-teal-400"
//               }
//             >
//               {alertMessage}
//             </p>
//           </div>
//         )}

//         {isModalOpen && (
//           <div className="fixed inset-0 bg-black/50 flex justify-center items-center z-50">
//             <div className="bg-gray-900 p-6 rounded-lg shadow-lg w-full max-w-md">
//               <h3 className="text-2xl font-bold mb-4 text-teal-400">
//                 Edit Transaction
//               </h3>

//               <form className="space-y-4">
//                 <div>
//                   <label
//                     htmlFor="description"
//                     className="block text-teal-400 mb-1"
//                   >
//                     Description
//                   </label>
//                   <input
//                     id="description"
//                     name="description"
//                     type="text"
//                     className="w-full p-2 bg-gray-800 text-teal-300 border border-gray-700 rounded focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
//                     value={updatedData.description}
//                     onChange={handleInputChange}
//                   />
//                 </div>

//                 <div>
//                   <label htmlFor="amount" className="block text-teal-400 mb-1">
//                     Amount
//                   </label>
//                   <input
//                     id="amount"
//                     name="amount"
//                     type="number"
//                     className="w-full p-2 bg-gray-800 text-teal-300 border border-gray-700 rounded focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
//                     value={updatedData.amount}
//                     onChange={handleInputChange}
//                   />
//                 </div>

//                 <div>
//                   <label
//                     htmlFor="category"
//                     className="block text-teal-400 mb-1"
//                   >
//                     Category
//                   </label>
//                   <input
//                     id="category"
//                     name="category"
//                     type="text"
//                     className="w-full p-2 bg-gray-800 text-teal-300 border border-gray-700 rounded focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
//                     value={updatedData.category}
//                     onChange={handleInputChange}
//                   />
//                 </div>

//                 <div>
//                   <label htmlFor="type" className="block text-teal-400 mb-1">
//                     Type
//                   </label>
//                   <input
//                     id="type"
//                     name="type"
//                     type="text"
//                     className="w-full p-2 bg-gray-800 text-teal-300 border border-gray-700 rounded focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
//                     value={updatedData.type}
//                     onChange={handleInputChange}
//                   />
//                 </div>

//                 <div className="flex justify-between">
//                   <button
//                     type="button"
//                     onClick={handleCloseModal}
//                     className="py-2 px-4 bg-gray-600 text-white rounded hover:bg-gray-500 transition-colors"
//                   >
//                     Cancel
//                   </button>
//                   <button
//                     type="button"
//                     onClick={handleUpdateTransaction}
//                     className="py-2 px-4 bg-teal-600 text-white rounded hover:bg-teal-500 transition-colors"
//                   >
//                     Update
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default TransactionTable;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransactions,
  deleteTransaction,
  updateTransaction,
} from "../store/slices/generalTransactionSlice";
import {
  Pencil,
  Trash2,
  ArrowUpRight,
  Sparkles,
  ChevronRight,
  Clock,
  DollarSign,
  Tag,
} from "lucide-react";

const TransactionTable = () => {
  const dispatch = useDispatch();
  const {
    transactions = [],
    loading,
    error,
  } = useSelector((state) => state.generalTransactions || {});
  const activeUser = useSelector((state) => state.auth.activeUser);

  const [editTransaction, setEditTransaction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState({
    description: "",
    amount: "",
    category: "",
    type: "",
  });
  const [alertMessage, setAlertMessage] = useState(null);

  const isEmployee = activeUser?.role === "Employee";

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  // ... (keeping all the handler functions the same)
  const handleEditTransaction = (transaction) => {
    if (isEmployee) return;
    setEditTransaction(transaction);
    setUpdatedData({
      description: transaction.description,
      amount: transaction.amount,
      category: transaction.category,
      type: transaction.type,
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateTransaction = async () => {
    if (isEmployee) return;
    const { type, description, amount, category } = updatedData;

    if (!description || !amount || !category || !type) {
      setAlertMessage("All fields are required for update");
      return;
    }

    if (editTransaction?._id) {
      dispatch(
        updateTransaction({
          transactionId: editTransaction._id,
          updatedData: { type, description, amount, category },
        })
      ).then((response) => {
        if (response.error) {
          setAlertMessage(
            "Error updating transaction: " + response.error.message
          );
        } else {
          setIsModalOpen(false);
        }
      });
    }
  };

  const handleDeleteTransaction = async (transactionId) => {
    if (isEmployee) return;
    const response = await dispatch(deleteTransaction(transactionId));
    if (response.error) {
      setAlertMessage(`Error deleting transaction: ${response.error.message}`);
    } else {
      setAlertMessage("Transaction deleted successfully");
      dispatch(fetchTransactions());
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="relative">
          <div className="w-16 h-16 border-4 border-teal-300/20 border-t-teal-300 rounded-full animate-spin"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-12 h-12 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-full"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-slate-800 via-slate-900  to-slate-800 rounded-2xl min-h-screen">
      {/* Header Section */}
      <div className="relative rounded-3xl overflow-hidden">
        <div className="absolute rounded-3xl  inset-0 bg-teal-300/5 backdrop-blur-3xl"></div>
        <div className="relative rounded-3xl  px-8 py-12">
          <div className="flex items-center rounded-3xl  justify-between">
            <div className="space-y-1">
              <div className="flex items-center space-x-4">
                <h2 className="text-4xl font-bold text-teal-300">
                  Transactions
                </h2>
                <div className="flex items-center px-3 py-1 rounded-full bg-teal-300/10 border border-teal-300/20">
                  <Sparkles className="w-4 h-4 text-teal-300 mr-2" />
                  <span className="text-sm text-teal-300 font-medium">
                    {transactions.length} Total
                  </span>
                </div>
              </div>
              <p className="text-teal-300/60">
                Manage and monitor your financial activities
              </p>
            </div>
            <div className="flex space-x-4">
              <div className="flex flex-col items-center justify-center w-32 h-32 rounded-2xl bg-gradient-to-br from-slate-800/50 to-slate-900/50 border border-teal-300/10 backdrop-blur-lg">
                <h3 className="w-8 text-teal-300 mb-2">PKR</h3>
                <span className="text-2xl font-bold text-teal-300">
                  {transactions
                    .reduce((acc, curr) => acc + Number(curr.amount), 0)
                    .toLocaleString()}
                </span>
                <span className="text-xs text-teal-300/60">Total Amount</span>
              </div>
              <div className="flex flex-col items-center justify-center w-32 h-32 rounded-2xl bg-gradient-to-br from-slate-800/50 to-slate-900/50 border border-teal-300/10 backdrop-blur-lg">
                <Clock className="w-8 h-8 text-teal-300 mb-2" />
                <span className="text-2xl font-bold text-teal-300">
                  {new Set(transactions.map((t) => t.category)).size}
                </span>
                <span className="text-xs text-teal-300/60">Categories</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="px-8 py-6">
        <div className="rounded-3xl border border-teal-300/10 overflow-hidden backdrop-blur-lg">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-slate-800/50">
                  {[
                    "Description",
                    "Amount",
                    "Category",
                    "Type",
                    "Username",
                    "Role",
                    "Designation",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-4 text-left text-xs font-medium text-teal-300 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                  {!isEmployee && (
                    <th
                      key="actions"
                      className="px-6 py-4 text-left text-teal-300 font-medium"
                    >
                      Actions
                    </th>
                  )}
                </tr>
              </thead>

              <tbody className="divide-y divide-teal-300/10">
                {transactions.map((transaction) => (
                  <tr
                    key={transaction._id}
                    className="group hover:bg-teal-300/5 transition-all duration-300"
                  >
                    <td className="px-6 py-6">
                      <div className="flex items-center">
                        <div className="w-8 h-8 rounded-full bg-teal-300/10 flex items-center justify-center mr-3">
                          <Tag className="w-4 h-4 text-teal-300" />
                        </div>
                        <span className="text-teal-300 font-medium">
                          {transaction.description}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-6">
                      <div className="flex items-center space-x-3">
                        <h4 className="w-4 text-teal-300">PKR</h4>
                        <span className="text-teal-300 font-medium">
                          {transaction.amount
                            ? transaction.amount.toLocaleString()
                            : "N/A"}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-6">
                      <span className="px-3 py-1 rounded-full text-xs font-medium bg-gradient-to-r from-teal-300/10 to-transparent text-teal-300 border border-teal-300/20">
                        {transaction.category}
                      </span>
                    </td>
                    <td className="px-6 py-6">
                      <span className="px-3 py-1 rounded-full text-xs font-medium bg-gradient-to-r from-teal-300/10 to-transparent text-teal-300 border border-teal-300/20">
                        {transaction.type}
                      </span>
                    </td>
                    <td className="px-6 py-6 text-teal-300">
                      {transaction.username || "N/A"}
                    </td>
                    <td className="px-6 py-6 text-teal-300">
                      {transaction.role || "N/A"}
                    </td>
                    <td className="px-6 py-6 text-teal-300">
                      {transaction.designation || "Admin"}
                    </td>
                    {!isEmployee && (
                      <td className="px-6 py-6">
                        <div className="flex items-center space-x-3 opacity-0 group-hover:opacity-100 transition-all duration-300">
                          <button
                            onClick={() => handleEditTransaction(transaction)}
                            className="p-2 rounded-xl bg-teal-300/10 hover:bg-teal-300/20 text-teal-300 transition-colors duration-300"
                          >
                            <Pencil className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() =>
                              handleDeleteTransaction(transaction._id)
                            }
                            className="p-2 rounded-xl bg-red-400/10 hover:bg-red-400/20 text-red-400 transition-colors duration-300"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Edit Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-slate-900/90 flex items-center justify-center backdrop-blur-sm">
            <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-3xl shadow-2xl w-full max-w-lg p-8 border border-teal-300/20">
              <div className="flex items-center justify-between mb-8">
                <h3 className="text-2xl font-bold text-teal-300">
                  Edit Transaction
                </h3>
                <button
                  onClick={handleCloseModal}
                  className="p-2 rounded-xl hover:bg-teal-300/10 text-teal-300 transition-colors duration-300"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <form className="space-y-6">
                {["description", "amount", "category", "type"].map((field) => (
                  <div key={field}>
                    <label
                      htmlFor={field}
                      className="block text-sm font-medium text-teal-300 mb-2 capitalize"
                    >
                      {field}
                    </label>
                    <div className="relative">
                      <input
                        id={field}
                        name={field}
                        type={field === "amount" ? "number" : "text"}
                        value={updatedData[field]}
                        onChange={handleInputChange}
                        className="w-full px-4 py-3 rounded-xl bg-slate-800/50 border border-teal-300/20 text-teal-300 placeholder-teal-300/50 focus:ring-2 focus:ring-teal-300/20 focus:border-teal-300/30 transition-all duration-300"
                        placeholder={`Enter ${field}`}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <ChevronRight className="w-4 h-4 text-teal-300/30" />
                      </div>
                    </div>
                  </div>
                ))}

                <div className="flex justify-end space-x-4 pt-6">
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="px-6 py-3 rounded-xl text-sm font-medium text-teal-300 hover:bg-teal-300/10 transition-colors duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleUpdateTransaction}
                    className="px-6 py-3 rounded-xl text-sm font-medium bg-teal-300 text-slate-900 hover:bg-teal-400 transition-colors duration-300"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Alert Messages */}
        {alertMessage && (
          <div className="fixed bottom-8 right-8 max-w-md">
            <div
              className={`p-6 rounded-2xl backdrop-blur-lg animate-slide-up ${
                alertMessage.includes("Error")
                  ? "bg-red-900/20 text-red-400 border border-red-500/20"
                  : "bg-teal-900/20 text-teal-300 border border-teal-500/20"
              }`}
            >
              <div className="flex items-center space-x-3">
                <div
                  className={`p-2 rounded-full ${
                    alertMessage.includes("Error")
                      ? "bg-red-400/10"
                      : "bg-teal-300/10"
                  }`}
                >
                  {alertMessage.includes("Error") ? (
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
                <p className="text-sm font-medium">{alertMessage}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionTable;
