import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { fetchAllUsers, fetchCustomerPlots } from "../store/slices/authSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import { transferPlotThunk } from "../store/slices/transferPlotSlice";
import { Calendar, Search } from "lucide-react";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ field, form, ...props }) => {
  const { setFieldValue } = form;
  const { name, value } = field;

  return (
    <div className="relative">
      <div className="absolute right-4 top-1/2 -translate-y-1/2">
        <Calendar className="h-6 w-6 text-teal-300/50" />
      </div>
      <DatePicker
        {...props}
        selected={value ? new Date(value) : null}
        onChange={(date) => {
          setFieldValue(name, date);
        }}
        maxDate={new Date()}
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        wrapperClassName="w-full"
        className="w-full h-12 bg-slate-800/50 text-teal-300 rounded-2xl px-6 border border-teal-500/20 focus:border-teal-500/50 focus:ring-2 focus:ring-teal-500/20 transition-all duration-300"
        placeholderText="Select transfer date...(DD/MM/YYYY)"
        scrollableYearDropdown
        yearDropdownItemNumber={15}
      />
    </div>
  );
};

const TransferPlotForm = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { activeSociety } = useSidebarContext();

  const { users } = useSelector((state) => state.auth);
  const { plots: customerPlots } = useSelector((state) => state.auth);
  const { societies } = useSelector((state) => state.societies);

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedPlot, setSelectedPlot] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  // Validation Schema
  const TransferPlotSchema = Yup.object().shape({
    new_owner_id: Yup.string().required("Please select a new owner"),
    plot_id: Yup.string().required("Please select a plot"),
    transfer_fee: Yup.string()
      .matches(/^\d+(,\d{3})*$/, "Transfer fee must be a valid number")
      .test(
        "is-number",
        "Transfer fee must be a valid number",
        (value) => !isNaN(Number(value.replace(/,/g, "")))
      )
      .required("Transfer fee is required"),
    transfer_date: Yup.date()
      .required("Transfer date is required")
      .max(new Date(), "Transfer date cannot be in the future")
      .typeError("Please enter a valid date"),
  });

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchSocieties());
  }, [dispatch]);

  useEffect(() => {
    if (users?.length > 0 && activeSociety) {
      const societyCustomers = users.filter((user) =>
        activeSociety.users.some(
          (societyUser) =>
            societyUser._id === user._id && societyUser.role === "Customer"
        )
      );

      setCustomers(societyCustomers);
      setFilteredCustomers(societyCustomers);
    } else {
      setCustomers([]);
      setFilteredCustomers([]);
    }
  }, [users, activeSociety]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = customers.filter((customer) =>
        customer.username.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCustomers(filtered);
    } else {
      setFilteredCustomers(customers);
    }
  }, [searchTerm, customers]);

  useEffect(() => {
    if (selectedCustomer) {
      dispatch(fetchCustomerPlots(selectedCustomer));
    }
  }, [selectedCustomer, dispatch]);

  useEffect(() => {
    if (activeSociety) {
      setSearchTerm("");
      setSelectedCustomer("");
      setSelectedPlot("");
      const societyCustomers = users.filter((user) =>
        activeSociety.users.some(
          (societyUser) =>
            societyUser._id === user._id && societyUser.role === "Customer"
        )
      );

      setCustomers(societyCustomers);
      setFilteredCustomers(societyCustomers);
    }
  }, [activeSociety, users]);

  const getSocietyAndPlotDetails = (plotId) => {
    if (!societies || societies.length === 0) return null;

    for (let soc of societies) {
      const plot = soc.plots.find((plot) => plot._id === plotId);
      if (plot) {
        return {
          societyName: soc.name,
          plotNumber: plot.plot_number,
          plotType: plot.plot_type,
          block: plot.block,
          category: plot.category,
          bookingStatus: plot.booking_status,
          status: plot.status,
        };
      }
    }
    return null;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCustomerSelect = (customerId, customerName, setFieldValue) => {
    setSelectedCustomer(customerId);
    setSearchTerm(customerName);
    setFieldValue("new_owner_id", customerId);
    setFieldValue("plot_id", "");
    setFilteredCustomers([]);
    setIsFocused(false);
    setSelectedPlot("");
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => setIsFocused(false), 200);
  };

  const handlePlotSelect = (plot, setFieldValue) => {
    const details = getSocietyAndPlotDetails(plot._id);

    if (details?.bookingStatus === "Transfer") {
      enqueueSnackbar(
        "This plot has already been transferred and cannot be selected.",
        { variant: "error" }
      );
      return;
    }
    setSelectedPlot(plot._id);
    setFieldValue("plot_id", plot._id);
  };

  // const handleSubmit = (values, { setSubmitting, resetForm }) => {
  //   dispatch(transferPlotThunk(values))
  //     .then((response) => {
  //       // Success Notification
  //       enqueueSnackbar("Plot transfer completed successfully!", {
  //         variant: "success",
  //       });
  //       resetForm();
  //       setSelectedCustomer("");
  //       setSelectedPlot("");
  //       setSearchTerm("");
  //     })
  //     .catch((error) => {
  //       // Extract backend error message
  //       const errorMessage =
  //         error.response?.data?.message ||
  //         "Failed to transfer plot. Please try again.";
  //       enqueueSnackbar(errorMessage, { variant: "error" });
  //     })
  //     .finally(() => {
  //       setSubmitting(false);
  //     });
  // };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const formattedValues = {
      ...values,
      transfer_fee: values.transfer_fee.replace(/,/g, ""),
      transfer_date: values.transfer_date,
    };
    dispatch(transferPlotThunk(formattedValues))
      .then((response) => {
        enqueueSnackbar("Plot transfer completed successfully!", {
          variant: "success",
        });
        resetForm();
        setSelectedCustomer("");
        setSelectedPlot("");
        setSearchTerm("");
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          "Failed to transfer plot. Please try again.";
        enqueueSnackbar(errorMessage, { variant: "error" });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <div className=" bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-6">
      <div className="max-w-7xl mx-auto">
        <div className="space-y-8">
          {/* Header Section */}
          <div className="text-center space-y-6">
            <div className="relative">
              <div className="relative left-1/2 -translate-x-1/2 w-56 mb-2">
                <div className="px-4 py-3 bg-teal-500/20 backdrop-blur-xl rounded-full border border-teal-500/30 flex items-center justify-center gap-2">
                  {activeSociety?.society_image && (
                    <img
                      src={activeSociety.society_image}
                      alt={`${activeSociety?.name || "Society"} Logo`}
                      className="w-7 h-7 object-cover rounded-full"
                    />
                  )}
                  <p className="text-teal-300 text-sm px-3 font-semibold">
                    {activeSociety?.name || "Loading..."}
                  </p>
                </div>
              </div>

              <h1 className="text-4xl font-bold">
                <span className="bg-gradient-to-r from-teal-300 via-teal-200 to-teal-400 bg-clip-text text-transparent">
                  TRANSFER PLOT OWNERSHIP
                </span>
              </h1>
              <p className="text-teal-300/70">
                Transfer property ownership seamlessly and securely
              </p>
            </div>
          </div>
          <div className="backdrop-blur-xl bg-white/5 rounded-3xl border border-white/10 shadow-2xl p-8">
            <Formik
              initialValues={{
                plot_id: "",
                new_owner_id: "",
                transfer_fee: "",
                transfer_date: null,
              }}
              validationSchema={TransferPlotSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                <Form className="space-y-8">
                  <div className="relative">
                    <label className="inline-block text-teal-300 text-sm font-medium mb-2">
                      Search New Owner
                    </label>
                    <div className="relative">
                      <input
                        ref={inputRef}
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className={`w-full h-12 bg-slate-800/50 text-teal-300 rounded-2xl px-6 border ${
                          touched.new_owner_id && errors.new_owner_id
                            ? "border-red-500"
                            : "border-teal-500/20 focus:border-teal-500/50"
                        } focus:ring-2 focus:ring-teal-500/20 transition-all duration-300`}
                        placeholder="Enter customer name..."
                      />
                      <div className="absolute right-4 top-1/2 -translate-y-1/2">
                        <Search className="h-6 w-6 rounded-full text-teal-300 flex items-center justify-center" />
                      </div>
                    </div>

                    {/* Error Message for New Owner */}
                    <ErrorMessage
                      name="new_owner_id"
                      component="div"
                      className="text-red-500 text-sm mt-2"
                    />

                    {/* Dropdown Results */}
                    {isFocused && filteredCustomers.length > 0 && (
                      <div className="absolute w-full mt-2 py-2 bg-slate-800/90 backdrop-blur-xl border border-teal-500/20 rounded-2xl z-50 shadow-xl">
                        {filteredCustomers.map((customer) => (
                          <div
                            key={customer._id}
                            onClick={() =>
                              handleCustomerSelect(
                                customer._id,
                                customer.username,
                                setFieldValue
                              )
                            }
                            className="px-6 py-3 hover:bg-teal-500/10 cursor-pointer text-teal-300 transition-colors duration-200"
                          >
                            {customer.username}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Plots Grid */}
                  {selectedCustomer && customerPlots.length > 0 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-3 px-4 py-3 bg-teal-500/10 rounded-2xl">
                        <div className="h-2 w-2 rounded-full bg-teal-400 animate-pulse" />
                        <p className="text-teal-300 font-medium">
                          Available Plots ({customerPlots.length})
                        </p>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {customerPlots.map((plot) => {
                          const details = getSocietyAndPlotDetails(plot._id);

                          const isDisabled =
                            details?.bookingStatus === "Transfer" ||
                            details?.status === "Installment";
                          const tooltipMessage =
                            details?.bookingStatus === "Transfer"
                              ? "This plot has already been transferred and cannot be selected."
                              : details?.status === "Installment"
                              ? "Plots with installment status cannot be transferred."
                              : "";

                          return (
                            <div
                              key={plot._id}
                              onClick={() =>
                                !isDisabled &&
                                handlePlotSelect(plot, setFieldValue)
                              }
                              title={isDisabled ? tooltipMessage : ""}
                              className={`relative group rounded-2xl border transition-all duration-500 ${
                                isDisabled
                                  ? "bg-gray-700 border-gray-500 cursor-not-allowed"
                                  : selectedPlot === plot._id
                                  ? "bg-teal-500/10 border-teal-400/50 shadow-lg shadow-teal-500/20"
                                  : "bg-slate-800/40 border-white/5 hover:border-teal-300/20 cursor-pointer"
                              }`}
                            >
                              <div className="p-6 space-y-4">
                                <div className="flex justify-between items-center">
                                  <span className="px-4 py-1 rounded-full bg-teal-500/10 text-sm font-medium text-teal-300">
                                    Plot {details?.plotNumber}
                                  </span>
                                  <div className="h-8 w-8 rounded-full bg-teal-500/10 flex items-center justify-center">
                                    <div className="h-2 w-2 rounded-full bg-teal-400 group-hover:animate-ping" />
                                  </div>
                                </div>
                                <div className="space-y-4">
                                  <div className="flex items-center gap-2">
                                    <span className="text-teal-300/70">
                                      Society:
                                    </span>
                                    <span className="text-teal-300 font-medium">
                                      {details?.societyName}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2 gap-3">
                                    <div className="p-3 rounded-xl bg-slate-800/50 border border-teal-500/10">
                                      <p className="text-sm text-teal-300/70">
                                        Size
                                      </p>
                                      <p className="text-teal-300 font-medium">
                                        {details?.plotType} Marla
                                      </p>
                                    </div>
                                    <div className="p-3 rounded-xl bg-slate-800/50 border border-teal-500/10">
                                      <p className="text-sm text-teal-300/70">
                                        Block
                                      </p>
                                      <p className="text-teal-300 font-medium">
                                        {details?.block}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex justify-between items-center pt-2">
                                    <div>
                                      <p className="text-sm text-teal-300/70">
                                        Status
                                      </p>
                                      <p
                                        className={`font-medium ${
                                          details?.bookingStatus === "Transfer"
                                            ? "text-red-500"
                                            : "text-teal-300"
                                        }`}
                                      >
                                        {details?.bookingStatus}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="text-sm text-teal-300/70">
                                        Category
                                      </p>
                                      <p className="text-teal-300 font-medium">
                                        {details?.category}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="text-sm text-teal-300/70">
                                        Plot Status
                                      </p>
                                      <p className="text-teal-300 font-medium">
                                        {details?.status}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* Plot Selection Error */}
                      <ErrorMessage
                        name="plot_id"
                        component="div"
                        className="text-red-500 text-sm mt-2"
                      />
                    </div>
                  )}

                  {/* Transfer Fee Input */}
                  {selectedPlot && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {/* Transfer Fee Input */}
                      <div className="space-y-2">
                        <label className="inline-block text-teal-300 text-sm font-medium mb-2">
                          Transfer Fee
                        </label>
                        <div className="relative">
                          <Field
                            type="text"
                            name="transfer_fee"
                            value={values.transfer_fee.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            onChange={(e) => {
                              const rawValue = e.target.value.replace(/,/g, "");
                              setFieldValue("transfer_fee", rawValue);
                            }}
                            className={`w-full h-12 bg-slate-800/50 text-teal-300 rounded-2xl px-6 border ${
                              touched.transfer_fee && errors.transfer_fee
                                ? "border-red-500"
                                : "border-teal-500/20 focus:border-teal-500/50"
                            } focus:ring-2 focus:ring-teal-500/20 transition-all duration-300`}
                            placeholder="Enter transfer fee amount..."
                          />
                          <div className="absolute right-4 top-1/2 -translate-y-1/2 text-teal-300/50">
                            PKR
                          </div>
                        </div>
                        {touched.transfer_fee && errors.transfer_fee && (
                          <div className="text-red-500 text-sm mt-2">
                            {errors.transfer_fee}
                          </div>
                        )}
                      </div>

                      {/* Transfer Date Input */}
                      <div className="space-y-2">
                        <label className="inline-block text-teal-300 text-sm font-medium mb-2">
                          Transfer Date
                        </label>
                        <Field
                          name="transfer_date"
                          component={CustomDatePicker}
                        />
                        {touched.transfer_date && errors.transfer_date && (
                          <div className="text-red-500 text-sm mt-2">
                            {errors.transfer_date}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="w-full bg-gradient-to-r from-teal-500 to-teal-400 h-12 rounded-2xl text-white font-medium transition-all duration-300 hover:shadow-lg hover:shadow-teal-500/25 hover:-translate-y-0.5 active:translate-y-0 disabled:opacity-50 disabled:pointer-events-none"
                    disabled={
                      isSubmitting || !selectedPlot || !values.transfer_fee
                    }
                  >
                    {isSubmitting ? "Processing..." : "Complete Transfer"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferPlotForm;
