import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  payInstallmentAsync,
  fetchInstallmentsByPlotIdAndStatus,
  fetchInstallmentsByPlotId,
  fetchPaidInstallmentsForUser,
  updateInstallmentStatusAsync,
} from "../store/slices/installmentSlice";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchAllUsers, fetchCustomerPlots } from "../store/slices/authSlice";
import { fetchSocieties } from "../store/slices/societySlice";
import InstallmentBookingSlip from "./util/InstallmentBookingSlip";
import Modal from "react-modal";
import {
  MapPin,
  Home,
  Tag,
  CheckCircle,
  Info,
  HandCoins,
  BookCopy,
  ReceiptText,
  Edit2,
  X,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { getAllBookings } from "../store/slices/bookingSlice";

const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
  backdropFilter: "blur(4px)",
  padding: "1rem",
};

const modalStyle = {
  position: "relative",
  background: "#ffffff",
  borderRadius: "8px",
  padding: "20px",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "90vh",
  overflow: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
  zIndex: 1001,
};
const printStyles = `
  @media print {
    .print-btn, .close-btn {
      display: none !important;
    }
  }
`;
const ManageInstallments = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { installments } = useSelector((state) => state.installments);
  const users = useSelector((state) => state.auth.users);
  const customerPlots = useSelector((state) => state.auth.plots || []);
  const { societies } = useSelector((state) => state.societies);
  const { activeSociety } = useSidebarContext();
  const [paymentMode, setPaymentMode] = useState("");
  const [installmentId, setInstallmentId] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [completedCurrentPage, setCompletedCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [isSlipModalOpen, setIsSlipModalOpen] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [receiptNo, setReceiptNo] = useState("");
  const [selectedInstallmentDate, setSelectedInstallmentDate] = useState("");
  const [totals, setTotals] = useState({
    totalPaidAmount: 0,
    totalPendingInstallmentsAmount: 0,
    totalPlotAmount: 0,
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingInstallment, setEditingInstallment] = useState(null);
  const [editFormData, setEditFormData] = useState({
    installment_number: "",
    amount: "",
    due_date: "",
    description: "",
    status: "",
  });
  const { bookings = [] } = useSelector((state) => state.booking);
  const [filteredPlots, setFilteredPlots] = useState([]);
const [plotSearchTerm, setPlotSearchTerm] = useState("");
const [showPlotDropdown, setShowPlotDropdown] = useState(false);
const [selectedPlotCustomer, setSelectedPlotCustomer] = useState(null);
const dropdownRef = useRef(null);

  const handleEditClick = (installment) => {
    setEditingInstallment(installment);
    setEditFormData({
      installment_number: installment.installment_number,
      amount: installment.amount.toString(),
      due_date: new Date(installment.due_date).toISOString().split("T")[0],
      description: installment.description || "",
      status: installment.status,
      paid_amount: installment.paid_amount?.toString() || "",
      payment_date: installment.payment_date
        ? new Date(installment.payment_date).toISOString().split("T")[0]
        : "",
      receipt_no: installment.receipt_no || "",
    });
    setIsEditModalOpen(true);
  };

  // Add handler for form input changes
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSaveChanges = async () => {
    try {
      const updatedData = {
        installmentId: editingInstallment._id,
        status: editFormData.status,
        installment_number: editFormData.installment_number,
        amount: parseFloat(editFormData.amount),
        due_date: editFormData.due_date,
        description: editFormData.description,
        paid_amount: parseFloat(editFormData.paid_amount),
        payment_date: editFormData.payment_date,
        receipt_no: editFormData.receipt_no,
      };

      await dispatch(updateInstallmentStatusAsync(updatedData)).unwrap();
      // Refresh the installments list
      if (selectedPlot) {
        dispatch(fetchInstallmentsByPlotId(selectedPlot._id));
      }

      enqueueSnackbar("Installment updated successfully", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });

      setIsEditModalOpen(false);
      setEditingInstallment(null);
    } catch (error) {
      enqueueSnackbar(error.message || "Failed to update installment", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  };
  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchSocieties());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllBookings());
  }, [dispatch]);

  const searchedPlots = useMemo(() => {
    return filteredPlots.filter(plot => 
      plot.plot_number.toLowerCase().includes(plotSearchTerm.toLowerCase())
    );
  }, [filteredPlots, plotSearchTerm]);

  useEffect(() => {
    if (activeSociety) {
      const validPlots = bookings
        .filter(booking => 
          booking.payment_mode === "Installment" &&
          booking.plot_id?.society_id === activeSociety?._id &&
          booking.plot_id?.status === "Installment"
        )
        .map(booking => booking.plot_id);

      setFilteredPlots(validPlots);
    }
  }, [bookings, activeSociety]);

  useEffect(() => {
    if (!isEditModalOpen) {
      setEditingInstallment(null);
    }
  }, [isEditModalOpen]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.customerData) {
      dispatch(fetchCustomerPlots(selectedCustomer.customerData._id));
    }
  }, [dispatch, selectedCustomer]);

  useEffect(() => {
    if (selectedCustomer) {
      const customerId = selectedCustomer._id;
      customerPlots.forEach((plot) => {
        const plotId = plot._id;
        dispatch(fetchPaidInstallmentsForUser({ customerId, plotId }))
          .unwrap()
          .then((result) => {
            setTotals((prevTotals) => ({
              ...prevTotals,
              [plotId]: {
                totalPaidAmount: result.totalPaidAmount || 0,
                totalPendingInstallmentsAmount:
                result.totalPendingInstallmentsAmount || 0,
                totalPlotAmount: result.totalPlotAmount || 0,
              },
            }));
          })
          .catch((error) => {
            console.error("Detailed Dispatch Error:", error);
            alert(
              "There was an issue fetching your data. Please check the console for details."
            );
          });
      });
    }
  }, [dispatch, selectedCustomer, customerPlots]);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowPlotDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (selectedPlot) {
      dispatch(fetchInstallmentsByPlotId(selectedPlot._id));
      const statuses = ["Pending", "Partially Paid", "Completed", "Overdue"];
      statuses.forEach((status) => {
        dispatch(
          fetchInstallmentsByPlotIdAndStatus({
            plotId: selectedPlot._id,
            status,
          })
        );
      });
    }
  }, [dispatch, selectedPlot]);

  useEffect(() => {
    setSelectedCustomer(null);
    setSelectedPlot(null);
  }, [activeSociety]);

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  useEffect(() => {
    if (selectedCustomer || selectedPlotCustomer) {
      const customer = selectedCustomer || selectedPlotCustomer;
      dispatch(fetchCustomerPlots(customer._id));
    }
  }, [dispatch, selectedCustomer, selectedPlotCustomer]);

 
  const getSocietyAndPlotDetails = (plotId) => {
    const plotBooking = bookings.find(booking => booking.plot_id?._id === plotId);
    for (let soc of societies) {
      const plot = soc.plots.find((plot) => plot._id === plotId);

      if (plot) {
        return {
          societyName: soc.name,
          societyLocation: soc.location,
          societyImage: soc.society_image,
          plotNumber: plot.plot_number,
          plotType: plot.plot_type,
          block: plot.block,
          category: plot.category,
          bookingStatus: plot.booking_status,
          status: plot.status,
          description: plot.description,
          deposite: plot.initial_payment,
          Date: plotBooking ? new Date(plotBooking.booking_date).toLocaleDateString('en-GB') : 'N/A'
        };
      }
    }
    return null;
  };

  // Filter users from the active society
  const customerOptions = useMemo(() => {
    if (!activeSociety || !activeSociety.users) {
      return [];
    }
    return activeSociety.users
      .filter((user) => user.role === "Customer")
      .map((customer) => (
        <option key={customer._id} value={customer._id}>
          {customer.username}
        </option>
      ));
  }, [activeSociety]);

  const handlePaymentModeChange = useCallback((value) => {
    setPaymentMode(value);
    setSelectedCustomer(null);
    setSelectedPlot(null);
    setInstallmentId("");
    setPaymentAmount("");
  }, []);

  const handleCustomerChange = useCallback(
    (customerId) => {
      const customer = users.find((user) => user._id === customerId);
      if (customer) {
        setSelectedCustomer(customer);
        setSelectedPlot(null);
      }
    },
    [users]
  );

  const handlePlotSelect = (plot) => {
    const plotDetails = getSocietyAndPlotDetails(plot._id);
    // Find the booking for this plot to get customer info
    const plotBooking = bookings.find(booking => booking.plot_id?._id === plot._id);
    const customer = plotBooking?.customer_id._id;
    
    if (plotDetails) {
      setSelectedPlot({
        ...plot,
        plot_number: plotDetails.plotNumber,
        soc: {
          name: plotDetails.societyName,
          location: plotDetails.societyLocation,
          society_image: plotDetails.societyImage,
        }
      });
            if (customer) {
        setSelectedCustomer(customer);
        setSelectedPlotCustomer(customer);
      }
    }
  };

  const handleInstallmentChange = useCallback(
    (installment) => {
      const plotDetails = getSocietyAndPlotDetails(selectedPlot._id);
      if (plotDetails) {
        const amountToSet =
          installment.remaining_amount > 0
            ? installment.remaining_amount
            : installment.amount;

        setInstallmentId(installment._id);
        setPaymentAmount(amountToSet.toString());
        setIsModalOpen(true);
        setSelectedPlot({
          ...selectedPlot,
          plotNumber: plotDetails.plotNumber,
          societyName: plotDetails.societyName,
        });
        // Add this line to store the current date
        setSelectedInstallmentDate(installment.payment_date);
      }
    },
    [selectedPlot]
  );

  const handlePaymentAmountChange = (e) => {
    // Remove commas and any invalid characters
    const value = e.target.value.replace(/,/g, "").replace(/\.\d{3,}/, "");
    let floatValue = parseFloat(value);

    // Ensure the value is a valid number
    if (value === "" || (!isNaN(floatValue) && floatValue >= 0)) {
      // Format the number with commas
      setPaymentAmount(value ? formatNumberWithCommas(floatValue) : "");
    }
  };

  const formatNumberWithCommas = (number) => {
    const parts = number.toString().split(".");
    // Add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  // Ensure preloaded value is formatted correctly
  useEffect(() => {
    if (paymentAmount && typeof paymentAmount === "string") {
      const unformattedValue = paymentAmount.replace(/,/g, ""); // Remove commas
      const floatValue = parseFloat(unformattedValue);
      if (!isNaN(floatValue)) {
        setPaymentAmount(formatNumberWithCommas(floatValue));
      }
    }
  }, [paymentAmount]);

  const handlePayment = async () => {
    if (
      !installmentId ||
      !paymentAmount ||
      parseFloat(paymentAmount.replace(/,/g, "")) <= 0
    ) {
      enqueueSnackbar("Please fill in all required fields with valid values", {
        variant: "warning",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    const currentInstallment = installments.find(
      (inst) => inst._id === installmentId
    );
    if (!currentInstallment) {
      enqueueSnackbar("Selected installment not found. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      return;
    }

    const paymentAmountFloat = parseFloat(paymentAmount.replace(/,/g, "")); // Ensure commas are stripped
    const paymentData = {
      installment_id: installmentId,
      paid_amount: paymentAmountFloat,
      receipt_no: receiptNo || `RCP-${Date.now()}`,
      updated_due_date: selectedInstallmentDate,
    };

    try {
      setLoading(true);
      const response = await dispatch(
        payInstallmentAsync(paymentData)
      ).unwrap();
      console.log("Payment Response:", response);
      const overpaymentAmount =
        paymentAmountFloat - currentInstallment.remaining_amount;
      const message =
        overpaymentAmount > 0
          ? `Payment processed successfully! Excess amount of PKR ${overpaymentAmount.toLocaleString()} will be applied to next installment.`
          : "Payment processed successfully!";

      enqueueSnackbar(message, {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
      if (selectedPlot) {
        dispatch(fetchInstallmentsByPlotId(selectedPlot._id));
        const statuses = ["Pending", "Partially Paid", "Completed", "Overdue"];
        statuses.forEach((status) => {
          dispatch(
            fetchInstallmentsByPlotIdAndStatus({
              plotId: selectedPlot._id,
              status,
            })
          );
        });
      }
      setLoading(false);
      setPaymentAmount("");
      setReceiptNo("");
      setIsModalOpen(false);
    } catch (err) {
      setLoading(false);
      console.error("Payment Error:", err);
      enqueueSnackbar(err.message || "Payment failed. Please try again.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
      });
    }
  };

  const filteredInstallments = installments
    .filter(
      (installment) =>
        installment.plot_id === selectedPlot?._id &&
        (installment.status === "Pending" || installment.status === "Overdue")
    )
    .sort((a, b) => new Date(a.due_date) - new Date(b.due_date));

  const CompleteInstallments = installments
    .filter(
      (installment) =>
        installment.plot_id === selectedPlot?._id &&
        (installment.status === "Completed")
    )
    .sort((a, b) => new Date(b.payment_date) - new Date(a.payment_date));

  const paginatedFilteredInstallments = filteredInstallments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const paginatedCompleteInstallments = CompleteInstallments.slice(
    (completedCurrentPage - 1) * itemsPerPage,
    completedCurrentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredInstallments.length / itemsPerPage);
  const completedTotalPages = Math.ceil(
    CompleteInstallments.length / itemsPerPage
  );

  const handleViewSlip = (installment) => {
    setSelectedInstallment(installment);
    setIsSlipModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSlipModalOpen(false);
    setSelectedInstallment(null);
  };

  const formatAmount = (amount) => {
    if (!amount) return "0";
    const roundedAmount = Math.round(parseFloat(amount));
    return roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getStatusColor = (status) => {
    const statusMap = {
      Installment: "text-amber-400",
    };
    return statusMap[status] || "text-teal-400";
  };
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditingInstallment(null);
    setEditFormData({
      installment_number: "",
      amount: "",
      due_date: "",
      description: "",
      status: "",
      paid_amount: "",
      payment_date: "",
      receipt_no: "",
    });
  };

  const formatAmountWithCommas = (value) => {
    if (!value) return "";
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const renderEditModal = () => {
    if (!isEditModalOpen) return null;

    const isCompleted = editFormData.status === "Completed";
    const isPending = editFormData.status === "Pending";

    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 bg-black/60 backdrop-blur-sm">
          <div className="w-full max-w-4xl mx-auto rounded-xl shadow-2xl bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 border border-slate-700/50">
            <div className="p-6">
              <div className="flex items-center justify-between mb-8">
                <h2 className="text-2xl font-semibold text-teal-300">
                  Edit Installment
                </h2>
                <button
                  onClick={handleCloseEditModal}
                  className="text-teal-300/70 hover:text-teal-300 transition-colors"
                >
                  <X size={24} />
                </button>
              </div>

              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-teal-300/80 mb-2">
                      Installment Number
                    </label>
                    <input
                      type="text"
                      name="installment_number"
                      value={editFormData.installment_number}
                      onChange={handleEditFormChange}
                      className="w-full px-4 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg border border-slate-600/50 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 placeholder-teal-300/30"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-teal-300/80 mb-2">
                      Amount (PKR)
                    </label>
                    <input
                      type="text"
                      name="amount"
                      value={formatAmountWithCommas(editFormData.amount)}
                      onChange={(e) =>
                        handleEditFormChange({
                          target: {
                            name: "amount",
                            value: e.target.value.replace(/,/g, ""),
                          },
                        })
                      }
                      className="w-full px-4 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg border border-slate-600/50 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 placeholder-teal-300/30"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-teal-300/80 mb-2">
                      Due Date
                    </label>
                    <DatePicker
                      selected={
                        editFormData.due_date
                          ? new Date(editFormData.due_date)
                          : null
                      }
                      onChange={(date) =>
                        handleEditFormChange({
                          target: {
                            name: "due_date",
                            value: date.toISOString().split("T")[0],
                          },
                        })
                      }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      wrapperClassName="w-full"
                      className="w-full px-4 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg border border-slate-600/50 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 placeholder-teal-300/30"
                      maxDate={new Date()}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-teal-300/80 mb-2">
                      Status
                    </label>
                    <select
                      name="status"
                      value={editFormData.status}
                      onChange={handleEditFormChange}
                      className="w-full px-4 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg border border-slate-600/50 focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
                      required
                    >
                      <option
                        value="Pending"
                        className="bg-gray-800 text-teal-300"
                      >
                        Pending
                      </option>
                      <option
                        value="Partially Paid"
                        className="bg-gray-800 text-teal-300"
                      >
                        Partially Paid
                      </option>
                      <option
                        value="Completed"
                        className="bg-gray-800 text-teal-300"
                      >
                        Completed
                      </option>
                      <option
                        value="Overdue"
                        className="bg-gray-800 text-teal-300"
                      >
                        Overdue
                      </option>
                    </select>
                  </div>
                </div>

                <div className="col-span-full">
                  <label className="block text-sm font-medium text-teal-300/80 mb-2">
                    Description
                  </label>
                  <textarea
                    name="description"
                    value={editFormData.description}
                    onChange={handleEditFormChange}
                    rows="3"
                    className="w-full px-4 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg border border-slate-600/50 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 placeholder-teal-300/30"
                  />
                </div>

                {isCompleted && (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-teal-300/80 mb-2">
                          Paid Amount
                        </label>
                        <input
                          type="text"
                          name="paid_amount"
                          value={formatAmountWithCommas(
                            editFormData.paid_amount
                          )}
                          onChange={(e) =>
                            handleEditFormChange({
                              target: {
                                name: "paid_amount",
                                value: e.target.value.replace(/,/g, ""),
                              },
                            })
                          }
                          className="w-full px-4 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg border border-slate-600/50 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 placeholder-teal-300/30"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-teal-300/80 mb-2">
                          Payment Date
                        </label>
                        <DatePicker
                          selected={
                            editFormData.payment_date
                              ? new Date(editFormData.payment_date)
                              : null
                          }
                          onChange={(date) =>
                            handleEditFormChange({
                              target: {
                                name: "payment_date",
                                value: date.toISOString().split("T")[0],
                              },
                            })
                          }
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          wrapperClassName="w-full"
                          className="w-full px-4 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg border border-slate-600/50 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 placeholder-teal-300/30"
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-teal-300/80 mb-2">
                        Receipt No
                      </label>
                      <input
                        type="text"
                        name="receipt_no"
                        value={editFormData.receipt_no}
                        onChange={handleEditFormChange}
                        className="w-full px-4 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg border border-slate-600/50 focus:border-teal-500 focus:ring-1 focus:ring-teal-500 placeholder-teal-300/30"
                      />
                    </div>
                  </>
                )}

                {isPending && (
                  <div className="bg-slate-700/30 rounded-lg p-4">
                    <p className="text-teal-300/70 text-sm">
                      Pending installments do not require paid amount or receipt
                      information.
                    </p>
                  </div>
                )}
              </div>

              <div className="flex justify-end space-x-4 mt-8">
                <button
                  className="px-6 py-2.5 bg-slate-700/50 text-teal-300 rounded-lg hover:bg-slate-600/50 transition-colors duration-200"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </button>
                <button
                  className="px-6 py-2.5 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center min-w-[100px]"
                  onClick={handleSaveChanges}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                      Saving...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPageButtons = () => {
    const buttons = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + 2, totalPages);

    if (endPage - startPage < 2) {
      startPage = Math.max(endPage - 2, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium transition-all duration-300 ${
            currentPage === i
              ? "bg-teal-600 text-white"
              : "bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white"
          }`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderCompletedPageButtons = () => {
    const buttons = [];
    let startPage = Math.max(completedCurrentPage - 1, 1);
    let endPage = Math.min(startPage + 2, completedTotalPages);

    if (endPage - startPage < 2) {
      startPage = Math.max(endPage - 2, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => setCompletedCurrentPage(i)}
          className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium transition-all duration-300 ${
            completedCurrentPage === i
              ? "bg-teal-600 text-white"
              : "bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white"
          }`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const handleCompletedPreviousPage = () => {
    if (completedCurrentPage > 1) {
      setCompletedCurrentPage(completedCurrentPage - 1);
    }
  };

  const handleCompletedNextPage = () => {
    if (completedCurrentPage < completedTotalPages) {
      setCompletedCurrentPage(completedCurrentPage + 1);
    }
  };
  return (
    <div className="p-8 text-teal-300">
      <div className="max-w-7xl mx-auto space-y-8">
      <div className=" p-6">
      <div className="max-w-7l mx-auto">
        <div className="backdrop-blur-lg bg-white/5 rounded-3xl shadow-2xl border border-teal-400/10 overflow-hidden">
          {/* Header Section */}
          <div className="relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-teal-500/10 to-slate-900/10 backdrop-blur" />
            <div className="relative py-8 px-8">
              <h1 className="text-4xl font-bold text-center text-teal-300">
                Installment Payment Management
              </h1>
              <p className="text-teal-300/80 text-center mt-2 text-lg">
                {activeSociety?.name || "No Society Selected"}
              </p>
              <div className="h-1 w-32 mx-auto mt-4 bg-gradient-to-r from-teal-400 to-teal-600 rounded-full" />
            </div>
          </div>

          {/* Content Section */}
          <div className="p-8 space-y-8 min-h-[45vh]">
            <div className="grid md:grid-cols-2 gap-8">
              {/* Payment Mode Select */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-teal-300">
                  Payment Mode
                </label>
                <select
                  value={paymentMode}
                  onChange={(e) => handlePaymentModeChange(e.target.value)}
                  className="w-full px-4 py-3 bg-slate-800/50 text-teal-300 border-2 border-teal-500/20 rounded-xl focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-300 hover:border-teal-400/50 backdrop-blur-sm"
                  disabled
                >
                  <option value="Installment">Installment</option>
                </select>
              </div>

              {/* Customer Select */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-teal-300">
                  Select Customer
                </label>
                <select
                  value={selectedCustomer ? selectedCustomer._id : ""}
                  onChange={(e) => handleCustomerChange(e.target.value)}
                  className="w-full px-4 py-3 bg-slate-800/50 text-teal-300 border-2 border-teal-500/20 rounded-xl focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-300 hover:border-teal-400/50 backdrop-blur-sm"
                >
                  <option value="">Select Customer</option>
                  {customerOptions}
                </select>
              </div>

              {/* Plot Search */}
              <div className="md:col-span-2 relative" ref={dropdownRef}>
                <label className="block text-sm font-medium text-teal-300 mb-2">
                  Search Plot by Number
                </label>
                <div className="relative">
                  <input
                    type="text"
                    value={plotSearchTerm}
                    onChange={(e) => {
                      setPlotSearchTerm(e.target.value);
                      setShowPlotDropdown(true);
                    }}
                    onFocus={() => setShowPlotDropdown(true)}
                    placeholder="Enter plot number"
                    className="w-full px-4 py-3 bg-slate-800/50 text-teal-300 border-2 border-teal-500/20 rounded-xl focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-300 hover:border-teal-400/50 backdrop-blur-sm pl-10"
                  />
                  <Tag className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-teal-400" />
                </div>

                {showPlotDropdown && (plotSearchTerm || searchedPlots.length > 0) && (
                  <div className="absolute z-50 w-full mt-2">
                    <div className="bg-slate-800/95 backdrop-blur-xl border border-teal-500/20 rounded-xl shadow-2xl  overflow-y-auto max-h-[calc(2*4rem)]">
                      {searchedPlots.length > 0 ? (
                        searchedPlots.map((plot) => {
                          const plotBooking = bookings.find(
                            (booking) => booking.plot_id?._id === plot._id
                          );
                          const customerName = plotBooking?.customer_id?._id.username || 'N/A';

                          return (
                            <div
                              key={plot._id}
                              onClick={() => {
                                handlePlotSelect(plot);
                                setShowPlotDropdown(false);
                                setPlotSearchTerm(plot.plot_number);
                              }}
                              className="px-4 py-3 hover:bg-slate-700/50 cursor-pointer border-b border-teal-500/10 last:border-none transition-colors duration-200"
                            >
                              <div className="flex items-center gap-3">
                                <Tag className="w-4 h-4 text-teal-400" />
                                <div className="flex-1">
                                  <p className="text-teal-300 font-medium">Plot #{plot.plot_number}</p>
                                  <p className="text-teal-400/70 text-sm">Customer: {customerName}</p>
                                </div>
                                <span className="text-teal-500 text-sm font-medium">
                                  {plot.category}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="px-4 py-3 text-teal-400/50 text-center">
                          No plots found
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
       

            </div>

            {/* Customer Plot Details */}

            {(selectedCustomer || selectedPlot) && (
              <div className="w-full max-w-7xl mx-auto p-3 sm:p-4 lg:p-6">
                <div className="bg-gray-900/40 rounded-xl sm:rounded-2xl p-4 sm:p-6 lg:p-8 border border-teal-800/30 shadow-xl">
                  {/* Header Section */}
                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-6">
                    <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold text-teal-300 flex items-center gap-3">
                      <Home className="text-teal-500 w-5 h-5 sm:w-6 sm:h-6 lg:w-7 lg:h-7" />
                      <span className="break-all">
                      {selectedCustomer ? `${selectedCustomer.username}'s Plot Portfolio` : 'Plot Details'}
                      </span>
                    </h2>
                    <div className="bg-teal-500/20 text-teal-300 px-3 py-1.5 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm whitespace-nowrap">
                      Total Plots: {customerPlots.length}
                    </div>
                  </div>

                  {/* Empty State */}
                  {customerPlots.length === 0 ? (
                    <div className="bg-gray-900/50 rounded-xl sm:rounded-2xl py-8 sm:py-12 lg:py-16 text-center">
                      <Info className="w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12 mx-auto text-gray-500 mb-4" />
                      <p className="text-gray-400 text-base sm:text-lg lg:text-xl">
                        No plots found for this customer
                      </p>
                    </div>
                  ) : (
                    /* Plot Grid */
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 lg:gap-6">
                      {customerPlots
                        // .filter((plot) => plot.status !== "Sold")
                        .map((plot) => {
                          const details = getSocietyAndPlotDetails(plot._id);
                          const plotTotals = totals[plot._id] || {};
                          // Ensure Total Paid equals Total Plot Amount if the plot is sold
                          const adjustedTotalPaid =
                            details?.status === "Sold"
                              ? plotTotals.totalPlotAmount
                              : plotTotals.totalPaidAmount;
                          return (
                            <div
                              key={plot._id}
                              onClick={() => handlePlotSelect(plot)}
                              className={`
                      relative group cursor-pointer p-3 sm:p-4 lg:p-6 rounded-2xl sm:rounded-3xl
                      transition-all duration-300 ease-in-out
                      ${
                        selectedPlot?._id === plot._id
                          ? "ring-2 sm:ring-4 ring-teal-500 bg-teal-900/30 scale-[1.02] sm:scale-[1.03] shadow-lg sm:shadow-2xl"
                          : "hover:bg-gray-800/40 bg-gray-900/30 hover:scale-[1.02] hover:shadow-lg"
                      }
                    `}
                            >
                              {/* Gradient Overlay */}
                              <div className="absolute inset-0 bg-gradient-to-br from-teal-900/10 to-gray-800/20 rounded-2xl sm:rounded-3xl opacity-70 group-hover:opacity-90 transition-opacity"></div>

                              {/* Plot Content */}
                              <div className="relative z-10 space-y-3 sm:space-y-4">
                                <div className="flex items-center gap-3 sm:gap-4">
                                  {details?.societyImage ? (
                                    <img
                                      src={details.societyImage}
                                      alt={details.societyName}
                                      className="w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20 object-cover rounded-full border-2 sm:border-3 border-teal-500/30 shadow-lg"
                                    />
                                  ) : (
                                    <div className="w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20 bg-gray-700/30 rounded-full flex items-center justify-center">
                                      <Home className="w-5 h-5 sm:w-6 sm:h-6 text-gray-500" />
                                    </div>
                                  )}
                                  <div>
                                    <h3 className="text-base sm:text-lg lg:text-xl font-semibold text-white break-words">
                                      {details?.societyName}
                                    </h3>
                                    <p className="text-xs sm:text-sm text-gray-400 flex items-center">
                                      <MapPin className="w-3 h-3 sm:w-3.5 sm:h-3.5 mr-1.5 text-teal-400" />
                                      {details?.societyLocation}
                                    </p>
                                  </div>
                                </div>
                                <div className="space-y-2">
                                  <div className="flex items-center gap-2">
                                    <CheckCircle className="w-5 h-5 text-teal-400" />
                                    <p className="text-sm font-medium text-teal-300/70">
                                      Plot Status
                                    </p>
                                  </div>

                                  <div className="bg-gray-800/40 rounded-lg px-4 py-3">
                                    <p
                                      className={`text-sm font-semibold ${getStatusColor(
                                        details?.status
                                      )} transition-colors duration-300`}
                                    >
                                      {details?.status || "Status Unknown"}
                                    </p>
                                  </div>
                                </div>
                                {/* Plot Details */}
                                <div className="space-y-2 pt-3 sm:pt-4 border-t border-gray-700/30">
                                  {[
                                    {
                                      icon: Tag,
                                      label: "Plot Number",
                                      value: details?.plotNumber,
                                    },
                                    {
                                      icon: Tag,
                                      label: "Initial Deposit",
                                      value: details?.deposite,
                                    },
                                    {
                                      icon: CheckCircle,
                                      label: "Category",
                                      value: details?.category,
                                    },
                                    {
                                      icon: BookCopy,
                                      label: "Total Paid",
                                      value: `${formatAmount(
                                        adjustedTotalPaid
                                      )} PKR`,
                                    },
                                    {
                                      icon: HandCoins,
                                      label: "Pending Installments",
                                      value: `${formatAmount(
                                        plotTotals.totalPendingInstallmentsAmount
                                      )} PKR`,
                                    },
                                    {
                                      icon: ReceiptText,
                                      label: "Total Plot Amount",
                                      value: `${formatAmount(
                                        plotTotals.totalPlotAmount
                                      )} PKR`,
                                    },
                                    {
                                      icon: ReceiptText,
                                      label: "Booking Date",
                                      value: details?.Date
                                      ,
                                    },
                                  ].map(({ icon: Icon, label, value }) => (
                                    <div
                                      key={label}
                                      className="flex justify-between items-center text-gray-300 text-xs sm:text-sm"
                                    >
                                      <span className="flex items-center gap-1.5">
                                        <Icon className="w-3 h-3 sm:w-3.5 sm:h-3.5 text-teal-400" />
                                        {label}
                                      </span>
                                      <span className="font-semibold text-white break-all">
                                        {value}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Installments Tables */}

            {selectedPlot && (
              <div className="space-y-10">
                <div className="relative">
                  <div className="flex items-center justify-between mb-8">
                    <h3 className="text-2xl font-bold bg-gradient-to-r from-teal-300 to-teal-100 bg-clip-text text-transparent">
                      Pending Installments for Plot {selectedPlot.plot_number}{" "}
                      in{" "}
                      <span className="text-red-400 font-extrabold">
                        {selectedPlot.soc.name}
                      </span>
                    </h3>
                  </div>

                  {paginatedFilteredInstallments.length > 0 ? (
                    <div className="overflow-hidden rounded-2xl border border-slate-700/50 backdrop-blur-sm bg-slate-900/50">
                      <table className="w-full">
                        <thead>
                          <tr className="border-b border-slate-700/50">
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Installment Number
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Installment Amount
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Remaining Amount
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Paid Amount
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Due Date
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Status
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-700/50">
                          {paginatedFilteredInstallments.map((installment) => (
                            <tr
                              key={`pending-installment-${installment._id}`}
                              className="transition-colors hover:bg-slate-800/30"
                            >
                              <td className="py-4 px-6 text-teal-300">
                                {installment.installment_number}
                              </td>
                              <td className="py-4 px-6 text-teal-300">
                                PKR {installment.amount.toLocaleString()}
                              </td>
                              <td className="py-4 px-6 text-teal-300">
                                PKR{" "}
                                {installment.remaining_amount.toLocaleString()}
                              </td>
                              <td className="py-4 px-6 text-teal-300">
                                PKR {installment.paid_amount.toLocaleString()}
                              </td>
                              <td className="py-4 px-6 text-teal-300">
                                {new Date(
                                  installment.due_date
                                ).toLocaleDateString("en-GB")}
                              </td>
                              <td className="py-4 px-6">
                                <span
                                  className={`px-4 py-1.5 rounded-full text-xs font-medium ${
                                    installment.status === "Completed"
                                      ? "bg-green-500/10 text-green-400 ring-1 ring-green-400/30"
                                      : installment.status === "Overdue"
                                      ? "bg-red-500/10 text-red-400 ring-1 ring-red-400/30"
                                      : "bg-yellow-500/10 text-yellow-400 ring-1 ring-yellow-400/30"
                                  }`}
                                >
                                  {installment.status}
                                </span>
                              </td>
                              <td className="py-4 px-6">
                                <div className="flex gap-2">
                                  <button
                                    onClick={() =>
                                      handleInstallmentChange(installment)
                                    }
                                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-all ${
                                      installment.status === "Completed"
                                        ? "bg-slate-700/50 text-slate-400 cursor-not-allowed"
                                        : "bg-teal-500/10 text-teal-300 hover:bg-teal-500/20 ring-1 ring-teal-400/30"
                                    }`}
                                    disabled={
                                      installment.status === "Completed"
                                    }
                                  >
                                    Select
                                  </button>
                                  <button
                                    onClick={() => handleEditClick(installment)}
                                    className="p-2 rounded-lg bg-teal-500/10 text-teal-300 hover:bg-teal-500/20 ring-1 ring-teal-400/30 transition-all"
                                  >
                                    <Edit2 size={16} />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="text-teal-300">
                      No pending installments for this plot.
                    </p>
                  )}
                </div>

                {/* Pagination */}
                {totalPages > 1 && (
                  <div className="flex justify-center items-center gap-3 sm:gap-4 mt-4 sm:mt-6 mb-4 sm:mb-5 p-2 sm:p-4">
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className={`group flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full transition-all duration-300 ${
                        currentPage === 1
                          ? "bg-gray-600 cursor-not-allowed"
                          : "bg-white/10 hover:bg-teal-600"
                      }`}
                    >
                      <ChevronLeft
                        className={`w-3 h-3 sm:w-4 sm:h-4 ${
                          currentPage === 1
                            ? "text-gray-400"
                            : "text-teal-300 group-hover:text-white"
                        }`}
                      />
                    </button>

                    <div className="flex items-center gap-1 sm:gap-2">
                      {currentPage > 2 && totalPages > 3 && (
                        <>
                          <button
                            onClick={() => setCurrentPage(1)}
                            className="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white transition-all duration-300"
                          >
                            1
                          </button>
                          <span className="text-teal-300 px-1">...</span>
                        </>
                      )}

                      {renderPageButtons()}

                      {currentPage < totalPages - 1 && totalPages > 3 && (
                        <>
                          <span className="text-teal-300 px-1">...</span>
                          <button
                            onClick={() => setCurrentPage(totalPages)}
                            className="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white transition-all duration-300"
                          >
                            {totalPages}
                          </button>
                        </>
                      )}
                    </div>

                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className={`group flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full transition-all duration-300 ${
                        currentPage === totalPages
                          ? "bg-gray-600 cursor-not-allowed"
                          : "bg-white/10 hover:bg-teal-600"
                      }`}
                    >
                      <ChevronRight
                        className={`w-3 h-3 sm:w-4 sm:h-4 ${
                          currentPage === totalPages
                            ? "text-gray-400"
                            : "text-teal-300 group-hover:text-white"
                        }`}
                      />
                    </button>
                  </div>
                )}

                {/* Completed Installments Section */}
                {CompleteInstallments.length > 0 && (
                  <div className="mt-12">
                    <h3 className="text-2xl font-bold bg-gradient-to-r from-teal-300 to-teal-100 bg-clip-text text-transparent mb-8">
                      Paid Installments
                    </h3>
                    <div className="overflow-hidden rounded-2xl border border-slate-700/50 backdrop-blur-sm bg-slate-900/50">
                      <table className="w-full">
                        <thead>
                          <tr className="border-b border-slate-700/50">
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Receipt No.
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Installment No.
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Amount Paid
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Payment Date
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Status
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Actions
                            </th>
                            <th className="py-4 px-6 text-left text-sm font-medium text-teal-300">
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-700/50">
                          {paginatedCompleteInstallments.map((installment) => (
                            <tr
                              key={`completed-installment-${installment._id}`}
                              className="transition-colors hover:bg-slate-800/30"
                            >
                              <td className="py-4 px-6 text-teal-300">
                                {installment.receipt_no}
                              </td>
                              <td className="py-4 px-6 text-teal-300">
                                {installment.installment_number}
                              </td>
                              <td className="py-4 px-6 text-teal-300">
                                PKR {installment.paid_amount.toLocaleString()}
                              </td>
                              <td className="py-4 px-6 text-teal-300">
                                {new Date(
                                  installment.payment_date
                                ).toLocaleDateString("en-GB")}
                              </td>
                              <td className="py-4 px-6">
                                <span
                                  className={`px-4 py-1.5 rounded-full text-xs font-medium ${
                                    installment.status === "Completed"
                                      ? "bg-green-500/10 text-green-400 ring-1 ring-green-400/30"
                                      : "bg-yellow-500/10 text-yellow-400 ring-1 ring-yellow-400/30"
                                  }`}
                                >
                                  {installment.status}
                                </span>
                              </td>
                              <td className="py-4 px-6">
                                <div className="flex gap-2">
                                  <button
                                    onClick={() => handleViewSlip(installment)}
                                    className="px-4 py-2 rounded-lg text-sm font-medium bg-teal-500/10 text-teal-300 hover:bg-teal-500/20 ring-1 ring-teal-400/30 transition-all"
                                  >
                                    View Slip
                                  </button>
                                  <button
                                    onClick={() => handleEditClick(installment)}
                                    className="p-2 rounded-lg bg-teal-500/10 text-teal-300 hover:bg-teal-500/20 ring-1 ring-teal-400/30 transition-all"
                                  >
                                    <Edit2 size={16} />
                                  </button>
                                </div>
                              </td>
                              <td className="py-4 px-6 text-teal-300">
                                {installment.description}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* Completed Installments Pagination */}

                      {completedTotalPages > 1 && (
                        <div className="flex justify-center items-center gap-3 sm:gap-4 mt-4 sm:mt-6 mb-4 sm:mb-5 p-2 sm:p-4">
                          <button
                            onClick={handleCompletedPreviousPage}
                            disabled={completedCurrentPage === 1}
                            className={`group flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full transition-all duration-300 ${
                              completedCurrentPage === 1
                                ? "bg-gray-600 cursor-not-allowed"
                                : "bg-white/10 hover:bg-teal-600"
                            }`}
                          >
                            <ChevronLeft
                              className={`w-3 h-3 sm:w-4 sm:h-4 ${
                                completedCurrentPage === 1
                                  ? "text-gray-400"
                                  : "text-teal-300 group-hover:text-white"
                              }`}
                            />
                          </button>

                          <div className="flex items-center gap-1 sm:gap-2">
                            {completedCurrentPage > 2 &&
                              completedTotalPages > 3 && (
                                <>
                                  <button
                                    onClick={() => setCompletedCurrentPage(1)}
                                    className="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white transition-all duration-300"
                                  >
                                    1
                                  </button>
                                  <span className="text-teal-300 px-1">
                                    ...
                                  </span>
                                </>
                              )}

                            {renderCompletedPageButtons()}

                            {completedCurrentPage < completedTotalPages - 1 &&
                              completedTotalPages > 3 && (
                                <>
                                  <span className="text-teal-300 px-1">
                                    ...
                                  </span>
                                  <button
                                    onClick={() =>
                                      setCompletedCurrentPage(
                                        completedTotalPages
                                      )
                                    }
                                    className="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white transition-all duration-300"
                                  >
                                    {completedTotalPages}
                                  </button>
                                </>
                              )}
                          </div>

                          <button
                            onClick={handleCompletedNextPage}
                            disabled={
                              completedCurrentPage === completedTotalPages
                            }
                            className={`group flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full transition-all duration-300 ${
                              completedCurrentPage === completedTotalPages
                                ? "bg-gray-600 cursor-not-allowed"
                                : "bg-white/10 hover:bg-teal-600"
                            }`}
                          >
                            <ChevronRight
                              className={`w-3 h-3 sm:w-4 sm:h-4 ${
                                completedCurrentPage === completedTotalPages
                                  ? "text-gray-400"
                                  : "text-teal-300 group-hover:text-white"
                              }`}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        <style>{printStyles}</style>
        <Modal
          isOpen={isSlipModalOpen}
          onRequestClose={handleCloseModal}
          style={{ overlay: modalOverlayStyle, content: modalStyle }}
        >
          <div className="relative max-h-[90vh] overflow-y-auto bg-white rounded-lg">
            <button
              onClick={handleCloseModal}
              className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full"
            >
              <X size={20} />
            </button>
            <InstallmentBookingSlip
              installment={selectedInstallment}
              companyInfo={selectedPlot}
              payment={totals}
            />
          </div>
        </Modal>
      </>

      {/* Payment Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900/80 backdrop-blur-sm">
          <div className="bg-gray-800 rounded-xl p-6 max-w-md w-full mx-4 border border-gray-700 shadow-2xl">
            <h2 className="text-xl font-semibold text-white mb-4">
              Confirm Payment
            </h2>
            <p className="mb-6 text-gray-300">
              You are about to process a payment for installment number{" "}
              <span className="text-red-500 font-extrabold">
                `{installmentId}`
              </span>
              , plot number{" "}
              <span className="text-red-500 font-extrabold">
                `{selectedPlot?.plotNumber}`
              </span>{" "}
              in{" "}
              <span className="text-red-500 font-extrabold">
                `{selectedPlot?.societyName}`
              </span>
              .
            </p>
            <label className="mb-6 text-gray-300">
              <strong>Payment Amount (PKR):</strong>
              <input
                type="text"
                className="w-full mt-2 mb-3 px-4 py-2 bg-gray-700 text-white rounded-lg"
                value={paymentAmount}
                onChange={(e) => handlePaymentAmountChange(e)}
                placeholder="Enter payment amount"
              />
            </label>

            <label className="mb-6 text-gray-300">
              <strong>Receipt no.:</strong>
              <input
                type="text"
                className="w-full mt-2 mb-3 px-4 py-2 bg-gray-700 text-white rounded-lg"
                value={receiptNo}
                onChange={(e) => setReceiptNo(e.target.value)}
              />
            </label>
            <label className="mb-6 text-gray-300">
              <strong>Installment Pay Date:</strong>
              <DatePicker
          selected={selectedInstallmentDate ? new Date(selectedInstallmentDate) : null}
          onChange={(date) => setSelectedInstallmentDate(date)}
          dateFormat="dd/MM/yyyy" 
          placeholderText="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                         wrapperClassName="w-full"
          className="w-full mt-2 mb-3 px-4 py-2 bg-gray-700 text-white rounded-lg"
        />
            </label>

            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 transition-colors duration-200"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 bg-green-500/80 text-white rounded-lg hover:bg-green-500 transition-colors duration-200 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handlePayment}
                disabled={loading}
              >
                {loading ? "Processing..." : "Confirm Payment"}
              </button>
            </div>
          </div>
        </div>
      )}
      {renderEditModal()}
    </div>
  );
};

export default ManageInstallments;
