import React from "react";
import { useSidebarContext } from "../global/sidebar/SidebarContext";

const Society_Map = () => {
  const { activeSociety } = useSidebarContext();

  return (
    <div className="mx-auto my-4 rounded-lg shadow-lg overflow-hidden">
      <div className="p-6">
        <h2 className="text-2xl font-bold text-center text-teal-400 mb-4">
          {activeSociety.name} Map View
        </h2>

        <div className="relative overflow-hidden rounded-lg flex items-center justify-center">
          {activeSociety.society_map_image ? (
            <div className="aspect-video flex items-center justify-center">
              <img
                src={activeSociety.society_map_image}
                alt={`${activeSociety.name} Map`}
                className="object-cover transition-transform duration-300 hover:scale-105 rounded-lg"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          ) : (
            <div className="aspect-video  flex items-center justify-center rounded-lg">
              <p className="text-gray-500 text-lg text-center">
                No map image available for {activeSociety.name}.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Society_Map;
